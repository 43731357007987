import { Injectable } from '@angular/core';
import { TrainingTreeNode } from '../models/training-tree-node';
import { TreeStateService } from './tree-state.service';
import { TranslateService } from '@ngx-translate/core';
import { TrainingStatus } from 'src/app/shared/models/training-status';

@Injectable()
export class TreeNodeStructureService {
    startButton: string;
    stopButton: string;
    cloneButton: string;
    deleteButton: string;
    downloadButton: string;

    getProgressBarClasses(treeNode: TrainingTreeNode) {
        switch (treeNode.status) {
            case TrainingStatus.SUCCEEDED:
                return 'progress-bar progress-bar-success';
            case TrainingStatus.FAILED:
                return 'progress-bar progress-bar-error';
            case TrainingStatus.CANCELLING:
            case TrainingStatus.CANCELLED:
                return 'progress-bar progress-bar-stop';
            case TrainingStatus.QUEUED:
            case TrainingStatus.RUNNING:
            case TrainingStatus.REQUESTING:
            case TrainingStatus.PREPARING:
                return 'progress-bar progress-bar-running';
        }
        return 'progress-bar';
    }

    translateTimeText(time: string) {
        time = time.replace(
            'splitScreen.components.contextMenu.Minutes',
            this.translateService.instant(
                'splitScreen.components.contextMenu.Minutes'
            )
        );
        time = time.replace(
            'splitScreen.components.contextMenu.Hours',
            this.translateService.instant(
                'splitScreen.components.contextMenu.Hours'
            )
        );
        return time.replace(
            'splitScreen.components.contextMenu.Days',
            this.translateService.instant(
                'splitScreen.components.contextMenu.Days'
            )
        );
    }

    constructor(
        private treeStateService: TreeStateService,
        private translateService: TranslateService
    ) {
        this.downloadButton = `<img
                    id="training-node-download"
                    class="node-menu-info-icon"
                    title="${this.translateService.instant(
                        'splitScreen.components.tree.tooltip.download'
                    )}"
                    src="assets/svg/project-split-screen/tree/icon-download.svg"
                    alt="button for downloading skill"
                />`;
        this.cloneButton = `<img
                    id="training-node-clone"
                    class="node-menu-info-icon"
                    title="${this.translateService.instant(
                        'splitScreen.components.tree.tooltip.clone'
                    )}"
                    src="assets/svg/project-split-screen/tree/icon-clone.svg"
                    alt="button for cloning training"
                />`;
        this.startButton = `<img
                id="training-node-start"
                class="node-menu-info-icon"
                title="${this.translateService.instant(
                    'splitScreen.components.tree.tooltip.start'
                )}"
                src="assets/svg/project-split-screen/tree/icon-start.svg"
                alt="button for starting training"
            />`;
        this.stopButton = `<img
                    id="training-node-stop"
                    class="node-menu-info-icon"
                    title="${this.translateService.instant(
                        'splitScreen.components.tree.tooltip.stop'
                    )}"
                    src="assets/svg/project-split-screen/tree/icon-stop.svg"
                    alt="button for stopping training"
                />`;
        this.deleteButton = `<img
                    id="training-node-delete"
                    class="node-menu-info-icon"
                    title="${this.translateService.instant(
                        'splitScreen.components.tree.tooltip.delete'
                    )}"
                    src="assets/svg/project-split-screen/tree/icon-trash.svg"
                    alt="button for deleting training"
                />`;
    }

    public toHTML(treeNode: TrainingTreeNode): string {
        const time = this.translateTimeText(
            TrainingTreeNode.getRunningTime(treeNode)
        );
        const isNodeSelected =
            this.treeStateService.selectedTreeNode.uuid === treeNode.uuid;
        const trainingTerminatedIncomplete = [
            TrainingStatus.CANCELLED,
            TrainingStatus.CANCELLING,
            TrainingStatus.FAILED,
        ].includes(treeNode.status);
        const progress =
            treeNode.progress > 100 || trainingTerminatedIncomplete
                ? 100
                : treeNode.progress;
        return `
        <div class="flex-wrapper">
            <div class="background-flex" style="background-color:transparent">
                <div class="${this.getCardSelection(treeNode)}">
                    <div class="card-header">
                        <div class="card-title m-0">
                            <p
                                class="training-title${
                                    isNodeSelected ? ' selected' : ''
                                }">${treeNode.name}
                            </p>
                        </div>
                        <div class="menu-icon">
                            <img
                                id="training-node-menu"
                                class="${
                                    isNodeSelected &&
                                    this.treeStateService.isMenuOpen
                                        ? 'info-icon'
                                        : ''
                                }"
                                src="assets/svg/project-split-screen/tree/${
                                    this.treeStateService.isMenuOpen &&
                                    isNodeSelected
                                        ? 'icon-close-menu.svg'
                                        : 'icon-three-dot.svg'
                                }"
                                alt="three dot menu"
                            />
                        </div>
                    </div>
                    <div class="card-body py-1">
                        <div class="time-text-container">
                            <img
                                class="info-icon"
                                src="assets/svg/project-split-screen/tree/icon-clock.svg"
                                alt="clock image"
                            />
                            <span class="ms-1 time-text">
                                ${time}
                            </span>
                        </div>
                        <div class="progress my-2">
                            <div class="progress-bar-wrapper">
                                <div class="${this.getProgressBarClasses(
                                    treeNode
                                )}" aria-valuenow="${progress}"
                                    aria-valuemin="0" aria-valuemax="100"
                                    style="width:${+progress}%">
                                </div>
                            </div>
                            <div class="progress-text-wrapper">
                                <p class="progress-text progress-text-left">
                                ${this.getTrainingStatusText(treeNode)}
                                </p>
                                ${
                                    trainingTerminatedIncomplete
                                        ? `<img
                                class="info-icon"
                                src="assets/svg/project-split-screen/tree/icon-lightning.svg"
                                alt="clock image"
                            />`
                                        : `<p class="progress-text progress-text-right">
                                    ${+progress}%
                                </p>`
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            ${
                isNodeSelected && this.treeStateService.isMenuOpen
                    ? this.getTreeNodeMenu(treeNode.status)
                    : ''
            }
        </div>`;
    }

    getTrainingStatusText(treeNode: TrainingTreeNode): string {
        switch (treeNode.status) {
            case TrainingStatus.NEW:
                return this.translateService.instant(
                    'splitScreen.components.contextMenu.New'
                );
            case TrainingStatus.RUNNING:
            case TrainingStatus.QUEUED:
            case TrainingStatus.PREPARING:
            case TrainingStatus.REQUESTING:
                return this.translateService.instant(
                    'splitScreen.components.contextMenu.Running'
                );
            case TrainingStatus.SUCCEEDED:
                return this.translateService.instant(
                    'splitScreen.components.contextMenu.Finished'
                );
            case TrainingStatus.CANCELLED:
            case TrainingStatus.CANCELLING:
                return this.translateService.instant(
                    'splitScreen.components.contextMenu.Aborted'
                );
            case TrainingStatus.FAILED:
                return this.translateService.instant(
                    'splitScreen.components.contextMenu.Failed'
                );
        }
    }

    private getCardSelection(treeNode: TrainingTreeNode) {
        return this.treeStateService.selectedTreeNode.uuid === treeNode.uuid
            ? 'card selected'
            : 'card';
    }

    private getTreeNodeMenu(status: TrainingStatus): string {
        let treeNodeMenu = `<div class="node-menu">`;
        switch (status) {
            case TrainingStatus.NEW:
                treeNodeMenu +=
                    this.startButton + this.cloneButton + this.deleteButton;
                break;
            case TrainingStatus.RUNNING:
            case TrainingStatus.QUEUED:
            case TrainingStatus.PREPARING:
            case TrainingStatus.REQUESTING:
                treeNodeMenu += this.cloneButton + this.stopButton;
                break;
            case TrainingStatus.SUCCEEDED:
                treeNodeMenu += this.downloadButton + this.cloneButton;
                break;
            case TrainingStatus.CANCELLED:
            case TrainingStatus.CANCELLING:
            case TrainingStatus.FAILED:
                treeNodeMenu += this.cloneButton;
                break;
        }
        return treeNodeMenu + '</div>';
    }
}
