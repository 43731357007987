import {
    Directive,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import {
    ActionNoiseType,
    FrequencyUnit,
    ImitationPolicy,
    Policy,
    ReplayBufferClass,
    Scope,
} from '../models/algorithm-form';

@Directive()
export class BaseAlgorithmClass implements OnInit, OnDestroy {
    @Input() algorithmForm: UntypedFormGroup;
    @Input() editingIsDisabled: boolean;
    @Output() valueChange = new EventEmitter();

    protected readonly Scope = Scope;

    private $formChangesSubscription: Subscription;

    policyTypes: string[] = [
        Policy.MLP_POLICY,
        Policy.MLP_LSTM_POLICY,
        Policy.MLP_LN_LSTM_POLICY,
        Policy.CNN_POLICY,
        Policy.CNN_LSTM_POLICY,
        Policy.CNN_LN_LSTM_POLICY,
    ];

    imitationPolicy: string[] = [ImitationPolicy.FEED_FORWARD_32POLICY];

    frequencyTypes: FrequencyUnit[] = [
        FrequencyUnit.STEP,
        FrequencyUnit.EPISODE,
    ];

    actionNoiseTypes: ActionNoiseType[] = [
        ActionNoiseType.NONE,
        ActionNoiseType.NORMAL,
        ActionNoiseType.ORNSTEIN_UHLENBECK,
    ];

    replayBufferClasses: ReplayBufferClass[] = [
        ReplayBufferClass.NONE,
        ReplayBufferClass.REPLAY_BUFFER,
    ];

    constructor() {}

    ngOnInit() {
        this.$formChangesSubscription =
            this.algorithmForm.valueChanges.subscribe(() => {
                if (this.algorithmForm.invalid || this.editingIsDisabled) {
                    Object.keys(this.algorithmForm.controls).forEach(
                        (key: string) => {
                            const control = this.algorithmForm.get(key);
                            if (!control.invalid && this.editingIsDisabled) {
                                control.disable({ emitEvent: false });
                            }
                        }
                    );
                }
            });
    }

    ngOnDestroy() {
        this.$formChangesSubscription.unsubscribe();
    }

    blurInput($event: any) {
        $event.target.blur();
    }

    onValueChange(
        $event?: any,
        control?: UntypedFormControl,
        changedLearner?: boolean
    ) {
        let valueToEmit = undefined;
        if ($event) {
            control.setValue($event.target.value === 'true');
        }
        if (changedLearner) {
            this.algorithmForm.removeControl(this.Scope.LEARNER_GENERAL);
            this.algorithmForm.removeControl(this.Scope.LEARNER_TRAINING_SETUP);
            this.algorithmForm.removeControl(this.Scope.LEARNER_OPTIMIZER);
            this.algorithmForm.removeControl(this.Scope.LEARNER_SPECIFIC);
            valueToEmit = 'changedLearner';
        }
        this.valueChange.emit(valueToEmit);
    }
}
