<div class="wrapper">
    <div class="header">
        <p>Labels</p>
    </div>
    <div class="label-name">
        <p>
            {{ 'imgDb.labelManager.input.label' | translate }}
        </p>
    </div>
    <div class="label-creation-wrapper">
        <div class="input-field-wrapper">
            <input
                #labelName
                id="label-name"
                type="text"
                class="input-field"
                [formControl]="categoryInput"
                (keyup.enter)="
                    onEnterKeypressAddCategory(categoryInput.value?.trim())
                "
                [placeholder]="
                    'imgDb.labelManager.input.placeholder' | translate
                "
            />
            <div
                class="clear-button"
                id="clear-input-field-button"
                *ngIf="categoryInput.value"
                (click)="resetForm()"
            >
                <span class="material-icons clear-icon">clear</span>
            </div>
        </div>
        <button
            id="add-new-label-button"
            class="add-button"
            (click)="onEnterKeypressAddCategory(categoryInput.value?.trim())"
            [disabled]="showSpinner"
        >
            <p>+</p>
        </button>
    </div>
    <div class="sorting-wrapper">
        <select
            id="category-sorting-dropdown"
            [formControl]="selectedSortingOption"
            (change)="onSortingChange()"
        >
            <option
                class="sorting-option"
                *ngFor="let sortingOption of sortingOptions"
                [value]="sortingOption"
                [id]="sortingOption"
                [title]="
                    'imgDb.labelManager.sorting.' + sortingOption | translate
                "
            >
                {{ sortingOption }}
            </option>
        </select>
        <div class="radio-group">
            <label
                *ngFor="let option of availableOptions"
                [ngClass]="{ selected: selectedOption === option }"
                [id]="'radio-button-' + option"
                [title]="
                    'imgDb.labelManager.sortingRadioButtons.' +
                        option +
                        '.tooltip' | translate
                "
            >
                <input
                    type="radio"
                    name="option"
                    [(ngModel)]="selectedOption"
                    [value]="option"
                    (click)="onCountingGroupChange(option)"
                />
                <span class="custom-radio"></span>
                <p>
                    {{
                        'imgDb.labelManager.sortingRadioButtons.' +
                            option +
                            '.value' | translate
                    }}
                </p>
            </label>
        </div>
    </div>
    <ul
        class="list-group categories-list"
        *ngIf="categories?.length > 0 && !showSpinner"
        [ngClass]="{ 'from-training': isDbOfTraining }"
        aria-multiselectable="true"
    >
        <li
            *ngFor="let category of categories; index as idx"
            [class]="{
                'list-group-item': true,
                'category-item': true,
            }"
            [attr.aria-selected]="isCategoryActive(category)"
        >
            <div
                [class]="{
                    'main-category-entry': true,
                    selected: isCategoryActive(category),
                }"
                [style]="{
                    borderColor:
                        isCategoryActive(category) ||
                        hoveredCategoryIndex === idx
                            ? getColor(
                                  categoryIndexForRenaming === idx
                                      ? currentRenamingCategoryName
                                      : category.name
                              )
                            : 'transparent'
                }"
                (click)="onClickCategory(category)"
                (mouseenter)="hoveredCategoryIndex = idx"
                (mouseleave)="hoveredCategoryIndex = null"
            >
                <div class="category-name">
                    <span
                        class="dot"
                        [style]="{
                            backgroundColor: getColor(
                                categoryIndexForRenaming === idx
                                    ? currentRenamingCategoryName
                                    : category.name
                            )
                        }"
                    >
                    </span>
                    <input
                        #categoryRenameInput
                        *ngIf="categoryIndexForRenaming === idx"
                        [(ngModel)]="currentRenamingCategoryName"
                        class="category-rename-input"
                        (keydown)="onInputChange($event)"
                        (click)="$event.stopPropagation()"
                    />
                    <span
                        *ngIf="categoryIndexForRenaming !== idx"
                        class="category-name"
                        >{{ category.name }}</span
                    >
                </div>
                <div class="info-labels">
                    <div class="count-labels">
                        <span *ngIf="selectedOption === 'labels'">
                            {{ category.annotationCount }}
                        </span>
                        <span *ngIf="selectedOption === 'images'">
                            {{ category.imageCount }}
                        </span>
                    </div>
                    <div class="category-menu-wrapper">
                        <img
                            [id]="'menu-button-for-' + category.name"
                            [class]="
                                categoryMenuOpen && idx === menuForIndex
                                    ? 'info-icon'
                                    : ''
                            "
                            [src]="
                                categoryMenuOpen && idx === menuForIndex
                                    ? 'assets/svg/project-split-screen/tree/icon-close-menu.svg'
                                    : 'assets/svg/img-db/category-manager/icon-dots.svg'
                            "
                            alt="category menu button"
                            (click)="onCategoryMenuClick($event, idx)"
                        />
                    </div>
                </div>
            </div>
            <div
                class="category-menu-items"
                *ngIf="categoryMenuOpen && idx === menuForIndex"
            >
                <img
                    id="rename-category-button"
                    class="info-icon"
                    [title]="
                        'imgDb.labelManager.categoryMenuItems.rename'
                            | translate
                    "
                    src="assets/svg/img-db/category-manager/icon-rename.svg"
                    alt="rename category button"
                    (click)="onRename($event, idx)"
                />
                <img
                    id="delete-category-button"
                    class="info-icon"
                    [title]="
                        'imgDb.labelManager.categoryMenuItems.delete'
                            | translate
                    "
                    src="assets/svg/project-split-screen/tree/icon-trash.svg"
                    alt="delete category button"
                    (click)="onDeleteCategoryClick($event, category)"
                />
            </div>
        </li>
    </ul>
    <ul
        *ngIf="!showSpinner"
        class="list-group no-label-list"
        [ngClass]="{ 'not-alone': categories?.length > 0 }"
    >
        <li
            class="list-group-item"
            (click)="onClickEmptyCategory()"
            [class.selected]="isEmptyCategorySelected && mode === 'overview'"
        >
            <div class="category-name">
                <span class="no-label">
                    {{ 'imgDb.labelManager.noLabel' | translate }}</span
                >
            </div>
            <div class="info-labels">
                <div class="count-labels">
                    <span *ngIf="selectedOption === 'labels'"> 0 </span>
                    <span *ngIf="selectedOption === 'images'">{{
                        numberOfUncategorizedImages
                    }}</span>
                </div>
            </div>
        </li>
    </ul>
    <div class="spinner-wrapper" *ngIf="showSpinner">
        <div class="tryb-design-spinner"></div>
    </div>
</div>
