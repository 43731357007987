<div class="skill-architecture-selection">
    <div class="d-flex skill-architecture-selection-header">
        <div
            *ngIf="training.trainingType === 'RL'"
            id="observationTab"
            [class.selected]="selectedIndex === 0"
            class="dragBox"
            (click)="changeSelection(0)"
        >
            <span class="background"></span>
            {{ 'neuralNetwork.observations.tabName' | translate }}
        </div>
        <div
            id="NetworkArchitectureTab"
            [class.selected]="selectedIndex === 1"
            class="dragBox"
            (click)="changeSelection(1)"
        >
            <span class="background"></span>
            {{ 'neuralNetwork.networkArchitecture.tabName' | translate }}
        </div>
        <div
            *ngIf="training.trainingType === 'RL'"
            id="codeEditor"
            [class.selected]="selectedIndex === 2"
            class="dragBox"
            (click)="changeSelection(2)"
        >
            <span class="background"></span>
            {{ 'neuralNetwork.codeEditor.tabName' | translate }}
        </div>
    </div>
    <div class="skill-architecture-content">
        <app-skill-architecture
            *ngIf="selectedIndex === 1"
            [trainingId]="training.uuid"
            [editingIsDisabled]="editingIsDisabled"
        ></app-skill-architecture>
        <app-observation-element-details
            *ngIf="selectedIndex === 0"
            [trainingId]="training.uuid"
            [editingIsDisabled]="editingIsDisabled"
        ></app-observation-element-details>
        <app-custom-randomization
            *ngIf="selectedIndex === 2"
            [trainingId]="training.uuid"
        ></app-custom-randomization>
    </div>
</div>
