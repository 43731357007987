import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreeComponent } from './components/tree/tree.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    declarations: [TreeComponent],
    imports: [
        CommonModule,
        SharedModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
    ],
    exports: [TreeComponent],
})
export class SplitScreenModule {}
