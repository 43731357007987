import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import * as d3 from 'd3';
import { ChartItem, ChartPoint } from '../models/chart-item';
import { Subscription, fromEvent, Observable } from 'rxjs';
import { ColorService } from '../../../services/color.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-line-chart',
    templateUrl: './line-chart.component.html',
    styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements AfterViewInit, OnInit, OnDestroy {
    @Input()
    idPrefix: string;
    @Input()
    xAxisLabelSource: Observable<string>;
    @Input()
    yAxisLabelSource: Observable<string>;
    @Input()
    dataSource: Observable<ChartItem[]>;

    xAxisLabel: string;
    yAxisLabel: string;
    @Input()
    maxNumberEpoch: number;

    @Input()
    tooltipLabel: (x: number, mouseOverXLabel: string) => {};

    data: ChartItem[];

    @Output()
    clickEvent: EventEmitter<number> = new EventEmitter<number>();

    private svg;
    private graphRoot;

    private _width = 666;
    private _height = 333;

    private xScale;
    private yScale;
    private tooltip;
    private subscriptions: Subscription[] = [];

    private parentDiv: HTMLElement;

    mouseOverMapping = {
        sumEpisodes: 'evaluation.RL.diagrams.singleEpisode',
        sumModelUpdates: 'evaluation.RL.diagrams.singleModelUpdate',
        returnMeanRightLabel: 'evaluation.RL.diagrams.bcReturnMeanTooltipLabel',
        lossRightLabel: 'evaluation.RL.diagrams.bcLossTooltipLabel',
    };

    constructor(
        private colorService: ColorService,
        private translate: TranslateService
    ) {}

    public get width() {
        return this._width;
    }

    public get height() {
        return this._height;
    }

    ngOnInit(): void {
        this.subscriptions.push(
            fromEvent(window, 'resize').subscribe((value: Event) => {
                this._height = this.parentDiv.getBoundingClientRect().height;
                this._width = this.parentDiv.getBoundingClientRect().width;
                this.redrawAll();
            })
        );
        this.subscriptions.push(
            this.yAxisLabelSource.subscribe((label: string) => {
                this.yAxisLabel = this.translate.instant(label);
            })
        );
        this.subscriptions.push(
            this.xAxisLabelSource.subscribe((label: string) => {
                this.xAxisLabel = this.translate.instant(label);
            })
        );
        this.subscriptions.push(
            this.dataSource.subscribe((data: ChartItem[]) => {
                this.data = data;
                this.redrawAll();
            })
        );
    }

    ngAfterViewInit(): void {
        if (!this.data || this.data === undefined) {
            this.data = [];
        }
        this.parentDiv = window.document.getElementById(this.idPrefix);
        if (this.parentDiv) {
            this._height = this.parentDiv.getBoundingClientRect().height;
            this._width = this.parentDiv.getBoundingClientRect().width;
        }
        this.drawAll();
    }

    showHint(): boolean {
        return !this.data || this.data.length === 0;
    }

    redrawAll(): void {
        d3.select(`#${this.idPrefix} svg`)?.remove();
        d3.select(`#${this.idPrefix}-tooltip`)?.remove();
        this.drawAll();
    }

    private drawAll(): void {
        if (this.parentDiv && this.data?.length > 0) {
            this.createSvg();
            this.createAxis();
            this.initTooltip();

            this.drawGraphs();
        }
    }

    getCalcDiagramWidth(): number {
        //Substract Axis Label and numbers
        return this._width - 60;
    }

    getCalcDiagramHeight(): number {
        //Substract Axis Label and numbers
        return this._height - 30 - 45;
    }

    private createSvg(): void {
        this.svg = d3
            .select(`#${this.idPrefix}`)
            .append('svg')
            .attr('width', '100%')
            .attr('height', '100%');
    }

    private styledGridLine(g: any): any {
        return g.style('color', '#999').style('stroke-dasharray', '1, 3');
    }

    private createAxis(): void {
        const calcWidth = this.getCalcDiagramWidth();
        const calcHeight = this.getCalcDiagramHeight();
        this.graphRoot = this.svg
            .append('g')
            .attr('id', 'graph-root')
            .attr('transform', `translate(45, 30)`);
        let xMax = d3.max([
            d3.max(this.data, (d) => d3.max(d.points, (p) => p.x)),
            this.maxNumberEpoch,
        ]);
        let xMin = d3.min(this.data, (d) => d3.min(d.points, (p) => p.x));
        this.xScale = d3
            .scaleLinear()
            .domain([xMin, xMax])
            .range([0, calcWidth]);

        let minY = d3.min(this.data, (d) => d3.min(d.points, (p) => p.y));
        if (minY > 0) {
            minY = 0;
        }

        let domain: number[] = [0, 100];
        if (!this.yAxisLabel.includes('%')) {
            domain = [
                minY,
                d3.max(this.data, (d) => {
                    if (d.active) {
                        return d3.max(d.points, (p) => p.y);
                    }
                    return minY;
                }),
            ];
        }

        this.yScale = d3.scaleLinear().domain(domain).range([calcHeight, 0]);
        let ticks: Set<number> = this.createTicksSet(xMin, xMax);

        // vertical grid lines
        this.styledGridLine(
            this.graphRoot
                .append('g')
                .attr('transform', `translate(0, ${calcHeight})`)
        ).call(
            d3
                .axisBottom(this.xScale)
                .tickSize(-calcHeight)
                .tickFormat(() => '')
                .tickValues(ticks)
        );

        // horizontal grid lines
        this.styledGridLine(this.graphRoot.append('g')).call(
            d3
                .axisLeft(this.yScale)
                .tickSize(-calcWidth)
                .tickFormat(() => '')
        );

        // x-axis
        this.graphRoot
            .append('g')
            .attr('id', 'x-axis-label')
            .attr('transform', `translate(0, ${calcHeight})`)
            .call(d3.axisBottom(this.xScale).tickValues(ticks));
        this.svg
            .append('g')
            .attr('id', 'x-axis-label')
            .append('text')
            .attr('text-anchor', 'end')
            .attr('x', this.width - 5)
            .attr('y', this.height - 5)
            .text(this.xAxisLabel);

        // y-axis
        this.graphRoot.append('g').call(d3.axisLeft(this.yScale));
        this.svg
            .append('g')
            .attr('id', 'y-axis-label')
            .append('text')
            .attr('text-anchor', 'start')
            .attr('y', '1.1em')
            .attr('x', 5)
            //.attr('font-size', '13px')
            .text(this.yAxisLabel);
    }

    private initTooltip(): void {
        this.tooltip = d3
            .select(`#${this.idPrefix}`)
            .append('div')
            .attr('id', `${this.idPrefix}-tooltip`)
            .style('position', 'absolute')
            .style('padding', '3px')
            .style('background-color', '#f0f0f0')
            .style('border', '1px solid #dedede')
            .style('border-radius', '5px')
            .style('display', 'none');
    }

    private drawGraphs(): void {
        const line = d3
            .line()
            .x((d: any) => this.xScale(d.x))
            .y((d: any) => this.yScale(d.y));

        const lines = this.graphRoot.append('g').attr('class', 'lines');
        const glines = lines
            .selectAll('.line-group')
            .data(this.data)
            .enter()
            .append('g')
            .attr('id', (d: ChartItem) => d.id)
            .attr('class', 'line-group')
            .style('opacity', (d: ChartItem) => (d.active ? 1 : 0));

        glines
            .append('path')
            .attr('class', 'line')
            .attr('d', (d) => line(d.points))
            .style('stroke', (d) =>
                this.colorService.getColorForEvaluation(d.id)
            )
            .style('stroke-dasharray', (d: ChartItem) =>
                d.id.endsWith('train') ? '10, 3' : '10000000, 0'
            )
            .style('fill', 'none')
            .attr('stroke-width', 1.5);

        const mouseG = this.graphRoot
            .append('g')
            .attr('class', 'mouse-over-effects');
        mouseG
            .append('path')
            .attr('class', 'mouse-line')
            .style('stroke', '#a9a9a9')
            .style('stroke-width', 1.5)
            .style('opacity', 0);

        const mousePerLine = mouseG
            .selectAll('.mouse-per-line')
            .data(this.data)
            .enter()
            .append('g')
            .attr('class', 'mouse-per-line');
        const circles = mousePerLine
            .append('circle')
            .attr('r', 4)
            .style('stroke', (d) =>
                this.colorService.getColorForEvaluation(d.id)
            )
            .style('fill', 'none')
            .style('stroke-width', 1.5)
            .style('opacity', 0);

        mouseG
            .append('svg:rect')
            .attr('width', this.getCalcDiagramWidth())
            .attr('height', this.getCalcDiagramHeight())
            .attr('fill', 'none')
            .attr('pointer-events', 'all')
            .on('mouseout', () => {
                d3.select(`#${this.idPrefix}`)
                    .select('.mouse-line')
                    .style('opacity', 0);
                circles.style('opacity', 0);
                d3.select(`#${this.idPrefix}-tooltip`).style('display', 'none');
            })
            .on('mouseover', () => {
                d3.select(`#${this.idPrefix}`)
                    .select('.mouse-line')
                    .style('opacity', 1);
                circles.style('opacity', (d: ChartItem) => (d.active ? 1 : 0));
                d3.select(`#${this.idPrefix}-tooltip`).style(
                    'display',
                    'block'
                );
            })
            .on('mousemove', (event) => {
                const pointer = d3.pointer(event);
                this.svg
                    .selectAll('.mouse-per-line')
                    .attr('transform', (d: ChartItem) => {
                        const idx = this.getIdx(pointer, d);
                        d3.select(`#${this.idPrefix}`)
                            .select('.mouse-line')
                            .attr('d', () => {
                                return `M${this.xScale(d.points[idx].x)},${
                                    this._height
                                } ${this.xScale(d.points[idx].x)},0`;
                            });
                        return `translate(${this.xScale(
                            d.points[idx].x
                        )},${this.yScale(d.points[idx].y)})`;
                    });
                this.updateTooltipContent(pointer, event);
                // TODO: comment back in to route to different plots
                // })
                // .on('click', (event: PointerEvent) => {
                //     const pointer = d3.pointer(event);
                //     const x = this.xScale.invert(pointer[0]);
                //     const bisect = d3.bisector((d: any) => d.x).left;
                //     const idx = bisect(this.data[0].points, x);
                //     this.clickEvent.emit(this.data[0].points[idx].x);
            });
    }

    updateLineVisability(item: ChartItem): void {
        d3.select(`#${item.id}`).style('opacity', item.active ? 1 : 0);
    }

    private updateTooltipContent(pointer, event: MouseEvent): void {
        const idx = this.getIdx(pointer, this.data[0]);
        const divRect = this.parentDiv.getBoundingClientRect();
        let mouseOverXLabel: string = 'Iteration';
        const splitLabel = this.data[0].label.split('.');
        const label = splitLabel[splitLabel.length - 1];
        if (this.mouseOverMapping.hasOwnProperty(label)) {
            mouseOverXLabel = this.translate.instant(
                this.mouseOverMapping[label]
            );
        }
        this.tooltip
            .html(
                `${this.tooltipLabel(
                    this.data[0].points[idx].x,
                    mouseOverXLabel
                )}`
            )
            .style('display', 'block')
            .style('left', `${event.clientX - divRect.left + 5}px`)
            .style('top', `${event.clientY - divRect.top + 5}px`)
            .style('font-size', '14px')
            .selectAll()
            .data(this.data.filter((d) => d.active))
            .enter()
            .append('div')
            .style('color', (d: ChartItem) =>
                this.colorService.getColorForEvaluation(d.id)
            )
            .style('font-size', '12px')
            .html((d) => {
                const index = this.getIdx(pointer, d);
                return `${this.translate.instant(d.label)}: ${
                    d.points[index].y
                }`;
            });
    }

    private getIdx(pointer: any, d: ChartItem): number {
        const x = this.xScale.invert(pointer[0]);
        const bisect = d3.bisector((dist: ChartPoint) => dist.x).left;
        const idx = bisect(d.points, x, 1);

        const pointRight = d.points[idx - 1];
        const pointLeft = d.points[idx];
        return x - pointRight.x > pointLeft.x - x ? idx : idx - 1;
    }

    private createTicksSet(
        xMin: number,
        xMax: number,
        numberOfSteps: number = 10
    ): Set<number> {
        let resultSet: Set<number> = new Set<number>([xMin, xMax]);
        for (let i = 1; i <= 10; i++) {
            resultSet.add(Math.floor((xMax * i) / numberOfSteps));
        }
        if (xMin > 0) {
            resultSet.delete(0);
        }
        return resultSet;
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }
}
