import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ImgCategory } from '../model/img-category';
import { DeleteCategoryDialogComponent } from './delete-category-dialog/delete-category-dialog.component';
import { ColorService } from '../../shared/services/color.service';
import { UserCacheService } from 'src/app/xtra/user-cache/user-cache-service';
import { ImgDbService } from '../services/img-db.service';
import { ApplicationLockService } from 'src/app/shared/services/application-lock.service';
import {
    ConfirmationDialogComponent,
    ConfirmationDialogData,
} from '../../shared/components/confirmation-modal/confirmation-dialog.component';
import { SortingKey } from '../img-db.component';
import { AnnotationStateService } from '../services/annotation-state.service';
import { ImagesStorageService } from '../services/images-storage.service';

export type RadioButtons = 'images' | 'labels';
export type SortingOptions = 'A-Z' | 'Z-A' | '0-9' | '9-0';
export const COUNTING_GROUP_SELECTION_SAVE_LOCATION_PREFIX: string =
    'countingGroupOf';

@Component({
    selector: 'app-img-db-category-manager',
    templateUrl: './img-db-category-manager.component.html',
    styleUrls: ['./img-db-category-manager.component.scss'],
})
export class ImgDbCategoryManagerComponent
    implements OnInit, OnChanges, OnChanges
{
    readonly appLockMessage: string = 'appLock.categoryUpdate';

    @Input() imageDatabaseId: string;
    @Input() categories: ImgCategory[];
    @Input() selectedCategoryNames: string[];
    @Input() numberOfUncategorizedImages: number;
    @Input() isEmptyCategorySelected: boolean;
    @Input() isDbOfTraining: boolean = false;
    @Input() mode: string;
    @Input() selectedSorting: SortingOptions = 'A-Z';
    @Input() fetchOnInit: boolean;

    @Output() actionTaken = new EventEmitter<CategoryManagerAction>();

    @ViewChild('labelName') lblName: ElementRef;
    @ViewChild('categoryRenameInput') categoryRenameInput: ElementRef;
    public categoryInput = new FormControl('');
    public isFocusOnCategoryInput = false;

    availableOptions: RadioButtons[] = ['labels', 'images'];
    selectedOption: RadioButtons = 'images';
    sortingOptions: SortingOptions[] = ['A-Z', 'Z-A', '0-9', '9-0'];
    selectedSortingOption: FormControl<SortingOptions>;
    hoveredCategoryIndex: number | null = null;
    showSpinner: boolean = false;
    categoryMenuOpen: boolean = false;
    menuForIndex: number = -1;
    categoryIndexForRenaming: number = -1;
    currentRenamingCategoryName: string = null;
    selectedCategoryNameForDrawing: string = null;

    constructor(
        private dialog: MatDialog,
        public translate: TranslateService,
        private colorService: ColorService,
        private userCacheService: UserCacheService,
        private imageDbService: ImgDbService,
        private blockerService: ApplicationLockService,
        private annotationsStateService: AnnotationStateService,
        private imagesStorage: ImagesStorageService
    ) {}

    ngOnInit(): void {
        this.selectedSortingOption = new FormControl<SortingOptions>(
            this.selectedSorting
        );
        this.userCacheService
            .loadData(
                `${COUNTING_GROUP_SELECTION_SAVE_LOCATION_PREFIX}${this.imageDatabaseId}`
            )
            .subscribe((savedSelection: { selected: RadioButtons }) => {
                if (savedSelection) {
                    this.selectedOption = savedSelection.selected;
                }
                if (this.fetchOnInit) {
                    this.onSortingChange();
                }
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.categories) {
            this.showSpinner = false;
            this.categoryInput.enable({ emitEvent: false });
        }
    }

    isCategoryActive(category: ImgCategory): boolean {
        return (
            (this.mode === 'overview' &&
                this.selectedCategoryNames.includes(category.name)) ||
            (this.mode === 'annotate' &&
                this.selectedCategoryNameForDrawing === category.name)
        );
    }

    onClickCategory(category: ImgCategory) {
        if (this.categoryIndexForRenaming < 0) {
            if (this.mode === 'annotate') {
                if (
                    !category ||
                    this.selectedCategoryNameForDrawing === category.name
                ) {
                    this.selectedCategoryNameForDrawing = null;
                    this.actionTaken.emit(
                        new CategoryManagerAction(
                            CategoryManagerActionType.CATEGORY_CLICKED,
                            new CategoryClickedData(null)
                        )
                    );
                } else {
                    this.selectedCategoryNameForDrawing = category.name;
                    this.actionTaken.emit(
                        new CategoryManagerAction(
                            CategoryManagerActionType.CATEGORY_CLICKED,
                            new CategoryClickedData(category)
                        )
                    );
                }
            } else {
                this.actionTaken.emit(
                    new CategoryManagerAction(
                        CategoryManagerActionType.CATEGORY_CLICKED,
                        new CategoryClickedData(category)
                    )
                );
            }
        }
    }

    onClickEmptyCategory() {
        if (this.mode === 'overview') {
            this.actionTaken.emit(
                new CategoryManagerAction(
                    CategoryManagerActionType.EMPTY_CATEGORY_SELECTED
                )
            );
        }
    }

    onSortingChange() {
        this.showSpinner = true;
        this.categoryInput.disable({ emitEvent: false });
        const option: SortingOptions = this.selectedSortingOption.value;
        let suffix: string = '';
        if (option.startsWith('0') || option.startsWith('9')) {
            suffix = this.selectedOption;
        }
        this.actionTaken.emit(
            new CategoryManagerAction(
                CategoryManagerActionType.CATEGORY_SORTING_CHANGED,
                new CategorySortingChangedData({ option, suffix } as SortingKey)
            )
        );
    }

    onInputChange(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            const categoryForRenaming =
                this.categories[this.categoryIndexForRenaming];

            if (this.currentRenamingCategoryName === categoryForRenaming.name) {
                this.resetRenaming();
                return;
            }

            this.blockerService.setApplicationLock(this.appLockMessage);
            this.annotationsStateService
                .saveCommands(true, this.imagesStorage.selectedImage$.value?.id)
                .subscribe((_) => {
                    const conflictingCategory = this.categories.find(
                        (category: ImgCategory) =>
                            category.name === this.currentRenamingCategoryName
                    );
                    if (conflictingCategory) {
                        if (conflictingCategory.id !== categoryForRenaming.id) {
                            this.blockerService.releaseApplicationLock();
                            this.openMergeDialog(
                                new CategoryMergeParameter(
                                    categoryForRenaming.id,
                                    conflictingCategory.id
                                ),
                                conflictingCategory.name
                            );
                        }
                    } else {
                        this.renameCategory(categoryForRenaming.id);
                    }
                    this.resetRenaming();
                });
        }
        if (event.key === 'Escape') {
            this.resetRenaming();
        }
    }

    resetRenaming() {
        this.categoryIndexForRenaming = -1;
        this.currentRenamingCategoryName = null;
    }

    renameCategory(categoryId: string) {
        const newName = this.currentRenamingCategoryName;
        this.imageDbService
            .updateCategory(this.imageDatabaseId, categoryId, newName)
            .subscribe({
                next: () => {
                    this.actionTaken.emit(
                        new CategoryManagerAction(
                            CategoryManagerActionType.CATEGORY_RENAMED,
                            new CategoryRenameData(
                                this.categories.find(
                                    (category) => category.id === categoryId
                                ).name,
                                newName
                            )
                        )
                    );
                },
                error: (errorResponse) => {
                    this.blockerService.releaseApplicationLock();
                    if (errorResponse.status === '409') {
                        this.openMergeDialog(
                            new CategoryMergeParameter(
                                errorResponse.sourceId,
                                errorResponse.targetId
                            ),
                            newName
                        );
                    } else {
                        this.actionTaken.emit(
                            new CategoryManagerAction(
                                CategoryManagerActionType.UPDATE_STATE
                            )
                        );
                    }
                },
            });
    }

    onAfterClosed(data: CategoryMergeParameter) {
        if (data) {
            this.confirmMerge(data.sourceCategoryId, data.targetCategoryId);
        }
    }

    openMergeDialog(
        categoryMergeParameter: CategoryMergeParameter,
        targetCategoryName: string
    ) {
        this.dialog
            .open<
                ConfirmationDialogComponent,
                ConfirmationDialogData,
                CategoryMergeParameter
            >(ConfirmationDialogComponent, {
                data: {
                    headerTranslationKey:
                        'imgDb.labelManager.mergeDialog.header',
                    confirmationQuestion: {
                        translationKey:
                            'imgDb.labelManager.mergeDialog.question',
                        interpolateParameters: {
                            categoryName: targetCategoryName,
                        },
                    },
                    confirmButtonTranslationKey:
                        'imgDb.labelManager.mergeDialog.confirmText',
                    rejectButtonTranslationKey:
                        'imgDb.labelManager.mergeDialog.cancelText',
                    transferData: categoryMergeParameter,
                } as ConfirmationDialogData,
                width: '550px',
            })
            .afterClosed()
            .subscribe(this.onAfterClosed.bind(this));
    }

    confirmMerge(sourceCategoryId: string, targetCategoryId: string) {
        this.blockerService.setApplicationLock(this.appLockMessage);
        this.imageDbService
            .mergeCategory(
                this.imageDatabaseId,
                sourceCategoryId,
                targetCategoryId
            )
            .subscribe({
                next: () => {
                    this.actionTaken.emit(
                        new CategoryManagerAction(
                            CategoryManagerActionType.CATEGORY_MERGED,
                            new CategoryMergeData(
                                this.categories.find(
                                    (category) =>
                                        category.id === sourceCategoryId
                                ).name,
                                sourceCategoryId,
                                targetCategoryId
                            )
                        )
                    );
                },
                error: (_) => {
                    this.actionTaken.emit(
                        new CategoryManagerAction(
                            CategoryManagerActionType.UPDATE_STATE
                        )
                    );
                },
            });
    }

    onEnterKeypressAddCategory(categoryName: string) {
        if (
            categoryName &&
            !ImgCategory.includes(this.categories, categoryName)
        ) {
            this.showSpinner = true;
            this.categoryInput.disable({ emitEvent: false });
            this.actionTaken.emit(
                new CategoryManagerAction(
                    CategoryManagerActionType.CATEGORY_CREATED,
                    new CategoryCreatedData(categoryName)
                )
            );
            this.resetForm();
        }
        this.lblName.nativeElement.focus();
    }

    resetForm() {
        this.categoryInput.reset();
        this.lblName.nativeElement.focus();
    }

    onDeleteCategoryClick(event: Event, category: ImgCategory) {
        if (category.id === undefined) {
            return;
        }
        event.stopPropagation();
        this.closeCategoryMenu();
        const categoryIdToDelete: string = category.id;
        this.openDeletionDialog(categoryIdToDelete)
            .afterClosed()
            .subscribe((confirmedCategoryIdToDelete: string) => {
                if (confirmedCategoryIdToDelete) {
                    if (category.name === this.selectedCategoryNameForDrawing) {
                        this.onClickCategory(null);
                    }
                    this.actionTaken.emit(
                        new CategoryManagerAction(
                            CategoryManagerActionType.CATEGORY_DELETED,
                            new CategoryDeletedData(confirmedCategoryIdToDelete)
                        )
                    );
                }
            });
    }

    onCategoryMenuClick(event: Event, categoryIndex: number) {
        event.stopPropagation();
        if (this.menuForIndex !== categoryIndex) {
            this.menuForIndex = categoryIndex;
            this.categoryMenuOpen = true;
        } else {
            this.closeCategoryMenu();
        }
    }

    onRename(event: Event, categoryIndex: number) {
        if (this.mode === 'annotate') {
            this.onClickCategory(null);
        }
        event.stopPropagation();
        this.closeCategoryMenu();
        this.currentRenamingCategoryName = this.categories[categoryIndex].name;
        this.categoryIndexForRenaming = categoryIndex;
        setTimeout(() => this.categoryRenameInput?.nativeElement?.focus(), 300);
    }

    closeCategoryMenu() {
        this.categoryMenuOpen = false;
        this.menuForIndex = -1;
    }

    private openDeletionDialog(categoryToDelete: string) {
        return this.dialog.open(DeleteCategoryDialogComponent, {
            data: categoryToDelete,
            width: '650px',
            panelClass: 'delete-category-dialog', // can be used for global styling
        });
    }

    public onFocusHidePlaceholder() {
        this.isFocusOnCategoryInput = true;
    }

    public onFocusShowPlaceholder() {
        this.isFocusOnCategoryInput = false;
    }

    public getColor(category) {
        return this.colorService.getColor(category);
    }

    public onCountingGroupChange(group: RadioButtons) {
        if (group !== this.selectedOption) {
            this.userCacheService
                .saveData(
                    `${COUNTING_GROUP_SELECTION_SAVE_LOCATION_PREFIX}${this.imageDatabaseId}`,
                    { selected: group }
                )
                .subscribe();
            if (
                this.selectedSortingOption.value.startsWith('0') ||
                this.selectedSortingOption.value.startsWith('9')
            ) {
                this.selectedOption = group;
                this.onSortingChange();
            }
        }
    }
}

export class CategoryMergeParameter {
    constructor(
        public sourceCategoryId: string,
        public targetCategoryId: string
    ) {}
}

export class CategoryManagerAction {
    constructor(
        public type: CategoryManagerActionType,
        public parameter?:
            | CategoryCreatedData
            | CategoryClickedData
            | CategoryDeletedData
            | CategorySortingChangedData
            | CategoryRenameData
            | CategoryMergeData
    ) {}
}

export enum CategoryManagerActionType {
    CATEGORY_CREATED = 'CATEGORY_CREATED',
    CATEGORY_CLICKED = 'CATEGORY_CLICKED',
    CATEGORY_DELETED = 'CATEGORY_DELETED',
    EMPTY_CATEGORY_SELECTED = 'EMPTY_CATEGORY_SELECTED',
    CATEGORY_SORTING_CHANGED = 'CATEGORY_SORTING_CHANGED',
    CATEGORY_RENAMED = 'CATEGORY_RENAMED',
    CATEGORY_MERGED = 'CATEGORY_MERGED',
    UPDATE_STATE = 'UPDATE_STATE',
}

export class CategoryCreatedData {
    constructor(public categoryName: string) {}
}

export class CategoryClickedData {
    constructor(public category: ImgCategory | null) {}
}

export class CategoryDeletedData {
    constructor(public categoryId: string) {}
}

export class CategorySortingChangedData {
    constructor(public sortingKey: SortingKey) {}
}

export class CategoryRenameData {
    constructor(public oldName: string, public newName: string) {}
}

export class CategoryMergeData {
    constructor(
        public oldName: string,
        public oldId: string,
        public newId: string
    ) {}
}
