import { EventEmitter, Injectable } from '@angular/core';
import {
    BehaviorSubject,
    combineLatestWith,
    filter,
    map,
    mergeAll,
    mergeMap,
    Observable,
    ReplaySubject,
    take,
    tap,
    withLatestFrom,
} from 'rxjs';
import { SceneElement } from '../models/scene-element';
import { StateOfSceneElement } from '../models/state-of-scene-element';
import { SceneService } from './scene.service';
import { Scene } from '../models/scene';
import { Snapshot } from '../models/snapshot';
import { LoadedSceneElement } from '../models/loaded-scene-element';
import { BrowserAndPhysicsLoadable } from 'src/app/object3-d/models/browser-and-physics-loadable';
import { nonNil } from 'src/app/shared/utility';
import { Router } from '@angular/router';
import { SceneDiversity } from '../models/scene-diversity';
import { SceneDiversitySelection } from '../models/scene-diversity-selection';
import { SceneDiversitySelectionRequest } from '../models/scene-diversity-selection-request';
import { ISceneStateService } from './iscene-state.service';
import { InputLayerSceneElement } from 'src/app/skill-architecture-editor/skill-architecture/architecture-visualization/drawing/input-layer-scene-element';
import { TranslateService } from '@ngx-translate/core';
import { UserCacheService } from 'src/app/xtra/user-cache/user-cache-service';

@Injectable()
export class SceneStateService implements ISceneStateService {
    private loadedSceneElements$: BehaviorSubject<LoadedSceneElement[]> =
        new BehaviorSubject([]);
    scene$: BehaviorSubject<Scene> = new BehaviorSubject<Scene>(null);
    private newSceneElement$: ReplaySubject<LoadedSceneElement> =
        new ReplaySubject(256);
    private toBeRemovedSceneElements$: BehaviorSubject<LoadedSceneElement[]> =
        new BehaviorSubject([]);
    public sceneElementStateUpdated = new EventEmitter<StateOfSceneElement>();

    private sceneDiversity$: BehaviorSubject<SceneDiversity> =
        new BehaviorSubject({ base: null, exampleMotions: [] });
    private diversitySelection$: BehaviorSubject<SceneDiversitySelection> =
        new BehaviorSubject({});
    public additionalSceneElements$: BehaviorSubject<LoadedSceneElement[]> =
        new BehaviorSubject([]);
    public inputLayerSceneElements$: BehaviorSubject<InputLayerSceneElement[]> =
        new BehaviorSubject([]);

    private readonly userCachePathToSelectedSceneElementPrefix: string =
        'selectedSceneElementOf';

    constructor(
        private sceneService: SceneService,
        private translateService: TranslateService,
        private router: Router,
        private userCacheService: UserCacheService
    ) {
        this.scene$
            .pipe(
                filter(nonNil),
                tap((scene: Scene) => {
                    this.sceneDiversity$.next({
                        base: scene.base,
                        exampleMotions: scene.exampleMotions,
                    });
                }),
                withLatestFrom(this.diversitySelection$),
                map(
                    ([scene, diversitySelection]): [
                        Scene,
                        Snapshot,
                        LoadedSceneElement[]
                    ] => {
                        if (diversitySelection.exampleMotion) {
                            this.removeAdditionalSceneElements();

                            const newExampleMotion = scene.exampleMotions.find(
                                (em) =>
                                    em.id ===
                                    diversitySelection.exampleMotion.id
                            );

                            const loadedAdditionalElements =
                                this.loadSceneElementsWithoutState(
                                    newExampleMotion.additionalElements
                                );
                            return [
                                scene,
                                newExampleMotion.trajectory[0],
                                loadedAdditionalElements,
                            ];
                        } else if (!diversitySelection.base) {
                            return [scene, undefined, []];
                        }
                        return [scene, scene.base, []];
                    }
                ),
                tap(([scene, snapshot, additionalElements]) => {
                    if (snapshot) {
                        scene.loadedSceneElements =
                            this.loadSceneElementsWithoutState(
                                scene.sceneElements
                            ).concat(additionalElements);

                        this.setLoadedElementsStates(
                            scene.loadedSceneElements,
                            snapshot
                        );

                        this.additionalSceneElements$.next(additionalElements);
                        scene.loadedSceneElements.forEach(
                            (lse: LoadedSceneElement) => {
                                this.newSceneElement$.next(lse);
                            }
                        );
                        this.toBeRemovedSceneElements$.next(
                            this.loadedSceneElements$.value
                        );
                        //only emit loadedSceneElements if states are also loaded,
                        //otherwise no joints are displayed for robot
                        this.loadedSceneElements$.next(
                            scene.loadedSceneElements
                        );
                    }
                })
            )
            .subscribe();
    }

    // required for display of sceneElements in costfunction
    // only loaded elements are displayed
    public loadSceneElementsForCostFunction() {
        let scene = this.scene$.value;
        scene.loadedSceneElements = this.loadSceneElementsWithoutState(
            scene.sceneElements
        );
        this.loadedSceneElements$.next(scene.loadedSceneElements);
    }

    public set trainingId(trainingId: string) {
        this.sceneService
            .getScene(trainingId)
            .pipe(tap(this.scene$.next.bind(this.scene$)))
            .subscribe({
                next: null,
                error: (error) => {
                    console.error(error);
                    this.router.navigate(['/404']);
                    throw error;
                },
            });
    }

    public get observeScene$(): Observable<Scene> {
        return this.scene$.asObservable();
    }

    public get observeIncomingSceneElements$(): Observable<LoadedSceneElement> {
        return this.newSceneElement$.asObservable();
    }

    public get observeToBeRemovedSceneElements$(): Observable<
        LoadedSceneElement[]
    > {
        return this.toBeRemovedSceneElements$.asObservable();
    }

    public get observeLoadedSceneElements$(): Observable<LoadedSceneElement[]> {
        return this.loadedSceneElements$.pipe(
            map((elements: LoadedSceneElement[]) =>
                elements.filter(
                    (element: LoadedSceneElement) =>
                        element.sceneElement.browserAndPhysicsLoadable
                            .isRobot === false
                )
            )
        );
    }

    public get getDiversitySelection$(): Observable<SceneDiversitySelection> {
        return this.diversitySelection$.asObservable();
    }

    public get getDiversitySelection(): SceneDiversitySelection {
        return this.diversitySelection$.value;
    }

    public get robot$(): Observable<LoadedSceneElement> {
        return this.loadedSceneElements$.pipe(
            mergeAll(),
            filter(
                (element: LoadedSceneElement) =>
                    element.sceneElement.browserAndPhysicsLoadable.isRobot ===
                    true
            ),
            take(1)
        );
    }

    public get baseSceneElements(): LoadedSceneElement[] {
        return this.loadedSceneElements$.value;
    }

    /**
     * Implicit assumption: a threeId only exists for loaded elements.
     * Therefore the object3D must be loaded already.
     */
    public findElementByThreeId(threeId: number): LoadedSceneElement {
        return this.findIdInElementList(
            threeId,
            this.loadedSceneElements$.value
        );
    }

    public asyncFindElementByThreeId(
        threeId: number
    ): Observable<LoadedSceneElement> {
        return this.loadedSceneElements$.asObservable().pipe(
            filter(nonNil),
            take(1),
            map((objects) => this.findIdInElementList(threeId, objects))
        );
    }

    private findIdInElementList(id: number, elements: LoadedSceneElement[]) {
        return elements.find(
            (loadedSceneElement: LoadedSceneElement) =>
                loadedSceneElement.object3D$.value?.id === id
        );
    }

    private loadSceneElementsWithoutState(sceneElements: SceneElement[]) {
        return sceneElements.map((sceneElement) => {
            let loadedSceneElement = new LoadedSceneElement();
            loadedSceneElement.sceneElement = sceneElement;
            return loadedSceneElement;
        });
    }

    private setLoadedElementsStates(
        currentContext: LoadedSceneElement[],
        snapshot: Snapshot
    ) {
        currentContext.forEach((loadedSceneElement) => {
            loadedSceneElement.state = snapshot.states.find(
                (itr: StateOfSceneElement) =>
                    itr.elementId === loadedSceneElement.sceneElement.id
            );
        });
    }

    public addObject(
        loadable: BrowserAndPhysicsLoadable,
        trainingId: string
    ): void {
        this.addSceneElement(loadable)
            .pipe(
                // FIXME: LEA-797
                mergeMap(() => this.sceneService.getScene(trainingId)),
                tap((newScene) => {
                    this.scene$.next(newScene);
                })
            )
            .subscribe();
    }

    private updateDiversity(
        newScene: Scene,
        currSelection: SceneDiversitySelection
    ): void {
        if (nonNil(currSelection.base)) {
            currSelection.base = newScene.base;
        } else {
            currSelection.exampleMotion = newScene.exampleMotions.find(
                (em) => em.id === currSelection.exampleMotion.id
            );
        }
        this.diversitySelection$.next(currSelection);
    }

    private addSceneElement(
        loadable: BrowserAndPhysicsLoadable
    ): Observable<Scene> {
        return this.sceneService.addSceneElement(
            this.scene$.value.id,
            this.toSelectionRequest(this.diversitySelection$.value),
            loadable
        );
    }

    public updateSceneElementScale(sceneElement: SceneElement) {
        this.sceneService
            .patchSceneElementScale(sceneElement.id, sceneElement.scale)
            .subscribe();
    }

    public updateSceneElementFixed(sceneElementId: string, isfixed: boolean) {
        this.sceneService
            .patchSceneElementFixed(sceneElementId, isfixed)
            .subscribe();
    }

    public setSelectedSceneElement(sceneElementId: string) {
        this.userCacheService
            .saveData(
                `${this.userCachePathToSelectedSceneElementPrefix}${this.scene$.value.id}`,
                { selectedSceneElementId: sceneElementId }
            )
            .subscribe();
    }

    public deleteSceneElementSelection() {
        this.userCacheService
            .saveData(
                `${this.userCachePathToSelectedSceneElementPrefix}${this.scene$.value.id}`,
                { selectedSceneElementId: '' }
            )
            .subscribe();
    }

    public get selectedSceneElementId(): string {
        return this.scene$.value
            ? this.scene$.value.selectedSceneElementId
            : '';
    }

    public removeObject(sceneElement: SceneElement, trainingId: string) {
        this.sceneService
            .removeSceneElement(sceneElement.id)
            .pipe(
                // FIXME: LEA-797
                mergeMap(() => this.sceneService.getScene(trainingId)),
                withLatestFrom(this.loadedSceneElements$),
                map(([newScene, loadedSceneElements]) => {
                    this.toBeRemovedSceneElements$.next([
                        loadedSceneElements.find(
                            (lse) => lse.sceneElement.id === sceneElement.id
                        ),
                    ]);
                    return newScene;
                }),
                withLatestFrom(this.diversitySelection$),
                tap(([newScene, diversity]) => {
                    this.updateDiversity(newScene, diversity);
                    this.scene$.next(newScene);
                })
            )
            .subscribe({
                error: console.error,
            });
    }

    public updateStateOfLoadedSceneElement(
        state: StateOfSceneElement,
        fromScene: boolean = false
    ) {
        this.sceneService
            .patchSceneElementState(state)
            .pipe(filter(() => fromScene))
            .subscribe({
                next: () => this.fireStateUpdated(state),
            });
    }

    public fireStateUpdated(state: StateOfSceneElement) {
        this.sceneElementStateUpdated.emit(state);
    }

    setSceneDiversity(scene: Scene) {
        this.sceneDiversity$.next({
            base: scene.base,
            exampleMotions: scene.exampleMotions,
        });
        this.diversitySelection$.next({
            base: scene.base,
        });
    }

    getSceneDiversity(): Observable<SceneDiversity> {
        return this.sceneDiversity$.asObservable();
    }

    public selectExampleMotion(exampleMotionId: number) {
        const selectedExampleMotion =
            this.sceneDiversity$.value.exampleMotions.find(
                (item) => item.id === exampleMotionId
            );

        if (!selectedExampleMotion) return;

        this.changeDiversitySelection({
            base: undefined,
            exampleMotion: selectedExampleMotion,
        });
    }

    public changeDiversitySelection(desiredSelection: SceneDiversitySelection) {
        if (this.noTransition(desiredSelection)) {
            return;
        }
        if (this.transitionFromExampleMotion(desiredSelection)) {
            this.removeAdditionalSceneElements();
        }

        if (this.transitionToExampleMotion(desiredSelection)) {
            this.loadAdditionalSceneElements(desiredSelection);
        } else if (this.transitionExampleMotionToBase(desiredSelection)) {
            this.additionalSceneElements$.next([]);
        }

        this.setLoadedElementsStates(
            this.loadedSceneElements$.value,
            desiredSelection.base ??
                desiredSelection.exampleMotion.trajectory[0]
        );

        this.diversitySelection$.next(desiredSelection);
    }

    private noTransition(desiredSelection: SceneDiversitySelection): boolean {
        return (
            (!!this.diversitySelection$.value.base &&
                !!desiredSelection.base) ||
            (!!desiredSelection.exampleMotion &&
                this.diversitySelection$.value.exampleMotion?.id ===
                    desiredSelection?.exampleMotion.id)
        );
    }

    private loadAdditionalSceneElements(
        desiredSelection: SceneDiversitySelection
    ) {
        let snapshot = desiredSelection.exampleMotion.trajectory[0];
        let loadedAdditionalElements = this.loadSceneElementsWithoutState(
            desiredSelection.exampleMotion.additionalElements
        );
        this.setLoadedElementsStates(loadedAdditionalElements, snapshot);
        this.additionalSceneElements$.next(loadedAdditionalElements);
        this.loadedSceneElements$.next(
            this.loadedSceneElements$.value.concat(loadedAdditionalElements)
        );
    }

    private removeAdditionalSceneElements() {
        this.additionalSceneElements$
            .pipe(
                take(1),
                withLatestFrom(this.loadedSceneElements$),
                tap(([additionalElements, context]) => {
                    additionalElements.forEach(
                        (additionalElement: LoadedSceneElement) => {
                            let index = context.indexOf(additionalElement);
                            context.splice(index, 1);
                        }
                    );
                    this.loadedSceneElements$.next(context);
                    this.toBeRemovedSceneElements$.next(additionalElements);
                })
            )
            .subscribe();
    }

    private transitionToExampleMotion(
        desiredSelection: SceneDiversitySelection
    ): boolean {
        return (
            this.transitionBaseToExampleMotion(desiredSelection) ||
            this.transitionExampleMotionToExampleMotion(desiredSelection)
        );
    }

    private transitionFromExampleMotion(
        desiredSelection: SceneDiversitySelection
    ): boolean {
        return (
            this.transitionExampleMotionToExampleMotion(desiredSelection) ||
            this.transitionExampleMotionToBase(desiredSelection)
        );
    }

    private transitionExampleMotionToExampleMotion(
        desiredSelection: SceneDiversitySelection
    ): boolean {
        return (
            !!this.diversitySelection$.value.exampleMotion?.id &&
            !!desiredSelection.exampleMotion?.id &&
            this.diversitySelection$.value.exampleMotion.id !==
                desiredSelection.exampleMotion?.id
        );
    }

    private transitionExampleMotionToBase(
        desiredSelection: SceneDiversitySelection
    ): boolean {
        return (
            !!this.diversitySelection$.value.exampleMotion &&
            !!desiredSelection.base
        );
    }

    private transitionBaseToExampleMotion(
        desiredSelection: SceneDiversitySelection
    ): boolean {
        return (
            !!this.diversitySelection$.value.base &&
            !!desiredSelection.exampleMotion
        );
    }

    public createNewExampleMotion(
        selector: SceneDiversitySelection,
        trainingId: string
    ) {
        this.sceneService
            .createNewExampleMotion(
                this.scene$.value.id,
                this.toSelectionRequest(selector)
            )
            .pipe(
                // FIXME: LEA-797
                mergeMap(() => this.sceneService.getScene(trainingId)),
                withLatestFrom(this.diversitySelection$),
                tap(([newScene, diversity]) => {
                    this.updateDiversity(newScene, diversity);
                    this.scene$.next(newScene);
                })
            )
            .subscribe();
    }

    private toSelectionRequest(
        selector: SceneDiversitySelection
    ): SceneDiversitySelectionRequest {
        return {
            type: selector.base ? 'BASE' : 'EXAMPLE_MOTION',
            exampleMotionId: selector.base
                ? undefined
                : selector.exampleMotion.id,
            exampleMotionName: selector.base
                ? this.translateService.instant(
                      'sceneDiversity.exampleMovement'
                  )
                : selector.exampleMotion.name,
        };
    }

    public deleteExampleMotion(exampleMotionId: number, trainingId: string) {
        this.sceneService
            // FIXME: LEA-797
            .deleteExampleMotion(exampleMotionId)
            .pipe(mergeMap(() => this.sceneService.getScene(trainingId)))
            .subscribe((scene: Scene) => {
                this.scene$.next(scene);
            });
    }

    public getUnfixedElements(trainingId: string) {
        this.sceneService
            .getUnfixedElementsByTraining(trainingId)
            .pipe(combineLatestWith(this.robot$))
            .subscribe(([sceneElements, robot]) => {
                const allSceneElements = [
                    {
                        id: robot.sceneElement.id,
                        name: robot.sceneElement.browserAndPhysicsLoadable.name,
                        isRobot: true,
                    },
                ];

                const unfixedSceneElements = sceneElements
                    .filter((el: SceneElement) => {
                        return el.id !== robot.sceneElement.id;
                    })
                    .map((el: SceneElement) => {
                        return {
                            id: el.id,
                            name: el.browserAndPhysicsLoadable.name,
                            isRobot: false,
                        };
                    });

                this.inputLayerSceneElements$.next(
                    allSceneElements.concat(unfixedSceneElements)
                );
            });
    }
}
