import { Injectable } from '@angular/core';
import { ImgDbService } from './img-db.service';
import {
    BehaviorSubject,
    forkJoin,
    map,
    Observable,
    Subject,
    switchMap,
} from 'rxjs';
import { PagingResult } from '../../shared/models/pageable';
import { ImgWithSelection } from '../model/img-with-selection';
import { UserCacheService } from '../../xtra/user-cache/user-cache-service';
import { AnnotationStateService } from './annotation-state.service';

@Injectable()
export class ImagesStorageService {
    isEmptyCategorySelected: boolean = false;
    selectedCategoryNames: string[] = [];
    currentPage: number = 1;
    pageSize: number;
    dbId: string;

    selectedImage$: BehaviorSubject<ImgWithSelection> =
        new BehaviorSubject<ImgWithSelection>(null);

    imagesPage$: BehaviorSubject<PagingResult<ImgWithSelection>> =
        new BehaviorSubject<PagingResult<ImgWithSelection>>(null);

    // Storage is ready to fetch images after ImgDbComponent provided ImgDbID and pageSize
    isStorageReady: boolean = false;
    pageChanged$: Subject<void> = new Subject<void>();

    constructor(
        private dbService: ImgDbService,
        private cacheService: UserCacheService,
        private annotationStateService: AnnotationStateService
    ) {}

    setData(pageSize: number, dbId: string) {
        this.dbId = dbId;
        this.pageSize = pageSize;
        this.isStorageReady = true;
    }

    getImages(
        handleBookmarks: boolean = true
    ): Observable<PagingResult<ImgWithSelection>> {
        if (!this.isStorageReady) {
            return;
        }

        let observables: Observable<any>[] = [
            this.dbService.getImages(
                this.selectedCategoryNames,
                this.isEmptyCategorySelected,
                this.dbId,
                this.currentPage,
                this.pageSize
            ),
        ];

        if (handleBookmarks) {
            observables.push(
                this.cacheService.loadData(`workspace_image_db_${this.dbId}`)
            );
        }
        return this.updateImages(observables, handleBookmarks);
    }

    private updateImages(
        obs: Observable<any>[],
        handleBookmarks: boolean
    ): Observable<PagingResult<ImgWithSelection>> {
        return forkJoin(obs).pipe(
            map(([page, data]) => {
                if (handleBookmarks) {
                    page.values.forEach((img: ImgWithSelection) => {
                        img.isBookmarked =
                            data?.bookmarkedImageIds?.includes(img.id) ?? false;
                    });
                }
                this.imagesPage$.next(page);
                return page;
            })
        );
    }

    updateImageSelection(indexOfImageToBeSelected: number) {
        this.annotationStateService
            .saveCommands(true, this.selectedImage$.value?.id)
            .subscribe();
        const images: ImgWithSelection[] = this.imagesPage$.value.values;
        if (images.length > 0) {
            this.selectedImage$.next(images[indexOfImageToBeSelected]);
        }
    }

    toggleBookmark() {
        if (this.selectedImage$.value === null) {
            return;
        }

        this.selectedImage$.value.isBookmarked =
            !this.selectedImage$.value.isBookmarked;
        this.updateImagesList();

        this.cacheService
            .loadData(`workspace_image_db_${this.dbId}`)
            .pipe(
                switchMap((data) => {
                    let bookmarks: string[] = data?.bookmarkedImageIds || [];

                    if (this.selectedImage$.value.isBookmarked) {
                        bookmarks.push(this.selectedImage$.value.id);
                    } else {
                        const idx = bookmarks.indexOf(
                            this.selectedImage$.value.id
                        );
                        if (idx !== -1) bookmarks.splice(idx, 1);
                    }

                    return this.cacheService.saveData(
                        `workspace_image_db_${this.dbId}`,
                        {
                            bookmarkedImageIds: bookmarks,
                        }
                    );
                })
            )
            .subscribe();
    }

    private updateImagesList() {
        this.imagesPage$.value.values.forEach((img: ImgWithSelection) => {
            if (img.id === this.selectedImage$.value.id) {
                img.isBookmarked = this.selectedImage$.value.isBookmarked;
            }
        });
    }

    changePage(newPage: number) {
        this.pageChanged$.next();
        this.currentPage = newPage;
    }
}
