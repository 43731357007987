import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HierarchyPointNode, select } from 'd3';
import { UserCacheService } from 'src/app/xtra/user-cache/user-cache-service';
import { TreeParameters } from '../../models/tree-parameters';
import {
    emptyTrainingTreeNode,
    TrainingTreeNode,
} from '../../models/training-tree-node';
import { RightClickMenuPosition } from '../../models/right-click-menu-position';
import {
    NODE_HEIGHT,
    NODE_MARGIN_LEFT_RIGHT,
    NODE_WIDTH,
} from '../../models/tree-drawing-properties';
import { TreeDrawingService } from '../../services/tree-drawing.service';
import { TreeNodeToDrawableService } from '../../services/tree-node-to-drawable.service';
import { TreeStateService } from '../../services/tree-state.service';
import { Subscription } from 'rxjs';
import { SidebarNavService } from '../../../navigation/sidebar-nav/services/sidebar-nav.service';
import { ProjectSplitScreenService } from '../../../project-split-screen/services/project-split-screen.service';

@Component({
    selector: 'app-tree',
    templateUrl: './tree.component.html',
    styleUrls: ['./tree.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TreeComponent implements OnInit, AfterViewInit, OnDestroy {
    minScale: number = 1;

    leftOffset: number = 0;
    topOffset: number = 0;

    maxLeftOffset: number = 0;
    maxTopOffset: number = 0;

    isDragOn: boolean = false;

    treeHeight: number = 0;

    projectId: string = '';

    rightClickMenuStyles: RightClickMenuPosition = new RightClickMenuPosition(
        '0',
        '0'
    );

    private initTree: Subscription;
    private selectedTreeNodeSub: Subscription;
    private resizeSubscriptions: Subscription[] = [];

    constructor(
        private treeDrawingService: TreeDrawingService,
        public treeStateService: TreeStateService,
        private treeNodeToDrawableService: TreeNodeToDrawableService,
        private route: ActivatedRoute,
        private changeDetector: ChangeDetectorRef,
        private userCacheService: UserCacheService,
        private router: Router,
        private sidebarNavService: SidebarNavService,
        private projectSplitScreenService: ProjectSplitScreenService
    ) {}

    @HostListener('window:resize', ['$event'])
    onResize(_: unknown) {
        this.sendTreeSvgDimension();
        setTimeout(() => {
            this.treeDrawingService.calculateMeasurements();
        }, 1000);
    }

    @ViewChild('treeContainer')
    treeContainer: ElementRef;

    @ViewChild('menu')
    menu: ElementRef;

    ngOnInit(): void {
        this.projectId = this.route.snapshot.paramMap.get('projectId');
        this.treeStateService.projectId = this.projectId;
        const trainingId =
            this.route.snapshot.queryParamMap.get('trainingUuid');
        this.getTreeParameters();

        this.treeStateService.startTreeUpdates();
        this.treeDrawingService.treeData.subscribe(
            (data: Record<string, number>) => {
                this.minScale = data.minScale;
                this.maxLeftOffset = data.maxLeftOffset;
                this.maxTopOffset = data.maxTopOffset;
                this.treeHeight = data.treeHeight;

                if (
                    this.maxLeftOffset <= 0 &&
                    Math.abs(this.leftOffset) > Math.abs(this.maxLeftOffset)
                ) {
                    this.leftOffset = this.maxLeftOffset;
                }

                if (
                    this.maxTopOffset <= 0 &&
                    Math.abs(this.topOffset) > Math.abs(this.maxTopOffset)
                ) {
                    this.topOffset = this.maxTopOffset;
                }

                if (
                    this.treeDrawingService.scale &&
                    this.treeDrawingService.scale < this.minScale
                ) {
                    this.treeDrawingService.scale = this.minScale;
                }
                this.updateBounds();
                this.changeDetector.detectChanges();
            }
        );

        this.treeStateService.onTrainingCloned.subscribe(() => {
            this.onTrainingCloned();
        });
        this.initTree = this.treeStateService.refreshTreeState().subscribe();

        this.selectedTreeNodeSub =
            this.treeStateService.selectedTreeNode$.subscribe((treeNode) => {
                this.treeStateService.selectedTrainingId =
                    treeNode === emptyTrainingTreeNode
                        ? trainingId
                        : treeNode.uuid;
                this.router.navigate([], {
                    skipLocationChange: false,
                    relativeTo: this.route,
                    queryParams: {
                        trainingUuid: treeNode.uuid,
                    },
                    queryParamsHandling: 'merge',
                });
            });

        this.resizeSubscriptions.push(
            this.sidebarNavService.sidebarNavExpansion$.subscribe((_) => {
                this.sendTreeSvgDimension();
                setTimeout(() => {
                    this.treeDrawingService.calculateMeasurements();
                }, 1000);
            })
        );

        this.resizeSubscriptions.push(
            this.projectSplitScreenService.splitScreenStateChange$.subscribe(
                (_) => {
                    this.sendTreeSvgDimension();
                    setTimeout(() => {
                        this.treeDrawingService.calculateMeasurements();
                    }, 1000);
                }
            )
        );
    }

    getTreeParameters() {
        this.loadTreeLayout();
    }

    loadTreeLayout() {
        this.userCacheService.loadData(this.projectId).subscribe({
            next: this.setTreeLayout.bind(this),
        });
    }

    setTreeLayout(data: TreeParameters) {
        if (!data) {
            return;
        }
        this.leftOffset = data.treeVerticalOffset;
        this.topOffset = data.treeHorizontalOffset;
        this.treeDrawingService.scale = data.treeScale;
        setTimeout(() => {
            this.treeDrawingService.calculateMeasurements();
        }, 1000);
    }

    onTrainingCloned() {
        const uuid = this.treeStateService.selectedTreeNode.uuid;
        const node = this.treeNodeToDrawableService.drawableTree.find(
            (treeNode: HierarchyPointNode<TrainingTreeNode>) => {
                return treeNode.data.uuid === uuid;
            }
        );
        if (node) {
            this.handleClonedTrainingRightBorder(node);
            this.handleClonedTrainingBottomBorder(node);
        }
    }

    handleClonedTrainingRightBorder(
        node: HierarchyPointNode<TrainingTreeNode>
    ) {
        const rightBorder =
            node.x * this.treeDrawingService.scale +
            (this.treeContainer.nativeElement.offsetWidth *
                (1 - this.treeDrawingService.scale)) /
                2 +
            NODE_WIDTH * this.treeDrawingService.scale +
            this.leftOffset -
            NODE_MARGIN_LEFT_RIGHT / 2 -
            this.treeContainer.nativeElement.offsetWidth;
        if (rightBorder > 0) {
            const target = this.leftOffset - rightBorder;
            this.maxLeftOffset = target;
            const time = 500 / rightBorder;
            let step = 1;
            if (time < 1) {
                step /= time;
            }
            const interval = setInterval(() => {
                if (this.leftOffset > target) {
                    this.leftOffset -= step;
                } else {
                    clearInterval(interval);
                }
            }, time);
        }
    }

    handleClonedTrainingBottomBorder(
        node: HierarchyPointNode<TrainingTreeNode>
    ) {
        const bottomBorder =
            node.y * this.treeDrawingService.scale +
            (this.treeContainer.nativeElement.offsetHeight *
                (1 - this.treeDrawingService.scale)) /
                2 +
            NODE_HEIGHT * this.treeDrawingService.scale +
            this.topOffset -
            this.treeContainer.nativeElement.offsetHeight;
        if (bottomBorder > 0) {
            const target = this.topOffset - bottomBorder;
            this.maxTopOffset = target;
            const time = 500 / bottomBorder;
            let step = 1;
            if (time < 1) {
                step /= time;
            }
            const interval = setInterval(() => {
                if (this.topOffset > target) {
                    this.topOffset -= step;
                } else {
                    clearInterval(interval);
                }
            }, time);
        }
    }

    ngAfterViewInit(): void {
        this.treeDrawingService.svgElement = select('#tree-svg');
        this.sendTreeSvgDimension();
    }

    ngOnDestroy(): void {
        this.userCacheService
            .saveData(this.projectId, {
                treeVerticalOffset: this.leftOffset,
                treeHorizontalOffset: this.topOffset,
                treeScale: this.treeDrawingService.scale,
            } as TreeParameters)
            .subscribe();

        this.selectedTreeNodeSub.unsubscribe();
        this.initTree.unsubscribe();
        this.treeStateService.cleanup();
        this.resizeSubscriptions.forEach((subscription: Subscription) =>
            subscription.unsubscribe()
        );
    }

    private sendTreeSvgDimension() {
        this.treeNodeToDrawableService.treeDimension = {
            width: this.treeContainer.nativeElement.offsetWidth,
            height: this.treeContainer.nativeElement.offsetHeight,
        };
    }

    mousewheel(event: WheelEvent) {
        const deltaX = event.deltaY;

        let newScale = this.treeDrawingService.scale - deltaX / 1000;
        if (newScale < this.minScale) {
            newScale = this.minScale;
        }
        if (newScale > 1) {
            newScale = 1;
        }
        this.leftOffset =
            (this.leftOffset / this.treeDrawingService.scale) * newScale;
        this.topOffset =
            (this.topOffset / this.treeDrawingService.scale) * newScale;
        this.treeDrawingService.scale = newScale;
        this.treeDrawingService.calculateMeasurements();
        this.updateBounds();
    }

    mousedown() {
        this.isDragOn = true;
    }

    mouseup() {
        this.isDragOn = false;
    }

    mousemove(event: MouseEvent) {
        if (this.isDragOn) {
            const deltaX = event.movementX;
            const deltaY = event.movementY;

            if (this.maxLeftOffset < 0) {
                const maxLeftOffset = Math.abs(this.maxLeftOffset);
                let currentLeftOffset = this.leftOffset + deltaX;
                if (currentLeftOffset > maxLeftOffset) {
                    currentLeftOffset = maxLeftOffset;
                } else if (Math.abs(currentLeftOffset) > -this.maxLeftOffset) {
                    currentLeftOffset = this.maxLeftOffset;
                }
                this.leftOffset = currentLeftOffset;
            }

            if (this.maxTopOffset < 0) {
                const maxTopOffset = Math.abs(this.maxTopOffset);
                const maxBottomOffset = -this.maxTopOffset;
                let currentTopOffset = this.topOffset + deltaY;
                if (currentTopOffset > maxTopOffset) {
                    currentTopOffset = maxTopOffset;
                } else if (Math.abs(currentTopOffset) > maxBottomOffset) {
                    currentTopOffset = -maxBottomOffset;
                }
                this.topOffset = currentTopOffset;
            }
        }
    }

    private updateBounds() {
        if (this.maxLeftOffset > this.maxTopOffset) {
            if (Math.abs(this.leftOffset) > -this.maxLeftOffset) {
                this.leftOffset = this.maxLeftOffset;
            }
        } else {
            if (Math.abs(this.topOffset) > -this.maxTopOffset) {
                this.topOffset = this.maxTopOffset;
            }
        }
    }

    getTreeScale() {
        return this.treeDrawingService.scale;
    }
}
