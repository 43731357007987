<div class="container-wrapper">
    <div class="container">
        <div class="row">
            <span
                (click)="setSelection(i)"
                class="label-item"
                [attr.aria-current]="isCategorySelected(i)"
                *ngFor="let category of categories; let i = index"
                [ngStyle]="{
                    backgroundColor: isCategorySelected(i)
                        ? getActiveColor(category.name)
                        : getDefaultColor(category.name),
                    color: isCategorySelected(i)
                        ? 'white'
                        : getActiveColor(category.name),
                    borderColor: getActiveColor(category.name)
                }"
            >
                {{ category.name }}
            </span>
        </div>
    </div>
</div>
