import {
    ContentItem,
    ContentType,
    SideContainerData,
    ToggleMode,
} from './models/side-container-data';

const emptySideContainerData = {
    contentItems: [],
    contentType: ContentType.NON_INPUT,
} as SideContainerData;

const visualNavigation: SideContainerData = {
    contentItems: [
        {
            value: 'precision',
            translationKey: 'evaluation.SL.diagrams.precision.nav',
            checked: true,
        } as ContentItem,
        {
            value: 'recall',
            translationKey: 'evaluation.SL.diagrams.recall.nav',
            checked: false,
        } as ContentItem,
        {
            value: 'loss',
            translationKey: 'evaluation.SL.diagrams.loss.nav',
            checked: false,
        } as ContentItem,
        {
            value: 'precisionRecallCurve',
            translationKey: 'evaluation.SL.diagrams.precisionRecallCurve.nav',
            checked: false,
        } as ContentItem,
    ],
    contentType: ContentType.RADIO,
};

const visualLegendLoss: SideContainerData = {
    // value must be: column name (in log file)+ $ + (train|val)
    // otherwise plotting won't work (mapping from metric to logged data)
    contentItems: [
        {
            value: 'loss$val',
            translationKey: 'evaluation.SL.diagrams.loss.totalLoss.val',
            checked: true,
            tagForm: ['square'],
        } as ContentItem,
        {
            value: 'loss$train',
            translationKey: 'evaluation.SL.diagrams.loss.totalLoss.train',
            checked: true,
            tagForm: ['line'],
        } as ContentItem,
        {
            value: 'class_loss$val',
            translationKey:
                'evaluation.SL.diagrams.loss.classificationLoss.val',
            checked: true,
            tagForm: ['square'],
        } as ContentItem,
        {
            value: 'class_loss$train',
            translationKey:
                'evaluation.SL.diagrams.loss.classificationLoss.train',
            checked: true,
            tagForm: ['line'],
        } as ContentItem,
        {
            value: 'box_loss$val',
            translationKey: 'evaluation.SL.diagrams.loss.localizationLoss.val',
            checked: true,
            tagForm: ['square'],
        } as ContentItem,
        {
            value: 'box_loss$train',
            translationKey:
                'evaluation.SL.diagrams.loss.localizationLoss.train',
            checked: true,
            tagForm: ['line'],
        } as ContentItem,
    ],
    contentType: ContentType.CHECKBOX,
};

function createContentItems(values: string[], metric: string): ContentItem[] {
    let contentItems: ContentItem[] = [];
    values.forEach((value) => {
        let prefix: string[] = value.split('$');
        contentItems.push({
            value: value,
            tagForm: [prefix[1] === 'val' ? 'square' : 'line'],
            checked: true,
            translationKey: `evaluation.SL.diagrams.${metric}.${prefix[0]}.${prefix[1]}`,
        });
    });
    return contentItems;
}

const visualLegendPrecision: SideContainerData = {
    // value must be: column name (in log file)+ $ + (train|val)
    // otherwise plotting won't work (mapping from metric to logged data)
    contentItems: createContentItems(
        [
            'AP$val',
            'AP&IOU=0.5&confidence=0.5&category=mean&area_range=all&max_detections=100$val',
            'AP&IOU=0.75&confidence=0.5&category=mean&area_range=all&max_detections=100$val',
            'AP&IOU=0.95&confidence=0.5&category=mean&area_range=all&max_detections=100$val',
        ],
        'precision'
    ),
    contentType: ContentType.CHECKBOX,
};

const visualLegendRecall: SideContainerData = {
    // value must be: column name (in log file)+ $ + (train|val)
    // otherwise plotting won't work (mapping from metric to logged data)
    contentItems: createContentItems(
        [
            'ARmax100$val',
            'AR&IOU=0.5&confidence=0.5&category=mean&area_range=all&max_detections=100$val',
            'AR&IOU=0.75&confidence=0.5&category=mean&area_range=all&max_detections=100$val',
            'AR&IOU=0.95&confidence=0.5&category=mean&area_range=all&max_detections=100$val',
        ],
        'recall'
    ),
    contentType: ContentType.CHECKBOX,
};

const precisionRecallCurveLegend: SideContainerData = {
    // value must be: column name (in log file)+ $ + (train|val)
    // otherwise plotting won't work (mapping from metric to logged data)
    contentItems: createContentItems(
        [
            'AP&IOU=0.5&recall=[0:1.0:0.01]&category=mean&area_range=all&max_detections=100$val',
            'AP&IOU=0.75&recall=[0:1.0:0.01]&category=mean&area_range=all&max_detections=100$val',
            'AP&IOU=0.95&recall=[0:1.0:0.01]&category=mean&area_range=all&max_detections=100$val',
        ],
        'precisionRecallCurve'
    ),
    contentType: ContentType.CHECKBOX,
};

const visualLabelMarkers: SideContainerData = {
    contentItems: [
        {
            value: 'preTrain',
            translationKey: 'evaluation.SL.media.markers.preTrain.label',
            tagForm: ['square', 'border-square'],
            infoText: 'evaluation.SL.media.markers.preTrain.info',
        },
        {
            value: 'postTrain',
            translationKey: 'evaluation.SL.media.markers.postTrain.label',
            tagForm: ['square'],
            infoText: 'evaluation.SL.media.markers.postTrain.info',
        },
    ],
    contentType: ContentType.NON_INPUT,
};

const slDataObj: SideContainerStates = {
    visualNavigation: visualNavigation,
    precision: visualLegendPrecision,
    recall: visualLegendRecall,
    loss: visualLegendLoss,
    categories: {} as SideContainerData,
    labelMarkers: visualLabelMarkers,
    precisionRecallCurve: precisionRecallCurveLegend,
};

const viewingOptions: SideContainerData = {
    contentItems: [
        {
            value: 'modelUpdates',
            translationKey: 'evaluation.RL.diagrams.allModelUpdates',
            checked: true,
        } as ContentItem,
        {
            value: 'episodes',
            translationKey: 'evaluation.RL.diagrams.allEpisodes',
            checked: false,
        } as ContentItem,
        // TODO: comment out when reward per iteration functionality is there
        // {
        //     value: 'singleIteration',
        //     translationKey: 'evaluation.RL.diagrams.singleIteration',
        //     checked: false,
        // } as ContentItem,
    ],
    contentType: ContentType.RADIO,
};
const motoricEpochLegend: SideContainerData = {
    // value must be: column name (in log file)+ $ + (train|val)
    // otherwise plotting won't work (mapping from metric to logged data)
    contentItems: [
        {
            value: 'sum$train',
            translationKey: 'evaluation.RL.diagrams.sumEpisodes',
            checked: true,
            tagForm: ['line'],
        } as ContentItem,
    ],
    contentType: ContentType.CHECKBOX,
};

const motoricModelUpdateLegend: SideContainerData = {
    // value must be: column name (in log file)+ $ + (train|val)
    // otherwise plotting won't work (mapping from metric to logged data)
    contentItems: [
        {
            value: 'sum$train',
            translationKey: 'evaluation.RL.diagrams.sumModelUpdates',
            checked: true,
            tagForm: ['line'],
        } as ContentItem,
    ],
    contentType: ContentType.CHECKBOX,
};

export const rlDataObj: SideContainerStates = {
    '': emptySideContainerData,
    viewingOptions: viewingOptions,
    modelUpdates: motoricModelUpdateLegend,
    episodes: motoricEpochLegend,
};

const viewingOptionsBc: SideContainerData = {
    contentItems: [
        {
            value: 'bcReturnMean',
            translationKey: 'evaluation.RL.diagrams.bcReturnMeanLabel',
            checked: true,
        } as ContentItem,
        {
            value: 'bcLoss',
            translationKey: 'evaluation.RL.diagrams.bcLossLabel',
            checked: false,
        } as ContentItem,
    ],
    contentType: ContentType.RADIO,
};

const motoricReturnMeanLegend: SideContainerData = {
    // value must be: column name (in log file)+ $ + (train|val)
    // otherwise plotting won't work (mapping from metric to logged data)
    contentItems: [
        {
            value: 'return_mean$val',
            translationKey: 'evaluation.RL.diagrams.returnMeanRightLabel',
            checked: true,
            tagForm: ['line'],
        } as ContentItem,
    ],
    contentType: ContentType.CHECKBOX,
};

const motoricLossLegend: SideContainerData = {
    // value must be: column name (in log file)+ $ + (train|val)
    // otherwise plotting won't work (mapping from metric to logged data)
    contentItems: [
        {
            value: 'loss$train',
            translationKey: 'evaluation.RL.diagrams.lossRightLabel',
            checked: true,
            tagForm: ['line'],
        } as ContentItem,
    ],
    contentType: ContentType.CHECKBOX,
};

export const bcDataObj: SideContainerStates = {
    '': emptySideContainerData,
    viewingOptionsBc: viewingOptionsBc,
    bcReturnMean: motoricReturnMeanLegend,
    bcLoss: motoricLossLegend,
};

const adversarialTrainerviewingOptions: SideContainerData = {
    contentItems: [
        {
            value: 'adversarialReward',
            translationKey: 'evaluation.RL.diagrams.reward',
            checked: true,
        } as ContentItem,
        {
            value: 'adversarialLoss',
            translationKey: 'evaluation.RL.diagrams.bcLossLabel',
            checked: false,
        } as ContentItem,
        {
            value: 'adversarialAccuracy',
            translationKey: 'evaluation.RL.diagrams.accuracy',
            checked: false,
        } as ContentItem,
    ],
    contentType: ContentType.RADIO,
};

const adversarialTrainerSacLossLegend: SideContainerData = {
    // value must be: column name (in log file)+ $ + (train|val)
    // otherwise plotting won't work (mapping from metric to logged data)
    contentItems: [
        {
            value: 'disc_loss_mean$train',
            translationKey: 'evaluation.RL.diagrams.discLossMean',
            checked: true,
            tagForm: ['line'],
        } as ContentItem,
        {
            value: 'learner_actor_loss$train',
            translationKey: 'evaluation.RL.diagrams.learnerActorLossMean',
            checked: true,
            tagForm: ['line'],
        } as ContentItem,
        {
            value: 'learner_critic_loss$train',
            translationKey: 'evaluation.RL.diagrams.learnerCriticLoss',
            checked: true,
            tagForm: ['line'],
        } as ContentItem,
    ],
    contentType: ContentType.CHECKBOX,
};

const adversarialTrainerPpoLossLegend: SideContainerData = {
    // value must be: column name (in log file)+ $ + (train|val)
    // otherwise plotting won't work (mapping from metric to logged data)
    contentItems: [
        {
            value: 'disc_loss_mean$train',
            translationKey: 'evaluation.RL.diagrams.discLossMean',
            checked: true,
            tagForm: ['line'],
        } as ContentItem,
        {
            value: 'learner_mean_loss$train',
            translationKey: 'evaluation.RL.diagrams.learnerMeanLoss',
            checked: true,
            tagForm: ['line'],
        } as ContentItem,
        {
            value: 'learner_mean_value_loss$train',
            translationKey: 'evaluation.RL.diagrams.learnerMeanValueLoss',
            checked: true,
            tagForm: ['line'],
        } as ContentItem,
    ],
    contentType: ContentType.CHECKBOX,
};

const adversarialTrainerRewardLegend: SideContainerData = {
    // value must be: column name (in log file)+ $ + (train|val)
    // otherwise plotting won't work (mapping from metric to logged data)
    contentItems: [
        {
            value: 'episode_reward_mean$train',
            translationKey: 'evaluation.RL.diagrams.episodeRewardMean',
            checked: true,
            tagForm: ['line'],
        } as ContentItem,
        {
            value: 'episode_reward_mean_wrapped$train',
            translationKey: 'evaluation.RL.diagrams.episodeRewardMeanWrapped',
            checked: true,
            tagForm: ['line'],
        } as ContentItem,
    ],
    contentType: ContentType.CHECKBOX,
};

const adversarialTrainerAccuracyLegend: SideContainerData = {
    // value must be: column name (in log file)+ $ + (train|val)
    // otherwise plotting won't work (mapping from metric to logged data)
    contentItems: [
        {
            value: 'disc_acc_mean$train',
            translationKey: 'evaluation.RL.diagrams.discAccMean',
            checked: true,
            tagForm: ['line'],
        } as ContentItem,
        {
            value: 'disc_acc_expert_mean$train',
            translationKey: 'evaluation.RL.diagrams.discAccExpertMean',
            checked: true,
            tagForm: ['line'],
        } as ContentItem,
        {
            value: 'disc_acc_gen_mean$train',
            translationKey: 'evaluation.RL.diagrams.discAccGenMean',
            checked: true,
            tagForm: ['line'],
        } as ContentItem,
    ],
    contentType: ContentType.CHECKBOX,
};

export const adversarialTrainerSacDataObj: SideContainerStates = {
    '': emptySideContainerData,
    viewingOptions: adversarialTrainerviewingOptions,
    adversarialReward: adversarialTrainerRewardLegend,
    adversarialAccuracy: adversarialTrainerAccuracyLegend,
    adversarialLoss: adversarialTrainerSacLossLegend,
};

export const adversarialTrainerPpoDataObj: SideContainerStates = {
    '': emptySideContainerData,
    viewingOptions: adversarialTrainerviewingOptions,
    adversarialReward: adversarialTrainerRewardLegend,
    adversarialAccuracy: adversarialTrainerAccuracyLegend,
    adversarialLoss: adversarialTrainerPpoLossLegend,
};

export const sideContainerDataMapping: { [key: string]: EvaluationState } = {
    SL: {
        media: {
            left: 'categories',
            right: 'labelMarkers',
            imageIndex: 0,
        },
        diagrams: {
            left: 'visualNavigation',
            right: 'precision',
        },
        data: slDataObj,
    },
    RL: {
        media: {
            left: '',
            right: '',
        },
        diagrams: {
            left: 'viewingOptions',
            right: 'modelUpdates',
        },
        data: rlDataObj,
    },
    BC: {
        media: {
            left: '',
            right: '',
        },
        diagrams: {
            left: 'viewingOptionsBc',
            right: 'bcReturnMean',
        },
        data: bcDataObj,
    },
    LEARNER_TYPE_SAC: {
        media: {
            left: '',
            right: '',
        },
        diagrams: {
            left: 'viewingOptions',
            right: 'adversarialReward',
        },
        data: adversarialTrainerSacDataObj,
    },
    LEARNER_TYPE_PPO: {
        media: {
            left: '',
            right: '',
        },
        diagrams: {
            left: 'viewingOptions',
            right: 'adversarialReward',
        },
        data: adversarialTrainerPpoDataObj,
    },
};

export interface EvaluationState {
    diagrams: { left: string; right: string };
    media: { left: string; right: string; imageIndex?: number };
    data: SideContainerStates;
}

export interface SideContainerStates {
    [key: string]: SideContainerData;
}

export interface SavedEvaluationState {
    toggleMode: ToggleMode;
    diagrams: { left: string; right: string };
    media: { left: string; right: string; imageIndex?: number };
    data: SavedSideContainerStates;
}

export interface SavedSideContainerStates {
    [key: string]: boolean[];
}
