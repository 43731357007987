import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { httpByPageable, Pageable } from 'src/app/shared/models/pageable';
import { BrowserAndPhysicsLoadable } from '../models/browser-and-physics-loadable';
import { Object3DPage } from '../models/object3-d-page';

@Injectable({
    providedIn: 'root',
})
export class Object3DService {
    private readonly backendUrl: string;

    constructor(private http: HttpClient) {
        this.backendUrl = environment.backendUrl + '/rest';
    }

    getObject3D(id: number): Observable<BrowserAndPhysicsLoadable> {
        const url = `${this.backendUrl}/object3d/${id}`;
        return this.http.get<BrowserAndPhysicsLoadable>(url);
    }

    getObject3DPage(
        userid: string,
        pageable: Pageable
    ): Observable<Object3DPage> {
        const url = `${this.backendUrl}/objects3d/` + userid;
        const httpParams = httpByPageable(new HttpParams(), pageable);
        return this.http.get<Object3DPage>(url, { params: httpParams });
    }

    getObjects3DByUser(
        userId: string
    ): Observable<BrowserAndPhysicsLoadable[]> {
        const url = `${this.backendUrl}/objects3d/all/` + userId;
        return this.http.get<BrowserAndPhysicsLoadable[]>(url);
    }

    updateobject3D(id: number, formData): Observable<Object3DPage> {
        const url = `${this.backendUrl}/object3d/${id}`;
        return this.http.patch<Object3DPage>(url, formData);
    }

    deleteObject3D(object3dId: string): Observable<Object3DPage> {
        const url = `${this.backendUrl}/object3d/${object3dId}`;
        return this.http.delete<Object3DPage>(url);
    }

    createObject3D(object3d: Object3DPage): Observable<Object3DPage> {
        const url = `${this.backendUrl}/object3d/`;
        return this.http.post<Object3DPage>(url, Object3DPage);
    }

    createFileObject3D(
        file: File,
        object3d: BrowserAndPhysicsLoadable
    ): Observable<HttpEvent<any>> {
        const url = `${this.backendUrl}/object3d/`;
        const formData: FormData = new FormData();
        formData.append('file', file);
        formData.append('name', object3d.name);
        return this.http.post(url, formData, {
            reportProgress: true,
            observe: 'events',
        });
    }
}
