<div class="basic-param-wrapper">
    <div class="basic-param-title-wrapper">
        <p class="basic-param-title mb-0">
            {{ 'algorithm.basicFormTitle' | translate }}
        </p>
    </div>
    <div class="row m-0 basic-param-content">
        <div class="col-4 col-xl-3 px-0 bg-color">
            <div class="col-content">
                <app-algorithm-input
                    inputLabel="{{ 'algorithm.nEpochs.label' | translate }}"
                    type="text"
                    [required]="true"
                    [hasSteppers]="true"
                    [control]="
                        algorithmForm.get(Scope.TRAINING_SETUP).get('nEpochs')
                    "
                    (valueChange)="onValueChange()"
                    [isFloatingPoint]="false"
                ></app-algorithm-input>
                <div class="spacer40"></div>
                <app-algorithm-input
                    inputLabel="{{
                        'algorithm.learningRate.label' | translate
                    }}"
                    [required]="true"
                    type="text"
                    [control]="
                        algorithmForm.get(Scope.OPTIMIZER).get('learningRate')
                    "
                    [hasSteppers]="true"
                    [isFloatingPoint]="true"
                    (valueChange)="onValueChange()"
                ></app-algorithm-input>
                <div class="spacer40"></div>
            </div>
        </div>

        <div
            class="col-4 col-xl-3 px-0 bg-color"
            *ngIf="advancedSettingEnabled"
        >
            <div class="col-content">
                <app-algorithm-input
                    inputLabel="{{
                        'algorithm.batchSizeAdam.label' | translate
                    }}"
                    type="text"
                    [required]="true"
                    [control]="
                        algorithmForm.get(Scope.GENERAL).get('batchSize')
                    "
                    [hasSteppers]="true"
                    [isFloatingPoint]="false"
                    (valueChange)="onValueChange()"
                ></app-algorithm-input>
                <div class="spacer40"></div>
                <app-algorithm-input
                    inputLabel="{{ 'algorithm.beta_1.label' | translate }}"
                    type="text"
                    [required]="true"
                    [hasSteppers]="true"
                    [control]="algorithmForm.get(Scope.OPTIMIZER).get('beta_1')"
                    (valueChange)="onValueChange()"
                    [isFloatingPoint]="true"
                    [decimalPlaces]="4"
                ></app-algorithm-input>
                <div class="spacer40"></div>
            </div>
        </div>

        <div
            class="col-4 col-xl-3 px-0 bg-color"
            *ngIf="advancedSettingEnabled"
        >
            <div class="col-content">
                <app-algorithm-input
                    inputLabel="{{ 'algorithm.beta_2.label' | translate }}"
                    type="text"
                    [required]="true"
                    [hasSteppers]="true"
                    [control]="algorithmForm.get(Scope.OPTIMIZER).get('beta_2')"
                    (valueChange)="onValueChange()"
                    [isFloatingPoint]="true"
                    [decimalPlaces]="4"
                ></app-algorithm-input>
                <div class="spacer40"></div>
                <app-algorithm-input
                    inputLabel="{{ 'algorithm.epsilon.label' | translate }}"
                    type="text"
                    [required]="true"
                    [hasSteppers]="true"
                    [control]="
                        algorithmForm.get(Scope.OPTIMIZER).get('epsilon')
                    "
                    (valueChange)="onValueChange()"
                    [isFloatingPoint]="false"
                    [fixedPrefix]="'1e'"
                ></app-algorithm-input>
            </div>
        </div>
        <div
            class="col-4 col-xl-3 px-0 bg-color"
            *ngIf="advancedSettingEnabled"
        >
            <div class="col-content">
                <div class="spacer30"></div>
                <div class="checkbox-group d-flex align-items-center">
                    <label class="tryb-checkbox">
                        <span class="input-label">{{
                            'algorithm.amsgrad.label' | translate
                        }}</span>
                        <input
                            type="checkbox"
                            [formControl]="
                                algorithmForm
                                    .get(Scope.OPTIMIZER)
                                    .get('amsgrad')
                            "
                            [checked]="
                                algorithmForm
                                    .get(Scope.OPTIMIZER)
                                    .get('amsgrad').value
                            "
                            (change)="onValueChange()"
                        />
                        <span class="checkmark">
                            <span
                                class="material-icons task_alt"
                                *ngIf="
                                    algorithmForm
                                        .get(Scope.OPTIMIZER)
                                        .get('amsgrad').value
                                "
                                >task_alt</span
                            >
                        </span>
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>
