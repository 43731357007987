import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export type ConfirmationDialogData = {
    headerTranslationKey: string;
    confirmationQuestion: {
        translationKey: string;
        interpolateParameters: { [key: string]: string };
    };
    confirmButtonTranslationKey: string;
    rejectButtonTranslationKey: string;
    transferData: any;
};

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
})
export class ConfirmationDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<
            ConfirmationDialogComponent,
            ConfirmationDialogData
        >,
        @Inject(MAT_DIALOG_DATA)
        public injectedData: ConfirmationDialogData
    ) {
        //adds css class for styling in global styles
        this.dialogRef.addPanelClass('tryb-confirmation-dialog');
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onSubmit() {
        this.dialogRef.close(this.injectedData.transferData);
    }
}
