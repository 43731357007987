<div class="basic-param-wrapper">
    <div class="basic-param-title-wrapper">
        <p class="basic-param-title mb-0">
            {{ 'algorithm.basicFormTitle' | translate }}
        </p>
    </div>
    <div class="row m-0 basic-param-content">
        <div class="col-4 col-xl-3 px-0 bg-color">
            <div class="col-content">
                <app-algorithm-input
                    inputLabel="{{ 'algorithm.batchSizeBC.label' | translate }}"
                    type="text"
                    [required]="true"
                    [hasSteppers]="true"
                    [control]="
                        algorithmForm.get(Scope.GENERAL).get('batchSize')
                    "
                    (valueChange)="onValueChange()"
                    [isFloatingPoint]="false"
                ></app-algorithm-input>
                <div class="spacer40"></div>
                <app-algorithm-input
                    inputLabel="{{ 'algorithm.batchSize.label' | translate }}"
                    type="text"
                    [required]="false"
                    [hasSteppers]="true"
                    [control]="
                        algorithmForm
                            .get(Scope.SPECIFIC)
                            .get('demoMinibatchSize')
                    "
                    (valueChange)="onValueChange()"
                    [isFloatingPoint]="false"
                ></app-algorithm-input>
                <div class="spacer40"></div>
                <div class="tryb-input-container">
                    <label for="input-algorithm-select" class="input-label">
                        {{ 'algorithm.inputAlgorithm.label' | translate }}
                    </label>

                    <div
                        class="tryb-dropdown"
                        [ngClass]="{
                            disabled: algorithmForm
                                .get(Scope.SPECIFIC)
                                .get('inputAlgorithm').disabled
                        }"
                    >
                        <select
                            id="input-algorithm-select"
                            class="mr-1"
                            [formControl]="
                                algorithmForm
                                    .get(Scope.SPECIFIC)
                                    .get('inputAlgorithm')
                            "
                            (change)="onValueChange(undefined, undefined, true)"
                            (keyup.enter)="blurInput($event)"
                            (keyup.escape)="blurInput($event)"
                        >
                            <option
                                *ngFor="
                                    let type of [
                                        AlgorithmType.SAC,
                                        AlgorithmType.PPO
                                    ]
                                "
                                [value]="type"
                            >
                                {{ type }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4 col-xl-3 px-0 bg-color">
            <div class="col-content">
                <app-algorithm-input
                    inputLabel="{{
                        'algorithm.genTrainTimesteps.label' | translate
                    }}"
                    type="text"
                    [required]="true"
                    [control]="
                        algorithmForm
                            .get(Scope.TRAINING_SETUP)
                            .get('genTrainTimesteps')
                    "
                    infoText="{{
                        'algorithm.genTrainTimesteps.description' | translate
                    }}"
                    [hasSteppers]="true"
                    [isFloatingPoint]="false"
                    (valueChange)="onValueChange()"
                ></app-algorithm-input>
                <div class="spacer40"></div>
                <app-algorithm-input
                    inputLabel="{{
                        'algorithm.totalTimesteps.label' | translate
                    }}"
                    type="text"
                    [required]="true"
                    [hasSteppers]="true"
                    [control]="
                        algorithmForm
                            .get(Scope.TRAINING_SETUP)
                            .get('totalTimesteps')
                    "
                    (valueChange)="onValueChange()"
                    [isFloatingPoint]="false"
                ></app-algorithm-input>
                <div class="spacer60"></div>
                <div
                    class="checkbox-group d-flex align-items-center"
                    *ngIf="advancedSettingEnabled"
                >
                    <label class="tryb-checkbox">
                        <span class="input-label"
                            >{{
                                'algorithm.debugUseGroundTruth.label'
                                    | translate
                            }}*</span
                        >
                        <input
                            type="checkbox"
                            id="debugUseGroundTruthCheckbox"
                            [formControl]="
                                algorithmForm
                                    .get(Scope.SPECIFIC)
                                    .get('debugUseGroundTruth')
                            "
                            [checked]="
                                algorithmForm
                                    .get(Scope.SPECIFIC)
                                    .get('debugUseGroundTruth').value
                            "
                            (change)="onValueChange()"
                        />
                        <span class="checkmark">
                            <span
                                class="material-icons task_alt"
                                *ngIf="
                                    algorithmForm
                                        .get(Scope.SPECIFIC)
                                        .get('debugUseGroundTruth').value
                                "
                                >task_alt</span
                            >
                        </span>
                    </label>
                </div>
            </div>
        </div>

        <div class="col-4 col-xl-3 px-0 bg-color">
            <div class="col-content">
                <app-algorithm-input
                    inputLabel="{{
                        'algorithm.learningRate.label' | translate
                    }}"
                    type="text"
                    [required]="true"
                    [hasSteppers]="true"
                    [control]="
                        algorithmForm.get(Scope.OPTIMIZER).get('learningRate')
                    "
                    (valueChange)="onValueChange()"
                    [isFloatingPoint]="true"
                ></app-algorithm-input>

                <div class="spacer40"></div>
                <app-algorithm-input
                    inputLabel="{{
                        'algorithm.nDiscUpdatesPerRound.label' | translate
                    }}"
                    type="text"
                    [required]="true"
                    [hasSteppers]="true"
                    [control]="
                        algorithmForm
                            .get(Scope.SPECIFIC)
                            .get('nDiscUpdatesPerRound')
                    "
                    (valueChange)="onValueChange()"
                    [isFloatingPoint]="false"
                ></app-algorithm-input>
                <div class="spacer40"></div>
                <app-algorithm-input
                    *ngIf="advancedSettingEnabled"
                    inputLabel="{{
                        'algorithm.genReplayBufferCapacity.label' | translate
                    }}"
                    type="text"
                    [required]="true"
                    [hasSteppers]="true"
                    [control]="
                        algorithmForm
                            .get(Scope.SPECIFIC)
                            .get('genReplayBufferCapacity')
                    "
                    (valueChange)="onValueChange()"
                    [isFloatingPoint]="false"
                ></app-algorithm-input>
            </div>
        </div>
        <div class="col-4 col-xl-3 px-0 bg-color">
            <div class="col-content">
                <app-algorithm-input
                    inputLabel="{{ 'algorithm.seed.label' | translate }}"
                    type="text"
                    [required]="false"
                    [hasSteppers]="true"
                    [control]="algorithmForm.get(Scope.GENERAL).get('seed')"
                    (valueChange)="onValueChange()"
                    [isFloatingPoint]="false"
                ></app-algorithm-input>
                <div class="spacer60"></div>
                <div
                    class="checkbox-group d-flex align-items-center"
                    *ngIf="advancedSettingEnabled"
                >
                    <label class="tryb-checkbox">
                        <span class="input-label"
                            >{{
                                'algorithm.allowVariableHorizon.label'
                                    | translate
                            }}*</span
                        >
                        <input
                            type="checkbox"
                            id="allowVariableHorizonCheckbox"
                            [formControl]="
                                algorithmForm
                                    .get(Scope.SPECIFIC)
                                    .get('allowVariableHorizon')
                            "
                            [checked]="
                                algorithmForm
                                    .get(Scope.SPECIFIC)
                                    .get('allowVariableHorizon').value
                            "
                            (change)="onValueChange()"
                        />
                        <span class="checkmark">
                            <span
                                class="material-icons task_alt"
                                *ngIf="
                                    algorithmForm
                                        .get(Scope.SPECIFIC)
                                        .get('allowVariableHorizon').value
                                "
                                >task_alt</span
                            >
                        </span>
                    </label>
                </div>
                <div class="spacer40"></div>

                <app-algorithm-input
                    inputLabel="{{
                        'algorithm.maxEpisodeLength.label' | translate
                    }}"
                    type="text"
                    [required]="true"
                    [hasSteppers]="true"
                    [control]="
                        algorithmForm
                            .get(Scope.ENVIRONMENT)
                            .get('maxEpisodeLength')
                    "
                    infoText="{{
                        'algorithm.maxEpisodeLength.description' | translate
                    }}"
                    (valueChange)="onValueChange()"
                    [isFloatingPoint]="false"
                ></app-algorithm-input>
            </div>
            <div class="spacer40"></div>
        </div>
    </div>
    <div
        *ngIf="
            algorithmForm.get(Scope.SPECIFIC).get('inputAlgorithm').value ===
            AlgorithmType.SAC
        "
    >
        <div class="row m-0 basic-param-content">
            <div class="col-4 col-xl-3 px-0 bg-color">
                <div class="col-content">
                    <p class="basic-param-title mt-0">
                        {{ 'SAC' }}
                    </p>

                    <div class="spacer60"></div>

                    <app-algorithm-input
                        inputLabel="{{
                            'algorithm.learningRate.label' | translate
                        }}"
                        type="text"
                        [required]="true"
                        [control]="
                            algorithmForm
                                .get(Scope.LEARNER_OPTIMIZER)
                                .get('learningRate')
                        "
                        (valueChange)="onValueChange()"
                        [decimalPlaces]="6"
                    ></app-algorithm-input>

                    <div class="spacer20"></div>

                    <app-algorithm-input
                        inputLabel="{{
                            'algorithm.bufferSize.label' | translate
                        }}"
                        type="text"
                        [required]="true"
                        [control]="
                            algorithmForm
                                .get(Scope.LEARNER_SPECIFIC)
                                .get('bufferSize')
                        "
                        (valueChange)="onValueChange()"
                        [isFloatingPoint]="false"
                    ></app-algorithm-input>

                    <div class="spacer20"></div>

                    <app-algorithm-input
                        inputLabel="{{
                            'algorithm.learningStarts.label' | translate
                        }}"
                        type="text"
                        [required]="true"
                        [control]="
                            algorithmForm
                                .get(Scope.LEARNER_SPECIFIC)
                                .get('learningStarts')
                        "
                        (valueChange)="onValueChange()"
                        [isFloatingPoint]="false"
                    ></app-algorithm-input>
                </div>
            </div>

            <div class="col-4 col-xl-3 px-0 bg-color">
                <div class="col-content">
                    <app-algorithm-input
                        inputLabel="{{ 'algorithm.tau.label' | translate }}"
                        type="text"
                        [required]="true"
                        [control]="
                            algorithmForm.get(Scope.LEARNER_SPECIFIC).get('tau')
                        "
                        (valueChange)="onValueChange()"
                        [decimalPlaces]="4"
                    ></app-algorithm-input>

                    <div class="spacer20"></div>

                    <app-algorithm-input
                        inputLabel="{{
                            'algorithm.gradientSteps.label' | translate
                        }}"
                        type="text"
                        [required]="true"
                        [control]="
                            algorithmForm
                                .get(Scope.LEARNER_SPECIFIC)
                                .get('gradientSteps')
                        "
                        (valueChange)="onValueChange()"
                        [isFloatingPoint]="false"
                    ></app-algorithm-input>

                    <div class="spacer20"></div>

                    <div class="tryb-input-container">
                        <label class="input-label">
                            {{ 'algorithm.actionNoiseType.label' | translate }}
                        </label>

                        <div
                            class="tryb-dropdown"
                            [ngClass]="{
                                disabled: algorithmForm
                                    .get(Scope.LEARNER_SPECIFIC)
                                    .get('actionNoiseType').disabled
                            }"
                        >
                            <select
                                id="actionNoiseTypeDropdown"
                                class="mr-1"
                                [formControl]="
                                    algorithmForm
                                        .get(Scope.LEARNER_SPECIFIC)
                                        .get('actionNoiseType')
                                "
                                (change)="onValueChange()"
                                (keyup.enter)="blurInput($event)"
                                (keyup.escape)="blurInput($event)"
                            >
                                <option
                                    *ngFor="let type of actionNoiseTypes"
                                    [value]="type"
                                >
                                    {{ type }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="spacer20"></div>

                    <div class="double-input">
                        <label class="input-label">
                            {{ 'algorithm.trainFreq.label' | translate }}*
                        </label>
                        <div class="input-row">
                            <div class="first-container">
                                <app-algorithm-input
                                    type="text"
                                    [required]="false"
                                    [control]="
                                        algorithmForm
                                            .get(Scope.LEARNER_TRAINING_SETUP)
                                            .get('trainFreqValue')
                                    "
                                    (valueChange)="onValueChange()"
                                    [isFloatingPoint]="false"
                                ></app-algorithm-input>
                            </div>
                            <div class="second-container">
                                <div class="tryb-input-container">
                                    <div
                                        class="tryb-dropdown"
                                        [ngClass]="{
                                            disabled: algorithmForm
                                                .get(
                                                    Scope.LEARNER_TRAINING_SETUP
                                                )
                                                .get('trainFreqUnit').disabled
                                        }"
                                    >
                                        <select
                                            class="mr-1"
                                            [formControl]="
                                                algorithmForm
                                                    .get(
                                                        Scope.LEARNER_TRAINING_SETUP
                                                    )
                                                    .get('trainFreqUnit')
                                            "
                                            (change)="onValueChange()"
                                            (keyup.enter)="blurInput($event)"
                                            (keyup.escape)="blurInput($event)"
                                        >
                                            <option
                                                *ngFor="
                                                    let type of frequencyTypes
                                                "
                                                [value]="type"
                                            >
                                                {{ type }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-4 col-xl-3 px-0 bg-color">
                <div class="col-content">
                    <app-algorithm-input
                        inputLabel="{{ 'algorithm.entCoef.label' | translate }}"
                        type="text"
                        [control]="
                            algorithmForm
                                .get(Scope.LEARNER_SPECIFIC)
                                .get('entCoef')
                        "
                        (valueChange)="onValueChange()"
                        [allowEmptyValue]="true"
                        [decimalPlaces]="4"
                    ></app-algorithm-input>

                    <div class="spacer20"></div>

                    <app-algorithm-input
                        inputLabel="{{
                            'algorithm.targetUpdateInterval.label' | translate
                        }}"
                        type="text"
                        [control]="
                            algorithmForm
                                .get(Scope.LEARNER_SPECIFIC)
                                .get('targetUpdateInterval')
                        "
                        (valueChange)="onValueChange()"
                        [isFloatingPoint]="false"
                    ></app-algorithm-input>

                    <div class="spacer20"></div>

                    <app-algorithm-input
                        inputLabel="{{
                            'algorithm.targetEntropy.label' | translate
                        }}"
                        type="text"
                        [control]="
                            algorithmForm
                                .get(Scope.LEARNER_SPECIFIC)
                                .get('targetEntropy')
                        "
                        (valueChange)="onValueChange()"
                        [allowEmptyValue]="true"
                        [decimalPlaces]="4"
                    ></app-algorithm-input>

                    <div class="spacer50"></div>

                    <div class="checkbox-group d-flex align-items-center">
                        <label class="tryb-checkbox">
                            <span class="input-label"
                                >{{
                                    'algorithm.optimizeMemoryUsage.label'
                                        | translate
                                }}*</span
                            >
                            <input
                                type="checkbox"
                                id="optimizeMemoryUsageCheckbox"
                                [formControl]="
                                    algorithmForm
                                        .get(Scope.LEARNER_SPECIFIC)
                                        .get('optimizeMemoryUsage')
                                "
                                [checked]="
                                    algorithmForm
                                        .get(Scope.LEARNER_SPECIFIC)
                                        .get('optimizeMemoryUsage').value
                                "
                                (change)="onValueChange()"
                            />
                            <span class="checkmark">
                                <span
                                    class="material-icons task_alt"
                                    *ngIf="
                                        algorithmForm
                                            .get(Scope.LEARNER_SPECIFIC)
                                            .get('optimizeMemoryUsage').value
                                    "
                                    >task_alt</span
                                >
                            </span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="col-4 col-xl-3 px-0 bg-color">
                <div class="col-content">
                    <app-algorithm-input
                        inputLabel="{{ 'algorithm.seed.label' | translate }}"
                        type="text"
                        [control]="
                            algorithmForm.get(Scope.LEARNER_GENERAL).get('seed')
                        "
                        (valueChange)="onValueChange()"
                        [isFloatingPoint]="false"
                        [allowEmptyValue]="true"
                    ></app-algorithm-input>
                    <div class="spacer20"></div>

                    <div class="tryb-input-container">
                        <label class="input-label">
                            {{
                                'algorithm.replayBufferClass.label' | translate
                            }}
                        </label>

                        <div
                            class="tryb-dropdown"
                            [ngClass]="{
                                disabled: algorithmForm
                                    .get(Scope.LEARNER_SPECIFIC)
                                    .get('replayBufferClass').disabled
                            }"
                        >
                            <select
                                class="mr-1"
                                id="replayBufferClassDropDown"
                                [formControl]="
                                    algorithmForm
                                        .get(Scope.LEARNER_SPECIFIC)
                                        .get('replayBufferClass')
                                "
                                (change)="onValueChange()"
                                (keyup.enter)="blurInput($event)"
                                (keyup.escape)="blurInput($event)"
                            >
                                <option
                                    *ngFor="let type of replayBufferClasses"
                                    [value]="type"
                                >
                                    {{ type }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="spacer20"></div>

                    <app-algorithm-input
                        inputLabel="{{
                            'algorithm.batchSize.label' | translate
                        }}"
                        type="text"
                        [required]="true"
                        [control]="
                            algorithmForm
                                .get(Scope.LEARNER_GENERAL)
                                .get('batchSize')
                        "
                        (valueChange)="onValueChange()"
                        [isFloatingPoint]="false"
                    ></app-algorithm-input>
                    <div class="spacer20"></div>
                    <app-algorithm-input
                        inputLabel="{{ 'algorithm.gamma.label' | translate }}"
                        type="text"
                        [required]="true"
                        [control]="
                            algorithmForm
                                .get(Scope.LEARNER_SPECIFIC)
                                .get('gamma')
                        "
                        (valueChange)="onValueChange()"
                        [decimalPlaces]="4"
                    ></app-algorithm-input>
                </div>
            </div>
        </div>
    </div>
    <div
        *ngIf="
            algorithmForm.get(Scope.SPECIFIC).get('inputAlgorithm').value ===
            AlgorithmType.PPO
        "
    >
        <div class="row m-0 basic-param-content">
            <div class="col-4 col-xl-3 px-0 bg-color">
                <div class="col-content">
                    <p class="basic-param-title mb-0">
                        {{ 'PPO' }}
                    </p>
                    <div class="spacer60"></div>
                    <app-algorithm-input
                        inputLabel="{{
                            'algorithm.learningRate.label' | translate
                        }}"
                        type="text"
                        [required]="true"
                        [hasSteppers]="true"
                        [control]="
                            algorithmForm
                                .get(Scope.LEARNER_OPTIMIZER)
                                .get('learningRate')
                        "
                        infoText="{{
                            'algorithm.learningRate.description' | translate
                        }}"
                        [isFloatingPoint]="true"
                        [decimalPlaces]="6"
                        (valueChange)="onValueChange()"
                    ></app-algorithm-input>
                    <div class="spacer20"></div>
                    <app-algorithm-input
                        inputLabel="{{ 'algorithm.nEpochs.label' | translate }}"
                        type="text"
                        [required]="true"
                        [control]="
                            algorithmForm
                                .get(Scope.LEARNER_TRAINING_SETUP)
                                .get('nEpochs')
                        "
                        infoText="{{
                            'algorithm.nEpochs.description' | translate
                        }}"
                        [hasSteppers]="true"
                        [isFloatingPoint]="false"
                        (valueChange)="onValueChange()"
                    ></app-algorithm-input>
                    <div class="spacer20"></div>
                    <app-algorithm-input
                        inputLabel="{{ 'algorithm.nSteps.label' | translate }}"
                        type="text"
                        [required]="true"
                        [control]="
                            algorithmForm
                                .get(Scope.LEARNER_TRAINING_SETUP)
                                .get('nSteps')
                        "
                        infoText="{{
                            'algorithm.nSteps.description' | translate
                        }}"
                        [hasSteppers]="true"
                        [isFloatingPoint]="false"
                        (valueChange)="onValueChange()"
                    ></app-algorithm-input>
                    <div class="spacer20"></div>
                    <app-algorithm-input
                        inputLabel="{{ 'algorithm.gamma.label' | translate }}"
                        type="text"
                        [required]="true"
                        [hasSteppers]="true"
                        [control]="
                            algorithmForm
                                .get(Scope.LEARNER_SPECIFIC)
                                .get('gamma')
                        "
                        infoText="{{
                            'algorithm.gamma.description' | translate
                        }}"
                        (valueChange)="onValueChange()"
                        [isFloatingPoint]="true"
                        [decimalPlaces]="4"
                    ></app-algorithm-input>
                </div>
            </div>

            <div
                class="col-4 col-xl-3 px-0 bg-color"
                *ngIf="advancedSettingEnabled"
            >
                <div class="col-content">
                    <div class="tryb-input-container">
                        <label class="input-label">
                            {{ 'algorithm.policy.label' | translate }}*
                        </label>

                        <div
                            class="tryb-dropdown"
                            [ngClass]="{
                                disabled: algorithmForm
                                    .get(Scope.LEARNER_SPECIFIC)
                                    .get('policy').disabled
                            }"
                        >
                            <select
                                class="mr-1"
                                [formControl]="
                                    algorithmForm
                                        .get(Scope.LEARNER_SPECIFIC)
                                        .get('policy')
                                "
                                (change)="onValueChange()"
                                (keyup.enter)="blurInput($event)"
                                (keyup.escape)="blurInput($event)"
                            >
                                <option
                                    *ngFor="let type of policyTypes"
                                    [value]="type"
                                >
                                    {{ type }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="spacer20"></div>

                    <app-algorithm-input
                        inputLabel="{{ 'algorithm.seed.label' | translate }}"
                        type="text"
                        [control]="
                            algorithmForm.get(Scope.LEARNER_GENERAL).get('seed')
                        "
                        infoText="{{
                            'algorithm.seed.description' | translate
                        }}"
                        [hasSteppers]="true"
                        [isFloatingPoint]="false"
                        (valueChange)="onValueChange()"
                        [allowEmptyValue]="true"
                    ></app-algorithm-input>

                    <div class="spacer20"></div>

                    <app-algorithm-input
                        inputLabel="{{
                            'algorithm.gaeLambda.label' | translate
                        }}"
                        type="text"
                        [required]="true"
                        [hasSteppers]="true"
                        [control]="
                            algorithmForm
                                .get(Scope.LEARNER_SPECIFIC)
                                .get('gaeLambda')
                        "
                        infoText="{{
                            'algorithm.gaeLambda.description' | translate
                        }}"
                        (valueChange)="onValueChange()"
                        [isFloatingPoint]="true"
                        [decimalPlaces]="4"
                    ></app-algorithm-input>

                    <div class="spacer20"></div>

                    <div class="checkbox-group d-flex align-items-center">
                        <label class="tryb-checkbox">
                            <span class="input-label">{{
                                'algorithm.useSde.label' | translate
                            }}</span>
                            <input
                                type="checkbox"
                                [formControl]="
                                    algorithmForm
                                        .get(Scope.LEARNER_SPECIFIC)
                                        .get('useSde')
                                "
                                [checked]="
                                    algorithmForm
                                        .get(Scope.LEARNER_SPECIFIC)
                                        .get('useSde').value
                                "
                                (change)="onValueChange()"
                            />
                            <span class="checkmark">
                                <span
                                    class="material-icons task_alt"
                                    *ngIf="
                                        algorithmForm
                                            .get(Scope.LEARNER_SPECIFIC)
                                            .get('useSde').value
                                    "
                                    >task_alt</span
                                >
                            </span>
                        </label>
                    </div>
                    <div class="spacer20"></div>

                    <app-algorithm-input
                        inputLabel="{{
                            'algorithm.sdeSampleFreq.label' | translate
                        }}"
                        type="text"
                        [required]="true"
                        [hasSteppers]="true"
                        [control]="
                            algorithmForm
                                .get(Scope.LEARNER_SPECIFIC)
                                .get('sdeSampleFreq')
                        "
                        infoText="{{
                            'algorithm.sdeSampleFreq.description' | translate
                        }}"
                        (valueChange)="onValueChange()"
                        [isFloatingPoint]="false"
                    ></app-algorithm-input>
                </div>
            </div>

            <div
                class="col-4 col-xl-3 px-0 bg-color"
                *ngIf="advancedSettingEnabled"
            >
                <div class="col-content">
                    <app-algorithm-input
                        inputLabel="{{ 'algorithm.entCoef.label' | translate }}"
                        type="text"
                        [required]="true"
                        [hasSteppers]="true"
                        [control]="
                            algorithmForm
                                .get(Scope.LEARNER_SPECIFIC)
                                .get('entCoef')
                        "
                        infoText="{{
                            'algorithm.entCoef.description' | translate
                        }}"
                        (valueChange)="onValueChange()"
                        [isFloatingPoint]="true"
                        [decimalPlaces]="4"
                    ></app-algorithm-input>

                    <div class="spacer20"></div>

                    <app-algorithm-input
                        inputLabel="{{ 'algorithm.vfCoef.label' | translate }}"
                        type="text"
                        [required]="true"
                        [hasSteppers]="true"
                        [control]="
                            algorithmForm
                                .get(Scope.LEARNER_SPECIFIC)
                                .get('vfCoef')
                        "
                        infoText="{{
                            'algorithm.vfCoef.description' | translate
                        }}"
                        (valueChange)="onValueChange()"
                        [isFloatingPoint]="true"
                        [decimalPlaces]="4"
                    ></app-algorithm-input>

                    <div class="spacer20"></div>

                    <app-algorithm-input
                        inputLabel="{{
                            'algorithm.maxGradNorm.label' | translate
                        }}"
                        type="text"
                        [required]="true"
                        [hasSteppers]="true"
                        [control]="
                            algorithmForm
                                .get(Scope.LEARNER_SPECIFIC)
                                .get('maxGradNorm')
                        "
                        infoText="{{
                            'algorithm.maxGradNorm.description' | translate
                        }}"
                        (valueChange)="onValueChange()"
                        [isFloatingPoint]="true"
                        [decimalPlaces]="4"
                    ></app-algorithm-input>

                    <div class="spacer20"></div>

                    <app-algorithm-input
                        inputLabel="{{
                            'algorithm.clipRange.label' | translate
                        }}"
                        type="text"
                        [required]="true"
                        [hasSteppers]="true"
                        [control]="
                            algorithmForm
                                .get(Scope.LEARNER_SPECIFIC)
                                .get('clipRange')
                        "
                        infoText="{{
                            'algorithm.clipRange.description' | translate
                        }}"
                        (valueChange)="onValueChange()"
                        [isFloatingPoint]="true"
                        [decimalPlaces]="4"
                    ></app-algorithm-input>
                </div>
            </div>
            <div
                class="col-4 col-xl-3 px-0 bg-color"
                *ngIf="advancedSettingEnabled"
            >
                <div class="col-content">
                    <app-algorithm-input
                        inputLabel="{{
                            'algorithm.batchSize.label' | translate
                        }}"
                        type="text"
                        [required]="true"
                        [control]="
                            algorithmForm
                                .get(Scope.LEARNER_GENERAL)
                                .get('batchSize')
                        "
                        infoText="{{
                            'algorithm.batchSize.description' | translate
                        }}"
                        [hasSteppers]="true"
                        [isFloatingPoint]="false"
                        (valueChange)="onValueChange()"
                    ></app-algorithm-input>
                    <div class="spacer20"></div>
                    <app-algorithm-input
                        inputLabel="{{
                            'algorithm.targetKl.label' | translate
                        }}"
                        type="text"
                        [required]="false"
                        [hasSteppers]="true"
                        [control]="
                            algorithmForm
                                .get(Scope.LEARNER_SPECIFIC)
                                .get('targetKl')
                        "
                        infoText="{{
                            'algorithm.targetKl.description' | translate
                        }}"
                        (valueChange)="onValueChange()"
                        [isFloatingPoint]="true"
                        [decimalPlaces]="4"
                        [allowEmptyValue]="true"
                    ></app-algorithm-input>
                    <div class="spacer20"></div>

                    <app-algorithm-input
                        inputLabel="{{
                            'algorithm.clipRangeVf.label' | translate
                        }}"
                        type="text"
                        [required]="false"
                        [hasSteppers]="true"
                        [control]="
                            algorithmForm
                                .get(Scope.LEARNER_SPECIFIC)
                                .get('clipRangeVf')
                        "
                        infoText="{{
                            'algorithm.clipRangeVf.description' | translate
                        }}"
                        (valueChange)="onValueChange()"
                        [isFloatingPoint]="true"
                        [decimalPlaces]="4"
                        [allowEmptyValue]="true"
                    ></app-algorithm-input>
                    <div class="spacer50"></div>

                    <div class="checkbox-group d-flex align-items-center">
                        <label class="tryb-checkbox">
                            <span class="input-label">{{
                                'algorithm.normalizeAdvantage.label' | translate
                            }}</span>
                            <input
                                type="checkbox"
                                [formControl]="
                                    algorithmForm
                                        .get(Scope.LEARNER_SPECIFIC)
                                        .get('normalizeAdvantage')
                                "
                                [checked]="
                                    algorithmForm
                                        .get(Scope.LEARNER_SPECIFIC)
                                        .get('normalizeAdvantage').value
                                "
                                (change)="onValueChange()"
                            />
                            <span class="checkmark">
                                <span
                                    class="material-icons task_alt"
                                    *ngIf="
                                        algorithmForm
                                            .get(Scope.LEARNER_SPECIFIC)
                                            .get('normalizeAdvantage').value
                                    "
                                    >task_alt</span
                                >
                            </span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="spacer20"></div>
        </div>
    </div>
</div>
