<div class="component-wrapper">
    <div class="header">
        <p>{{ 'imgDb.classificatorTree.header' | translate }}</p>
    </div>
    <div class="tree-wrapper">
        <div
            class="tree"
            *ngIf="classificatorTree.size && isClassificatorTreeSet"
        >
            <ng-container
                *ngFor="
                    let keyValuePair of classificatorTree | keyvalue;
                    index as idx
                "
            >
                <div
                    class="node"
                    [ngClass]="{
                        'folded-out': classificatorTree.get(keyValuePair.key)
                            .open
                    }"
                >
                    <div
                        class="node-data"
                        (click)="toggleClassificatorDropdown(keyValuePair.key)"
                        [ngStyle]="{
                            'border-color':
                                classificatorTree.get(keyValuePair.key).open ||
                                nodeDataHoveredIndex === idx
                                    ? getColor(keyValuePair.key)
                                    : 'transparent'
                        }"
                        (mouseenter)="nodeDataHoveredIndex = idx"
                        (mouseleave)="nodeDataHoveredIndex = null"
                    >
                        <div class="icon">
                            <img
                                src="assets/svg/img-db/classificator-tree/arrow.svg"
                                alt="arrow-icon"
                            />
                        </div>
                        <div
                            class="color-dot"
                            [ngStyle]="{
                                backgroundColor: getColor(keyValuePair.key)
                            }"
                        ></div>
                        <p class="name">{{ keyValuePair.key }}</p>
                        <div class="children-count">
                            {{
                                classificatorTree.get(keyValuePair.key).items
                                    .length
                            }}
                        </div>
                        <button
                            class="delete"
                            (click)="deleteCategory($event, keyValuePair.key)"
                            (mouseenter)="
                                deleteCategoryButtonHoveredIndex = idx
                            "
                            (mouseleave)="
                                deleteCategoryButtonHoveredIndex = null
                            "
                        >
                            <img
                                [src]="
                                    'assets/svg/img-db/classificator-tree/delete' +
                                    (deleteCategoryButtonHoveredIndex === idx
                                        ? '-hover'
                                        : '') +
                                    '.svg'
                                "
                                alt="delete-icon"
                            />
                        </button>
                    </div>
                    <div class="children">
                        <ng-container
                            *ngFor="
                                let classificatorTreeItem of classificatorTree.get(
                                    keyValuePair.key
                                ).items;
                                index as childIdx
                            "
                        >
                            <div
                                class="node-data"
                                [ngClass]="{
                                    active: classificatorTreeItem.active
                                }"
                                (click)="
                                    selectClassificator(classificatorTreeItem)
                                "
                            >
                                <div
                                    class="color-dot"
                                    [ngStyle]="{
                                        backgroundColor: getColor(
                                            keyValuePair.key
                                        )
                                    }"
                                ></div>
                                <p class="child-name">{{ keyValuePair.key }}</p>
                                <button
                                    class="delete"
                                    (click)="
                                        deleteAnnotation(classificatorTreeItem)
                                    "
                                    (mouseenter)="
                                        deleteAnnotationButtonHoveredIndex.parent =
                                            idx;
                                        deleteAnnotationButtonHoveredIndex.child =
                                            childIdx
                                    "
                                    (mouseleave)="
                                        deleteAnnotationButtonHoveredIndex.parent =
                                            null;
                                        deleteAnnotationButtonHoveredIndex.child =
                                            null
                                    "
                                >
                                    <img
                                        [src]="
                                            'assets/svg/img-db/classificator-tree/delete' +
                                            (deleteAnnotationButtonHoveredIndex.parent ===
                                                idx &&
                                            deleteAnnotationButtonHoveredIndex.child ===
                                                childIdx
                                                ? '-hover'
                                                : '') +
                                            '.svg'
                                        "
                                        alt="delete-icon"
                                    />
                                </button>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
        <div
            class="empty-tree-wrapper"
            *ngIf="!classificatorTree.size && isClassificatorTreeSet"
        >
            <p>{{ 'imgDb.classificatorTree.emptyTreeMessage' | translate }}</p>
        </div>
        <div class="spinner-wrapper" *ngIf="!isClassificatorTreeSet">
            <div class="tryb-design-spinner"></div>
        </div>
    </div>
</div>
