import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CostFunctionRow } from '../models/cost-function-row';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CostFunctionService {
    readonly restUrl: string;

    isInvalid$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(private http: HttpClient) {
        this.restUrl = environment.backendUrl + '/rest';
    }

    getCostFunctionContent(sceneId: string, type: string) {
        const url = `${this.restUrl}/cost-function/${type}/${sceneId}`;
        return this.http.get(url);
    }

    addCostFunctionRow(
        rowData: CostFunctionRow,
        sceneId: string,
        type: string
    ) {
        const url = `${this.restUrl}/cost-function/${type}/${sceneId}`;
        return this.http.post(url, rowData);
    }

    updateCostFunctionRow(
        rowData: CostFunctionRow,
        sceneId: string,
        type: string,
        index: number
    ) {
        const url = `${this.restUrl}/cost-function/${type}/${sceneId}/${index}`;
        return this.http.put(url, rowData);
    }

    deleteCostFunctionRow(sceneId: string, type: string, index: number) {
        const url = `${this.restUrl}/cost-function/${type}/${sceneId}/${index}`;
        return this.http.delete(url);
    }
}
