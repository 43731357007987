<div class="dialog-wrapper">
    <div class="header">
        <img
            src="assets/svg/shared/components/confirmation-dialog/warning.svg"
            alt="warning-icon"
        />
        <p>{{ injectedData.headerTranslationKey | translate }}</p>
    </div>
    <div class="question-text">
        <p>
            {{
                injectedData.confirmationQuestion.translationKey
                    | translate
                        : injectedData.confirmationQuestion
                              .interpolateParameters
            }}
        </p>
    </div>
    <div class="action-buttons">
        <button (click)="onSubmit()" class="confirm-button">
            {{ injectedData.confirmButtonTranslationKey | translate }}
        </button>
        <button (click)="onCancel()" class="cancel-button">
            {{ injectedData.rejectButtonTranslationKey | translate }}
        </button>
    </div>
</div>
