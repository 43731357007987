import { Component, OnInit } from '@angular/core';
import { ContainerService } from '../../../shared/services/container/container.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
    Container,
    ContainerMembership,
    ContainerType,
} from '../../../shared/services/container/container.types';
import { ProjectSplitScreenService } from '../../services/project-split-screen.service';
import { forkJoin } from 'rxjs';
import { nonNil } from '../../../shared/utility';
import { ProjectService } from '../../../project/services/project.service';
import { TreeStateService } from '../../../split-screen/services/tree-state.service';
import { TreeActionsService } from '../../../split-screen/services/tree-actions.service';
import { TreeDrawingService } from '../../../split-screen/services/tree-drawing.service';
import { TreeNodeStructureService } from '../../../split-screen/services/tree-node-structure.service';
import { TreeNodeToDrawableService } from '../../../split-screen/services/tree-node-to-drawable.service';
import { HttpErrorResponse } from '@angular/common/http';

export enum SplitScreenState {
    DEFAULT = 'default',
    LEFT_CLOSED = 'leftClosed',
    RIGHT_CLOSED = 'rightClosed',
}

export enum ArrowDirection {
    LEFT = 'left',
    RIGHT = 'right',
}

export const RIGHT_ARROW_SRC: string =
    'assets/svg/project-split-screen/right-arrow.svg';
export const RIGHT_ARROW_HOVER_SRC: string =
    'assets/svg/project-split-screen/right-arrow-hover.svg';
export const LEFT_ARROW_SRC: string =
    'assets/svg/project-split-screen/left-arrow.svg';
export const LEFT_ARROW_HOVER_SRC: string =
    'assets/svg/project-split-screen/left-arrow-hover.svg';

@Component({
    selector: 'app-project-split-screen',
    templateUrl: './project-split-screen.component.html',
    styleUrls: ['./project-split-screen.component.scss'],
    providers: [
        TreeStateService,
        TreeActionsService,
        TreeDrawingService,
        TreeNodeStructureService,
        TreeNodeToDrawableService,
    ],
})
export class ProjectSplitScreenComponent implements OnInit {
    protected readonly ArrowDirection = ArrowDirection;
    protected readonly SplitScreenState = SplitScreenState;

    splitScreenState: SplitScreenState = SplitScreenState.DEFAULT;
    leftArrowSrc: string = LEFT_ARROW_SRC;
    rightArrowSrc: string = RIGHT_ARROW_SRC;
    isHovered: boolean = false;

    containerId: string;
    containerName: string;
    containerType: ContainerType;
    memberRole: string;

    initializationSuccess: boolean = false;

    constructor(
        private containerService: ContainerService,
        private projectService: ProjectService,
        private activatedRoute: ActivatedRoute,
        private projectSplitScreenService: ProjectSplitScreenService,
        private router: Router
    ) {}

    ngOnInit() {
        this.containerId =
            this.activatedRoute.snapshot.paramMap.get('projectId');

        this.projectSplitScreenService.splitScreenStateChange$.next(
            this.splitScreenState
        );

        if (!nonNil(this.containerId)) {
            this.redirectToCommunityTab();
            return;
        }

        forkJoin<[Container, ContainerMembership]>([
            this.containerService.getById(this.containerId),
            this.containerService.getMyMembership(this.containerId),
        ]).subscribe({
            next: ([container, containerMembership]: [
                Container,
                ContainerMembership
            ]) => {
                this.initializeComponent(container, containerMembership);
            },
            error: (error) => {
                if (
                    error instanceof HttpErrorResponse &&
                    error.status === 401
                ) {
                    throw error;
                } else {
                    this.redirectToCommunityTab();
                }
            },
        });
    }

    private initializeComponent(
        container: Container,
        membership: ContainerMembership
    ): void {
        if (!nonNil(container) || !nonNil(membership)) {
            this.redirectToCommunityTab();
            return;
        }

        this.containerName = container.name;
        this.containerType = container.containerType;
        this.memberRole = membership.roleName;

        //TODO: refactor projectMemberComponent and projectSettingsComponent to use container service and selectedContainer$
        this.projectService.selectedProject$.next(container);

        this.initializationSuccess = true;
    }

    private redirectToCommunityTab(): void {
        this.router.navigate(['/communityprojects']);
    }

    onHover(direction: ArrowDirection, hovering: boolean) {
        if (hovering) {
            this.isHovered = true;
            switch (direction) {
                case ArrowDirection.LEFT:
                    this.leftArrowSrc = LEFT_ARROW_HOVER_SRC;
                    break;
                case ArrowDirection.RIGHT:
                    this.rightArrowSrc = RIGHT_ARROW_HOVER_SRC;
                    break;
                default:
                    this.isHovered = false;
            }
        } else {
            this.isHovered = false;
            this.leftArrowSrc = LEFT_ARROW_SRC;
            this.rightArrowSrc = RIGHT_ARROW_SRC;
        }
    }

    onArrowClick(direction: ArrowDirection) {
        switch (direction) {
            case ArrowDirection.LEFT:
                if (this.splitScreenState === SplitScreenState.DEFAULT) {
                    this.splitScreenState = SplitScreenState.LEFT_CLOSED;
                } else if (
                    this.splitScreenState === SplitScreenState.RIGHT_CLOSED
                ) {
                    this.splitScreenState = SplitScreenState.DEFAULT;
                } else return;
                break;

            case ArrowDirection.RIGHT:
                if (this.splitScreenState === SplitScreenState.DEFAULT) {
                    this.splitScreenState = SplitScreenState.RIGHT_CLOSED;
                } else if (
                    this.splitScreenState === SplitScreenState.LEFT_CLOSED
                ) {
                    this.splitScreenState = SplitScreenState.DEFAULT;
                } else return;
                break;

            default:
                return;
        }

        setTimeout(() => {
            this.projectSplitScreenService.splitScreenStateChange$.next(
                this.splitScreenState
            );
        }, 10);
    }
}
