import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IdmOption } from '../profile/models/idm-option';

const redirectUrl: string = 'redirectUrl';

@Injectable({
    providedIn: 'root',
})
export class LoginRedirectionService {
    idmOption: IdmOption;

    constructor(private router: Router) {}

    setIdmOptions(idmOption: IdmOption) {
        this.idmOption = idmOption;
    }

    login(url: string): void {
        localStorage.setItem(redirectUrl, url);
        this.redirect(this.idmOption.loginUri);
    }

    signup(): void {
        localStorage.setItem(redirectUrl, this.router.url);
        this.redirect(this.idmOption.registrationUri);
    }

    redirect(url: string): void {
        window.location.assign(url);
    }

    getRedirectUrl(): string {
        return localStorage.getItem(redirectUrl);
    }

    removeRedirectUrl(): void {
        localStorage.removeItem(redirectUrl);
    }
}
