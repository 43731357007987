<div class="img-db-pagination-container">
    <div class="paging-wrapper">
        <app-input-paging-bar
            [tag]="type"
            [maxValue]="totalPages"
            [currentValue]="currentPage"
            (nextValue)="onNextValue($event)"
        ></app-input-paging-bar>
    </div>
    <div class="zoom-control-group">
        <div class="zoom-control" (click)="onZoomOut()" id="zoom-out">
            <p>-</p>
        </div>
        <img
            class="indicator"
            src="assets/svg/img-db/icon-zoom-indicator.svg"
            alt="indicator"
        />
        <div class="zoom-control" (click)="onZoomIn()" id="zoom-in">
            <p>+</p>
        </div>
    </div>
</div>
