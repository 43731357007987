<div id="sidebar-nav-wrapper">
    <div
        id="sidebar-nav"
        class="text-white d-flex flex-column vh-100"
        [class.expanded]="isExpanded"
    >
        <a
            routerLink="/home"
            class="
                home-link
                d-flex
                align-items-center
                justify-content-center
                mb-5
                mt-5
                text-white text-decoration-none
            "
        >
            <img
                src="assets/svg/sidebar-nav/tryb-logo.png"
                alt="tryb-logo"
                class="logo"
                *ngIf="isExpanded"
            />
            <img
                src="assets/svg/sidebar-nav/tryb-mini-logo.png"
                alt="tryb-mini-logo"
                class="mini-logo"
                *ngIf="!isExpanded"
            />
        </a>
        <div class="flex-fill">
            <ul class="nav nav-pills flex-column mb-auto">
                <li
                    class="nav-item"
                    [ngClass]="{ active: activeNavItem === 0 }"
                >
                    <a
                        id="workspace-link"
                        routerLink="/workspace"
                        class="nav-link"
                        [ngClass]="{ selected: activeNavItem === 0 }"
                    >
                        <img
                            src="assets/svg/sidebar-nav/workspace-icon.svg"
                            class="float-start"
                            alt="workspace-icon"
                        />
                        <span class="nav-item-text workspace-link">{{
                            'navigation.sidebarNav.workspace' | translate
                        }}</span>
                    </a>
                </li>
                <li
                    class="nav-item"
                    *ngIf="userRoles.includes('ROLE_USER_MOTORIC_TRAINING')"
                >
                    <a class="nav-link" id="motoric-project-link">
                        <img
                            src="assets/svg/sidebar-nav/motoric-icon.svg"
                            class="float-start"
                            alt="motoric-icon"
                        />
                        <span class="nav-item-text motoricp-link">{{
                            'navigation.sidebarNav.motoricProjects' | translate
                        }}</span>
                    </a>
                </li>
                <li
                    class="nav-item"
                    *ngIf="userRoles.includes('ROLE_USER_VISUAL_TRAINING')"
                >
                    <a class="nav-link" id="visual-project-link">
                        <img
                            src="assets/svg/sidebar-nav/visual-icon.svg"
                            class="float-start"
                            alt="visual-icon"
                        />
                        <span class="nav-item-text visualp-link">{{
                            'navigation.sidebarNav.visualProjects' | translate
                        }}</span>
                    </a>
                </li>
                <li
                    class="nav-item"
                    *ngIf="userRoles.includes('ROLE_USER_VISUAL_TRAINING')"
                >
                    <a class="nav-link" id="image-db-link">
                        <img
                            src="assets/svg/sidebar-nav/picdatabase-icon.svg"
                            class="float-start"
                            alt="picdatabase-icon"
                        />
                        <span class="nav-item-text imagedb-link">{{
                            'navigation.sidebarNav.imageDatabses' | translate
                        }}</span>
                    </a>
                </li>
                <li class="nav-item separation-line ignore-hover-link">
                    <div class="nav-link separation-line-link">
                        <img
                            class="dashed-line"
                            src="assets/svg/sidebar-nav/separating-line-expanded.svg"
                            alt="separating-line-expanded"
                        />
                    </div>
                </li>
                <li
                    class="nav-item"
                    [ngClass]="{ active: activeNavItem === 4 }"
                >
                    <a
                        id="gallery-link"
                        class="nav-link"
                        routerLink="/communityprojects"
                        [ngClass]="{ selected: activeNavItem === 4 }"
                    >
                        <img
                            src="assets/svg/sidebar-nav/gallery-icon.svg"
                            class="float-start"
                            alt="gallery-icon"
                        />
                        <span class="nav-item-text gallery-link">{{
                            'navigation.sidebarNav.gallery' | translate
                        }}</span>
                    </a>
                </li>
            </ul>
        </div>
        <div>
            <ul class="nav nav-pills flex-column mb-auto">
                <li
                    id="sidebar-nav-expand-btn"
                    class="nav-item ignore-hover-link"
                    (click)="onExpand()"
                >
                    <a class="nav-link">
                        <img
                            src="assets/svg/sidebar-nav/arrow-close.svg"
                            class="float-start collapse-button"
                            alt="arrow-close"
                        />
                        <span class="nav-item-text">{{
                            'navigation.sidebarNav.collapse' | translate
                        }}</span>
                    </a>
                </li>
            </ul>
        </div>
        <div
            class="user-section"
            [ngClass]="{ 'user-section-expanded': isUserSectionExpanded }"
        >
            <ul class="nav nav-pills flex-column mb-auto">
                <li class="nav-item user-section-header ignore-hover-link">
                    <a
                        id="user-section-expand-btn"
                        class="nav-link"
                        (click)="onUserSectionExpand()"
                    >
                        <img
                            id="profile-img"
                            [src]="
                                picture
                                    ? picture
                                    : 'assets/svg/sidebar-nav/profile-icon.svg'
                            "
                            alt="profile-picture"
                        />
                        <span class="nav-item-text username-text">{{
                            username
                        }}</span>
                        <img
                            src="assets/svg/sidebar-nav/arrow-up.svg"
                            class="float-start user-section-expand-arrow"
                            alt="arrow-up"
                        />
                    </a>
                    <div
                        class="user-section-options-wrapper"
                        [ngClass]="{
                            expanded: isUserSectionExpanded,
                            admin: isAdmin
                        }"
                    >
                        <ul class="nav nav-pills flex-column mb-auto">
                            <li
                                class="nav-item no-border-radius"
                                [ngClass]="{
                                    'user-section-active': activeNavItem === 5
                                }"
                            >
                                <a
                                    id="profile-link"
                                    routerLink="/profile"
                                    class="nav-link"
                                    [ngClass]="{
                                        selected: activeNavItem === 5
                                    }"
                                >
                                    <img
                                        src="assets/svg/sidebar-nav/profile-icon.png"
                                        alt="profile-icon"
                                    />
                                    <span class="nav-item-text">{{
                                        'navigation.sidebarNav.profile'
                                            | translate
                                    }}</span>
                                </a>
                            </li>
                            <li
                                class="nav-item no-border-radius"
                                *ngIf="isAdmin"
                                [ngClass]="{
                                    'user-section-active': activeNavItem === 6
                                }"
                            >
                                <a
                                    id="admin-space-link"
                                    routerLink="/admin"
                                    class="nav-link"
                                    [ngClass]="{
                                        selected: activeNavItem === 6
                                    }"
                                >
                                    <img
                                        src="assets/svg/sidebar-nav/admin-icon.png"
                                        alt="admin-icon"
                                    />
                                    <span class="nav-item-text">{{
                                        'navigation.sidebarNav.admin'
                                            | translate
                                    }}</span>
                                </a>
                            </li>
                            <li class="nav-item no-border-radius">
                                <div
                                    id="logout-btn"
                                    class="nav-link"
                                    (click)="onLogOut()"
                                >
                                    <img
                                        src="assets/svg/sidebar-nav/log-out-icon.png"
                                        alt="log-out-icon"
                                    />
                                    <span class="nav-item-text">{{
                                        'navigation.sidebarNav.logOut'
                                            | translate
                                    }}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div id="color-stripe" class="vh-100">
        <div class="workspace-color" [class.active]="activeNavItem === 0"></div>
        <div class="gallery-color" [class.active]="activeNavItem === 4"></div>
        <div class="profile-color" [class.active]="activeNavItem === 5"></div>
        <div class="admin-color" [class.active]="activeNavItem === 6"></div>
    </div>
</div>
