import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { ThemePalette } from '@angular/material/core';
import { UserProfile } from '../../../authentication/profile/models/user-profile';
import { UserSettings } from '../../models/user-settings';
import { UserService } from '../../../authentication/services/user.service';
import { AuthenticationService } from '../../../authentication/services/authentication.service';

@Component({
    selector: 'app-user-settings',
    templateUrl: './user-settings.component.html',
    styleUrls: ['./user-settings.component.scss'],
})
export class UserSettingsComponent implements OnInit {
    users = new Subject<UserProfile[]>();
    color: ThemePalette = 'primary';
    userSettingsArray: UserSettings[] = [];
    currentUserId: any;

    constructor(
        private userService: UserService,
        private authenticationService: AuthenticationService
    ) {}

    ngOnInit(): void {
        this.currentUserId = this.authenticationService.currentUserValue.id;
        this.userService.getUsers().subscribe({
            next: (users) => {
                users.sort((u1, u2) => (u1.id > u2.id ? 1 : -1));

                this.users.next(users);
                for (const user of users) {
                    this.userSettingsArray.push(this.createUserSettings(user));
                }
            },
        });
    }

    onChangeActive(
        user: UserProfile,
        $event: MatSlideToggleChange,
        index: number
    ) {
        this.userService
            .activateUser($event.checked, user.id)
            .subscribe(
                (u) =>
                    (this.userSettingsArray[index] = this.createUserSettings(u))
            );
    }

    onChangeAdmin(
        user: UserProfile,
        $event: MatSlideToggleChange,
        index: number
    ) {
        this.userService
            .updateUserRole($event.checked, user.id)
            .subscribe(
                (u) =>
                    (this.userSettingsArray[index] = this.createUserSettings(u))
            );
    }

    onChangeCanTrain(
        user: UserProfile,
        $event: MatSlideToggleChange,
        index: number
    ) {
        this.userService
            .updateUserCanTrain($event.checked, user.id)
            .subscribe(
                (u) =>
                    (this.userSettingsArray[index] = this.createUserSettings(u))
            );
    }

    private createUserSettings(user: UserProfile): UserSettings {
        return new UserSettings(
            user.id,
            user.active,
            false,
            user.role === 'ROLE_CONFIG_ADMIN',
            user.canTrain
        );
    }
}
