import {
    AfterViewChecked,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { PanZoomAPI, PanZoomConfig, PanZoomModel } from 'ngx-panzoom';
import { filter, Subject, Subscription, switchMap, tap } from 'rxjs';
import { ImgWithSelection } from 'src/app/img-db/model/img-with-selection';
import { ImgAnnotation } from '../model/img-annotation';
import { ImgCategory } from '../model/img-category';
import { ImgDbZoomService } from '../services/img-db-zoom.service';
import { AnnotationStateService } from '../services/annotation-state.service';
import { ColorService } from '../../shared/services/color.service';
import { ImagesStorageService } from '../services/images-storage.service';
import { take, takeUntil } from 'rxjs/operators';
import { PagingResult } from '../../shared/models/pageable';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { SplitScreenState } from '../../project-split-screen/components/project-split-screen/project-split-screen.component';

@Component({
    selector: 'app-img-labeling-viewer',
    templateUrl: './img-labeling-viewer.component.html',
    styleUrls: ['./img-labeling-viewer.component.scss'],
})
export class ImgLabelingViewerComponent
    implements OnInit, OnChanges, AfterViewChecked, OnDestroy
{
    @Output()
    zoomLevelChanged: EventEmitter<number> = new EventEmitter();

    @Input() selectedCategory: ImgCategory;
    @Input() categories: ImgCategory[];
    @Input() splitScreenState: SplitScreenState;
    isDraggingActive: boolean = false;

    selectedImage: ImgWithSelection;
    timer: any;
    imageLoaded: boolean = false;
    alreadyDestroyed: boolean = false;

    @ViewChild('labeling') labeling: ElementRef;
    @ViewChild('currentImage') currentImage: ElementRef;
    @ViewChild('panzoomParent') panzoomParent: ElementRef;

    @ViewChildren('img') queryList: QueryList<ElementRef>;

    selectedAnnotation?: ImgAnnotation = null;
    serializedImgRefs: Element[] = [];
    svgStatus: any;
    annotations: ImgAnnotation[];
    drawingEventListenerRef: any;

    currentZoomLevel: number = 0;

    // panZoom configuration object
    panZoomConfig: PanZoomConfig = new PanZoomConfig({
        zoomLevels: ImgDbZoomService.MAX_IMAGE_ZOOM, // default + 20 levels
        scalePerZoomLevel: 1.2, // 20% zoom per level
        neutralZoomLevel: 10,
        initialZoomLevel: ImgDbZoomService.DEFAULT_IMAGE_ZOOM,
        zoomOnMouseWheel: true,
        zoomOnDoubleClick: false,
        invertMouseWheel: true,
        freeMouseWheel: false, // make mouse wheel zoom in 20% steps as defined above
        panOnClickDrag: false,
        zoomToFitZoomLevelFactor: 0.98,
    });
    private panZoomAPI: PanZoomAPI;

    // check if panning was started and ended
    imagePanned: boolean = true;

    // used to set and clear delayed alignment after zoom
    zoomAlignmentTimeoutId: any;

    // used to set and clear saving Interval
    saveIntervalId: any;

    cursorView: string = 'default';

    private subscriptions: Subscription[] = [];
    private destroy$: Subject<void> = new Subject<void>();

    showImageContainer: boolean = false;

    isPanButtonActive: boolean = false;
    featureButtonActiveIdx: number | null = null;

    constructor(
        public annotationStateService: AnnotationStateService,
        private colorService: ColorService,
        private zoomService: ImgDbZoomService,
        private storage: ImagesStorageService,
        private authService: AuthenticationService
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            this.storage.imagesPage$
                .pipe(
                    filter(
                        (page: PagingResult<ImgWithSelection>) => page !== null
                    ),
                    take(1),
                    tap((_) => this.setSvg()),
                    switchMap(() => {
                        return this.panZoomConfig.api.pipe(
                            tap((api: PanZoomAPI) => {
                                this.panZoomAPI = api;
                            }),
                            takeUntil(this.destroy$)
                        );
                    }),
                    switchMap(() => {
                        return this.annotationStateService.$annotations.pipe(
                            tap((annotations: ImgAnnotation[]) => {
                                this.annotations = annotations;
                            }),
                            takeUntil(this.destroy$)
                        );
                    }),
                    switchMap(() => {
                        return this.panZoomConfig.modelChanged.pipe(
                            tap((model: PanZoomModel) => {
                                if (this.imageLoaded) {
                                    this.onModelChanged(model);
                                }
                            }),
                            takeUntil(this.destroy$)
                        );
                    }),
                    switchMap(() => {
                        return this.zoomService.zoomLevelLabelDialog.pipe(
                            tap((zoomLevel: number) => {
                                this.onZoomChanged(zoomLevel);
                            }),
                            takeUntil(this.destroy$)
                        );
                    })
                )
                .subscribe()
        );
        this.subscriptions.push(
            this.authService.logoutTriggered$.subscribe(() => {
                this.annotationStateService
                    .saveCommandsBeforeLogout()
                    .subscribe(() => {
                        this.authService.logoutPreparationsFinished$.next(true);
                    });
            })
        );
        this.subscriptions.push(
            this.storage.selectedImage$.subscribe((img: ImgWithSelection) => {
                if (img) {
                    clearInterval(this.saveIntervalId);
                    this.selectedImage = img;
                    this.showImageContainer = true;
                    this.saveIntervalId = setInterval(() => {
                        this.annotationStateService
                            .saveCommands(true, img.id)
                            .subscribe();
                    }, 10000);

                    this.onImagesChanged();
                }
            })
        );

        this.subscriptions.push(
            this.storage.pageChanged$.subscribe(
                () => (this.showImageContainer = false)
            )
        );
    }

    onImageLoad() {
        const imgElem = this.currentImage.nativeElement;
        // when load is complete
        const zoomRect: DOMRect =
            this.panzoomParent.nativeElement.getBoundingClientRect();
        const imgRect: DOMRect = imgElem.getBoundingClientRect();

        // scaling factor
        const scale = Math.log10(this.panZoomConfig.scalePerZoomLevel);
        // if already zoomed, factor for img width height values
        const zoomVal = Math.pow(
            this.panZoomConfig.scalePerZoomLevel,
            this.panZoomAPI.model.zoomLevel
        );
        // find zoomWidth
        const width =
            Math.log10(zoomRect.width / (imgRect.width / zoomVal)) / scale;
        // find zoomHeight
        const height =
            Math.log10(zoomRect.height / (imgRect.height / zoomVal)) / scale;

        // show image full, select minimum zoom
        let minZoom = Math.floor(Math.min(width, height));
        // cannot use ZoomToFit, since it will zoom image parts if image is larger than viewArea
        this.imageLoaded = true;
        this.onZoomChanged(minZoom);
        this.updateSvgStatus();
    }

    setSvg() {
        this.svgStatus = {
            w: '100%',
            h: '100%',
            viewBox: '0 0 0 0',
        };
    }

    ngOnDestroy(): void {
        // unsubscribe from the subscriptions
        this.annotationStateService
            .saveCommands(true, this.selectedImage?.id)
            .subscribe();
        this.alreadyDestroyed = true;
        this.destroy$.complete();
        this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
        this.annotationStateService.$annotations.next([]);
        clearInterval(this.saveIntervalId);
    }

    // this is called every time the image is dragged or zoomed (for each animation step)
    onModelChanged(model: PanZoomModel) {
        if (model.isPanning) {
            this.imagePanned = true;
            clearTimeout(this.zoomAlignmentTimeoutId); //abort alignment after zoom
        } else if (this.imagePanned) {
            this.imagePanned = false;
            this.keepImageInBounds();
        }
        // check zoom even if panning, otherwise zoomService is out of sync
        let modelZoom = Math.round(model.zoomLevel);
        if (modelZoom !== this.currentZoomLevel) {
            // go here if image is currently zooming
            clearTimeout(this.zoomAlignmentTimeoutId); //abort alignment after zoom

            this.currentZoomLevel = modelZoom;
            this.zoomService.setCurrentZoomLevel(this.currentZoomLevel);
            this.zoomLevelChanged.emit(this.currentZoomLevel);
            // align image after zoom animation is finished with delay
            this.zoomAlignmentTimeoutId = setTimeout(() => {
                this.keepImageInBounds();
            }, 50);
        }
    }

    private keepImageInBounds() {
        //Fix for Unstable Tests
        if (!this.alreadyDestroyed) {
            const model = this.panZoomAPI.model;
            const parentWidth = this.panzoomParent.nativeElement.offsetWidth;
            const parentHeight = this.panzoomParent.nativeElement.offsetHeight;
            const imgWidth =
                this.currentImage.nativeElement.getBoundingClientRect().width;
            const imgHeight =
                this.currentImage.nativeElement.getBoundingClientRect().height;

            // current zoomLevel. This will return 0,1,2,etc, NOT the actual scale like 1, 1.2, 1.44 and so on
            const zoomLevel = this.currentZoomLevel;

            // actual scale value
            const scaleValue = Math.pow(
                this.panZoomConfig.scalePerZoomLevel,
                zoomLevel
            );

            let newX = model.pan.x;
            let newY = model.pan.y;

            // if the width of the image is less than or equal to the frame width
            // we need to center the image horizontally
            if (imgWidth <= parentWidth) {
                newX = parentWidth / 2 - imgWidth / 2;
            } else {
                // if the image width is larger than the frame, we need to make sure that edges
                // of the image don't go inside the frame, in other words, the image should always
                // cover the entire width of the frame.
                // x > 0 means the left side of the image inside the frame
                if (model.pan.x > 1) {
                    newX = 0; // return the left side of the image on the left edge of the frame, , i.e. first valid value
                } else if (model.pan.x + imgWidth < parentWidth) {
                    // check the right side of them image, x + imgWidth < parentWidth means the right side of the image is inside the frame.
                    // return it to the position where right side of the image is on the right edge of the frame, i.e. first valid value.
                    // x + imgWidth = parentWidth, i.e. x = parentWidth - imgWidth
                    newX = parentWidth - imgWidth;
                }
            }
            // Do the exact same as above but for y coordinate and heights
            if (imgHeight <= parentHeight) {
                newY = parentHeight / 2 - imgHeight / 2;
            } else {
                if (model.pan.y > 1) {
                    newY = 0;
                } else if (model.pan.y + imgHeight < parentHeight) {
                    newY = parentHeight - imgHeight;
                }
            }

            // set the delta position of the image, i.e. the distance of x and y that the image needs to be panned by
            const dx = ((newX - model.pan.x) * -1) / scaleValue;
            const dy = ((newY - model.pan.y) * -1) / scaleValue;
            // We need to check if there are any differences and only call penDelta when necessary.
            // Calling penDelta all the time can disable zoom.
            // We're comparing the absolute values to 2 and not 0 in order to avoid jittering due to small margins like 0.1, 0.2, etc
            if (Math.abs(dx) > 2 || Math.abs(dy) > 2) {
                this.panZoomAPI.panDelta(
                    {
                        x: dx,
                        y: dy,
                    },
                    0.01
                );
            }
        }
    }

    private onZoomChanged(zoomLevel: number) {
        if (
            zoomLevel > this.panZoomConfig.zoomLevels ||
            zoomLevel < this.panZoomConfig.initialZoomLevel ||
            !this.selectedImage
        ) {
            return;
        }
        const point = {
            x: this.panzoomParent.nativeElement.offsetWidth / 2,
            y: this.panzoomParent.nativeElement.offsetHeight / 2,
        };
        this.panZoomAPI.changeZoomLevel(zoomLevel, point);
    }

    resetZoom() {
        this.zoomService.setCurrentZoomLevel(0);
        this.onZoomChanged(0);
        this.keepImageInBounds();
    }

    onPanButtonClick() {
        this.isPanButtonActive = !this.isPanButtonActive;
        this.toggleImageDragging(this.isPanButtonActive);
    }

    // Disables and enables dragging the image

    toggleImageDragging(enable: boolean) {
        if (this.panZoomAPI) {
            this.panZoomAPI.config.panOnClickDrag = enable;
            this.cursorView = enable ? 'grab' : 'default';
            this.isDraggingActive = enable;
            this.isPanButtonActive = enable;
        }
        this.enableDrawing(!enable);
    }

    updateSvgStatus() {
        this.svgStatus = {
            w: this.currentImage.nativeElement.clientWidth,
            h: this.currentImage.nativeElement.clientHeight,
            viewBox: `0 0 ${this.selectedImage.width} ${this.selectedImage.height}`,
        };
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes) {
            if (changes.selectedCategory && this.labeling) {
                this.enableDrawing(this.canEdit());
                this.toggleImageDragging(false);
            }
            if (
                changes.splitScreenState &&
                this.imageLoaded &&
                this.panzoomParent &&
                this.currentImage
            ) {
                this.onImageLoad();
                this.keepImageInBounds();
            }
        }
    }

    ngAfterViewChecked(): void {
        this.serializedImgRefs = this.queryList.map((ref) => ref.nativeElement);
        this.queryList.changes.subscribe((_) => {
            this.serializedImgRefs = this.queryList.map(
                (ref) => ref.nativeElement
            );
        });
    }

    getSvgViewbox() {
        if (!this.selectedImage) return;

        return `0 0 ${this.selectedImage.width} ${this.selectedImage.height}`;
    }

    enableDrawing(enable: boolean): void {
        const svg = this.labeling.nativeElement;

        if (enable && !this.drawingEventListenerRef && this.canEdit()) {
            this.drawingEventListenerRef = (evt) => {
                this.handleDrawing(evt);
            };
            svg.addEventListener('mousedown', this.drawingEventListenerRef);
        } else if (!enable) {
            svg.removeEventListener('mousedown', this.drawingEventListenerRef);
            this.drawingEventListenerRef = null;
        }
    }

    handleDrawing(event: MouseEvent) {
        const svg = this.labeling.nativeElement;
        const start = this.getSVGPoint(svg, event.clientX, event.clientY);
        const rect = this.createSVGElement('rect');
        const g = this.createSVGElement('g');
        svg.appendChild(g);
        g.appendChild(rect);

        const drawRect = (e: MouseEvent) => {
            const p = this.getSVGPoint(svg, e.clientX, e.clientY);
            const [x, y, w, h] = this.calculateRect(start, p);
            rect.setAttribute('x', x.toString());
            rect.setAttribute('y', y.toString());
            rect.setAttribute('width', w.toString());
            rect.setAttribute('height', h.toString());
            rect.setAttribute(
                'stroke',
                this.getColor(this.selectedCategory.name)
            );
        };

        const endDraw = (_) => {
            const rectWidth = parseFloat(rect.getAttribute('width'));
            const rectHeight: number = parseFloat(rect.getAttribute('height'));
            const rectX = parseFloat(rect.getAttribute('x'));
            const rectY = parseFloat(rect.getAttribute('y'));
            if (rectWidth > 0 && rectHeight > 0) {
                let annotation = new ImgAnnotation();
                annotation.imageId = this.selectedImage.id;
                annotation.categoryId = this.selectedCategory.id + '';
                annotation.bbox = new DOMRect(
                    rectX,
                    rectY,
                    rectWidth,
                    rectHeight
                );
                this.annotationStateService.addAnnotation(annotation);
                this.selectedAnnotation = null;
                g.remove();
            }

            svg.removeEventListener('mousemove', drawRect);
            svg.removeEventListener('mouseup', endDraw);
        };

        svg.addEventListener('mousemove', drawRect);
        svg.addEventListener('mouseup', endDraw);
    }

    selectAnnotation(annotation: ImgAnnotation) {
        if (!this.panZoomAPI.config.panOnClickDrag) {
            this.annotationStateService.selectAnnotation(annotation.id);
        }
    }

    getSVGPoint(svg: SVGSVGElement, x: number, y: number): SVGPoint {
        const p = svg.createSVGPoint();
        p.x = x;
        p.y = y;
        return p.matrixTransform(svg.getScreenCTM().inverse());
    }

    createSVGElement(tagName: string): SVGElement {
        return document.createElementNS('http://www.w3.org/2000/svg', tagName);
    }

    calculateRect(
        start: DOMPoint,
        end: DOMPoint
    ): [number, number, number, number] {
        const x = Math.min(start.x, end.x);
        const y = Math.min(start.y, end.y);
        const w = Math.abs(end.x - start.x);
        const h = Math.abs(end.y - start.y);
        return [x, y, w, h];
    }

    getImgCategoryName(id: string) {
        return this.findImgCategoryById(id)?.name;
    }

    private findImgCategoryById(id: string) {
        return this.categories.find((category: ImgCategory) => {
            return category.id === id;
        });
    }

    canEdit(): boolean {
        return (
            this.selectedCategory !== null &&
            this.selectedCategory !== undefined
        );
    }

    onImagesChanged(): void {
        if (!this.labeling || !this.labeling.nativeElement) {
            return;
        }
        const rects = this.labeling.nativeElement.querySelectorAll('g');
        if (!rects || !rects.length) return;
        rects.forEach((element) => {
            element.remove();
        });
    }

    getColor(categoryName: string) {
        return this.colorService.getColor(categoryName);
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler(event) {
        if (this.annotationStateService.changedAnnotationState()) {
            this.annotationStateService.saveCommands(false, null).subscribe();
            event.preventDefault();
            event.returnValue = false;
        }
        this.alreadyDestroyed = true;
    }
}
