import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SplitScreenModule } from '../split-screen/split-screen.module';
import { ProjectSplitScreenComponent } from './components/project-split-screen/project-split-screen.component';
import { ProjectSplitScreenHeaderComponent } from './components/project-split-screen-header/project-split-screen-header.component';
import { ProjectSplitScreenTabsComponent } from './components/project-split-screen-tabs/project-split-screen-tabs.component';
import { SharedModule } from '../shared/shared.module';
import { SceneModule } from '../scenes/scene.module';
import { CostFunctionModule } from '../cost-function/cost-function.module';
import { AlgorithmModule } from '../algorithm/algorithm.module';
import { SkillArchitectureEditorModule } from '../skill-architecture-editor/skill-architecture-editor.module';
import { EvaluationModule } from '../evaluation/evaluation.module';
import { ImgDbModule } from '../img-db/img-db.module';

@NgModule({
    declarations: [
        ProjectSplitScreenComponent,
        ProjectSplitScreenHeaderComponent,
        ProjectSplitScreenTabsComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        SplitScreenModule,
        SceneModule,
        CostFunctionModule,
        AlgorithmModule,
        SkillArchitectureEditorModule,
        EvaluationModule,
        ImgDbModule,
    ],
})
export class ProjectSplitScreenModule {}
