import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ResetPasswordService {
    private readonly databaseUrl: string;

    constructor(private http: HttpClient) {
        this.databaseUrl = environment.backendUrl;
    }
    forgetPassword(userEmail: string) {
        const url = `${this.databaseUrl}/auth/forget/password`;
        const formData = new FormData();
        formData.append('userEmail', userEmail);
        return this.http.post(url, formData);
    }

    resetPassword(token: string, newPassword: string): Observable<any> {
        const url = `${this.databaseUrl}/auth/reset/password`;
        const formData = new FormData();
        formData.append('token', token);
        formData.append('newPassword', newPassword);
        return this.http.post(url, formData);
    }
}
