<div
    class="d-flex justify-content-center align-items-center h-100"
    *ngIf="images.length === 0 && !showSpinner"
>
    <p>{{ 'imgDb.upload.dropFileMessage' | translate }}</p>
</div>
<div
    class="d-flex justify-content-center align-items-center h-100"
    *ngIf="showSpinner"
>
    <div class="tryb-design-spinner"></div>
</div>
<div class="img-db-gallery-content overflow-auto">
    <div class="images-container" *ngIf="images.length && !showSpinner">
        <div class="row">
            <div
                class="fotocard-container to-zoom"
                [ngStyle]="{ width: currentWidth + '%' }"
                *ngFor="let image of images"
            >
                <div
                    class="fotocard"
                    (click)="selectImage(image)"
                    [ngClass]="{ 'img-selected': image.isSelected }"
                >
                    <div
                        [ngStyle]="{
                            'background-image':
                                'url(' + getImageUrl(image) + ')'
                        }"
                        class="img-card"
                    >
                        <div class="selection-marker"></div>
                    </div>
                    <span>{{ image.name }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
