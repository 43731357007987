import {
    Component,
    EventEmitter,
    Output,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { ImgWithSelection } from '../model/img-with-selection';
import { filter, Subscription } from 'rxjs';
import { ImgDbService } from '../services/img-db.service';
import { ImgDbZoomService } from '../services/img-db-zoom.service';
import { SelectionButtonMode } from '../img-db.component';
import { ImagesStorageService } from '../services/images-storage.service';
import { PagingResult } from '../../shared/models/pageable';

@Component({
    selector: 'app-img-db-gallery',
    templateUrl: './img-db-gallery.component.html',
    styleUrls: ['./img-db-gallery.component.scss'],
})
export class ImgDbGalleryComponent implements OnInit, OnDestroy {
    @Output()
    selectButtonStatusChanged: EventEmitter<string> = new EventEmitter();

    images: ImgWithSelection[] = [];
    currentWidth: number;

    subscriptions: Subscription[] = [];

    showSpinner: boolean = true;

    constructor(
        private service: ImgDbService,
        private zoomService: ImgDbZoomService,
        private storage: ImagesStorageService
    ) {}

    ngOnInit(): void {
        this.subscriptions.push(
            this.service.selection.subscribe((sel) => {
                this.handleSelection(sel);
            })
        );
        this.subscriptions.push(
            this.zoomService.widthManageDialog.subscribe(
                (width) => (this.currentWidth = width)
            )
        );

        this.storage.pageChanged$.subscribe(() => {
            this.showSpinner = true;
        });

        this.subscriptions.push(
            this.storage.imagesPage$
                .pipe(
                    filter(
                        (page: PagingResult<ImgWithSelection>) => page !== null
                    )
                )
                .subscribe((page: PagingResult<ImgWithSelection>) => {
                    this.images = page.values;
                    this.showSpinner = false;
                })
        );
    }

    selectImage(image: ImgWithSelection) {
        image.isSelected = !image.isSelected;
        if (image.isSelected) {
            const selectedImages = this.images.filter(
                (img: ImgWithSelection) => img.isSelected
            );
            if (selectedImages.length === this.images.length) {
                this.selectButtonStatusChanged.emit(
                    SelectionButtonMode.Deselect
                );
            }
        } else {
            const selectedImages = this.images.filter(
                (img: ImgWithSelection) => img.isSelected
            );
            if (!selectedImages.length) {
                this.selectButtonStatusChanged.emit(SelectionButtonMode.Select);
            }
        }
    }

    handleSelection(isAllSelected: boolean) {
        this.images.forEach((imageSel) => {
            imageSel.isSelected = isAllSelected;
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    getImageUrl(img: ImgWithSelection): string {
        let imageUrl = img.thumbnailUrl;
        if (img.thumbnailUrl === null) {
            imageUrl = img.url;
        }

        return imageUrl;
    }
}
