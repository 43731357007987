import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { CardPage } from '../../../card/model/card-page';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
    createPageableByRouterParameters,
    Pageable,
    setDefaultWorkspaceSortingParameters,
} from '../../../shared/models/pageable';
import { take } from 'rxjs/operators';
import {
    Container,
    ContainerPage,
} from '../../../shared/services/container/container.types';
import { AuthenticationService } from '../../../authentication/services/authentication.service';
import { Observable, Subscription } from 'rxjs';
import { AddCard } from '../../../card/model/add-card-implementations/add-card';
import { Card } from 'src/app/card/model/card-implementations/card';

@Directive()
export abstract class BaseLibraryComponent implements OnInit, OnDestroy {
    cardPage: CardPage = new CardPage();
    @Input() profilePicture: string;
    private sub: Subscription;

    protected constructor(
        protected authenticationService: AuthenticationService,
        protected route: ActivatedRoute,
        protected router: Router
    ) {}

    ngOnInit(): void {
        let currentUser = this.authenticationService.isLoggedIn();
        if (currentUser) {
            this.sub = this.route.queryParams.subscribe((params: Params) => {
                this.cardPage.pageable = createPageableByRouterParameters(
                    new Pageable(),
                    params
                );

                setDefaultWorkspaceSortingParameters(this.cardPage);

                this.cardPage.addCard = this.createAddCard();

                this.getData()
                    .pipe(take(1))
                    .subscribe((containerPage: ContainerPage) => {
                        this.cardPage.numberOfPages = containerPage.totalPages;
                        this.populate(containerPage.values);
                    });
            });
        } else {
            console.error('There is an internal error.');
            this.authenticationService.signOut();
        }
    }

    populate(containers: Container[]): void {
        this.cardPage.itemCards = [];
        containers.forEach((container: Container) => {
            let card = this.convertToCard(container, this.profilePicture);
            this.cardPage.itemCards.push(card);
        });
    }

    ngOnDestroy(): void {
        this.sub?.unsubscribe();
    }

    abstract convertToCard(container: Container, picture: string): Card;

    abstract createAddCard(): AddCard;

    abstract getData(): Observable<ContainerPage>;
}
