<div class="basic-param-wrapper">
    <div class="basic-param-title-wrapper">
        <p class="basic-param-title mb-0">
            {{ 'algorithm.basicFormTitle' | translate }}
        </p>
    </div>
    <div class="row m-0 basic-param-content">
        <div class="col-4 col-xl-3 px-0 bg-color">
            <div class="col-content">
                <app-algorithm-input
                    inputLabel="{{ 'algorithm.gamma.label' | translate }}"
                    type="text"
                    [required]="true"
                    [hasSteppers]="true"
                    [control]="algorithmForm.get(Scope.SPECIFIC).get('gamma')"
                    infoText="{{ 'algorithm.gamma.description' | translate }}"
                    (valueChange)="onValueChange()"
                    [isFloatingPoint]="true"
                    [decimalPlaces]="4"
                ></app-algorithm-input>
                <div class="spacer20"></div>
                <app-algorithm-input
                    inputLabel="{{
                        'algorithm.learningRate.label' | translate
                    }}"
                    type="text"
                    [required]="true"
                    [hasSteppers]="true"
                    [control]="
                        algorithmForm.get(Scope.OPTIMIZER).get('learningRate')
                    "
                    infoText="{{
                        'algorithm.learningRate.description' | translate
                    }}"
                    [isFloatingPoint]="true"
                    [decimalPlaces]="6"
                    (valueChange)="onValueChange()"
                ></app-algorithm-input>
                <div class="spacer20"></div>
                <app-algorithm-input
                    inputLabel="{{ 'algorithm.nEpochs.label' | translate }}"
                    type="text"
                    [required]="true"
                    [control]="
                        algorithmForm.get(Scope.TRAINING_SETUP).get('nEpochs')
                    "
                    infoText="{{ 'algorithm.nEpochs.description' | translate }}"
                    [hasSteppers]="true"
                    [isFloatingPoint]="false"
                    (valueChange)="onValueChange()"
                ></app-algorithm-input>
                <div class="spacer20"></div>
                <app-algorithm-input
                    inputLabel="{{ 'algorithm.nSteps.label' | translate }}"
                    type="text"
                    [required]="true"
                    [control]="
                        algorithmForm.get(Scope.TRAINING_SETUP).get('nSteps')
                    "
                    infoText="{{ 'algorithm.nSteps.description' | translate }}"
                    [hasSteppers]="true"
                    [isFloatingPoint]="false"
                    (valueChange)="onValueChange()"
                ></app-algorithm-input>

                <div class="spacer20"></div>
                <app-algorithm-input
                    inputLabel="{{
                        'algorithm.totalTimesteps.label' | translate
                    }}"
                    type="text"
                    [required]="true"
                    [hasSteppers]="true"
                    [control]="
                        algorithmForm
                            .get(Scope.TRAINING_SETUP)
                            .get('totalTimesteps')
                    "
                    infoText="{{
                        'algorithm.totalTimesteps.description' | translate
                    }}"
                    (valueChange)="onValueChange()"
                    [isFloatingPoint]="false"
                ></app-algorithm-input>
            </div>
        </div>

        <div
            class="col-4 col-xl-3 px-0 bg-color"
            *ngIf="advancedSettingEnabled"
        >
            <div class="col-content">
                <div class="tryb-input-container">
                    <label class="input-label">
                        {{ 'algorithm.policy.label' | translate }}*
                    </label>

                    <div
                        class="tryb-dropdown"
                        [ngClass]="{
                            disabled: algorithmForm
                                .get(Scope.SPECIFIC)
                                .get('policy').disabled
                        }"
                    >
                        <select
                            class="mr-1"
                            [formControl]="
                                algorithmForm.get(Scope.SPECIFIC).get('policy')
                            "
                            (change)="onValueChange()"
                            (keyup.enter)="blurInput($event)"
                            (keyup.escape)="blurInput($event)"
                        >
                            <option
                                *ngFor="let type of policyTypes"
                                [value]="type"
                            >
                                {{ type }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="spacer20"></div>

                <app-algorithm-input
                    inputLabel="{{ 'algorithm.seed.label' | translate }}"
                    type="text"
                    [control]="algorithmForm.get(Scope.GENERAL).get('seed')"
                    infoText="{{ 'algorithm.seed.description' | translate }}"
                    [hasSteppers]="true"
                    [isFloatingPoint]="false"
                    (valueChange)="onValueChange()"
                    [allowEmptyValue]="true"
                ></app-algorithm-input>

                <div class="spacer20"></div>

                <app-algorithm-input
                    inputLabel="{{ 'algorithm.gaeLambda.label' | translate }}"
                    type="text"
                    [required]="true"
                    [hasSteppers]="true"
                    [control]="
                        algorithmForm.get(Scope.SPECIFIC).get('gaeLambda')
                    "
                    infoText="{{
                        'algorithm.gaeLambda.description' | translate
                    }}"
                    (valueChange)="onValueChange()"
                    [isFloatingPoint]="true"
                    [decimalPlaces]="4"
                ></app-algorithm-input>

                <div class="spacer20"></div>

                <app-algorithm-input
                    inputLabel="{{ 'algorithm.clipRange.label' | translate }}"
                    type="text"
                    [required]="true"
                    [hasSteppers]="true"
                    [control]="
                        algorithmForm.get(Scope.SPECIFIC).get('clipRange')
                    "
                    infoText="{{
                        'algorithm.clipRange.description' | translate
                    }}"
                    (valueChange)="onValueChange()"
                    [isFloatingPoint]="true"
                    [decimalPlaces]="4"
                ></app-algorithm-input>

                <div class="spacer20"></div>

                <app-algorithm-input
                    inputLabel="{{ 'algorithm.clipRangeVf.label' | translate }}"
                    type="text"
                    [required]="false"
                    [hasSteppers]="true"
                    [control]="
                        algorithmForm.get(Scope.SPECIFIC).get('clipRangeVf')
                    "
                    infoText="{{
                        'algorithm.clipRangeVf.description' | translate
                    }}"
                    (valueChange)="onValueChange()"
                    [isFloatingPoint]="true"
                    [decimalPlaces]="4"
                    [allowEmptyValue]="true"
                ></app-algorithm-input>
            </div>
        </div>

        <div
            class="col-4 col-xl-3 px-0 bg-color"
            *ngIf="advancedSettingEnabled"
        >
            <div class="col-content">
                <app-algorithm-input
                    inputLabel="{{ 'algorithm.entCoef.label' | translate }}"
                    type="text"
                    [required]="true"
                    [hasSteppers]="true"
                    [control]="algorithmForm.get(Scope.SPECIFIC).get('entCoef')"
                    infoText="{{ 'algorithm.entCoef.description' | translate }}"
                    (valueChange)="onValueChange()"
                    [isFloatingPoint]="true"
                    [decimalPlaces]="4"
                ></app-algorithm-input>

                <div class="spacer20"></div>

                <app-algorithm-input
                    inputLabel="{{ 'algorithm.vfCoef.label' | translate }}"
                    type="text"
                    [required]="true"
                    [hasSteppers]="true"
                    [control]="algorithmForm.get(Scope.SPECIFIC).get('vfCoef')"
                    infoText="{{ 'algorithm.vfCoef.description' | translate }}"
                    (valueChange)="onValueChange()"
                    [isFloatingPoint]="true"
                    [decimalPlaces]="4"
                ></app-algorithm-input>

                <div class="spacer20"></div>

                <app-algorithm-input
                    inputLabel="{{ 'algorithm.maxGradNorm.label' | translate }}"
                    type="text"
                    [required]="true"
                    [hasSteppers]="true"
                    [control]="
                        algorithmForm.get(Scope.SPECIFIC).get('maxGradNorm')
                    "
                    infoText="{{
                        'algorithm.maxGradNorm.description' | translate
                    }}"
                    (valueChange)="onValueChange()"
                    [isFloatingPoint]="true"
                    [decimalPlaces]="4"
                ></app-algorithm-input>

                <div class="spacer20"></div>

                <app-algorithm-input
                    inputLabel="{{ 'algorithm.batchSize.label' | translate }}"
                    type="text"
                    [required]="true"
                    [control]="
                        algorithmForm.get(Scope.GENERAL).get('batchSize')
                    "
                    infoText="{{
                        'algorithm.batchSize.description' | translate
                    }}"
                    [hasSteppers]="true"
                    [isFloatingPoint]="false"
                    (valueChange)="onValueChange()"
                ></app-algorithm-input>

                <div class="spacer50"></div>

                <div class="checkbox-group d-flex align-items-center">
                    <label class="tryb-checkbox">
                        <span class="input-label">{{
                            'algorithm.normalizeAdvantage.label' | translate
                        }}</span>
                        <input
                            type="checkbox"
                            [formControl]="
                                algorithmForm
                                    .get(Scope.SPECIFIC)
                                    .get('normalizeAdvantage')
                            "
                            [checked]="
                                algorithmForm
                                    .get(Scope.SPECIFIC)
                                    .get('normalizeAdvantage').value
                            "
                            (change)="onValueChange()"
                        />
                        <span class="checkmark">
                            <span
                                class="material-icons task_alt"
                                *ngIf="
                                    algorithmForm
                                        .get(Scope.SPECIFIC)
                                        .get('normalizeAdvantage').value
                                "
                                >task_alt</span
                            >
                        </span>
                    </label>
                </div>
            </div>
        </div>
        <div
            class="col-4 col-xl-3 px-0 bg-color"
            *ngIf="advancedSettingEnabled"
        >
            <div class="col-content">
                <app-algorithm-input
                    inputLabel="{{
                        'algorithm.sdeSampleFreq.label' | translate
                    }}"
                    type="text"
                    [required]="true"
                    [hasSteppers]="true"
                    [control]="
                        algorithmForm.get(Scope.SPECIFIC).get('sdeSampleFreq')
                    "
                    infoText="{{
                        'algorithm.sdeSampleFreq.description' | translate
                    }}"
                    (valueChange)="onValueChange()"
                    [isFloatingPoint]="false"
                ></app-algorithm-input>

                <div class="spacer20"></div>

                <app-algorithm-input
                    inputLabel="{{ 'algorithm.targetKl.label' | translate }}"
                    type="text"
                    [required]="false"
                    [hasSteppers]="true"
                    [control]="
                        algorithmForm.get(Scope.SPECIFIC).get('targetKl')
                    "
                    infoText="{{
                        'algorithm.targetKl.description' | translate
                    }}"
                    (valueChange)="onValueChange()"
                    [isFloatingPoint]="true"
                    [decimalPlaces]="4"
                    [allowEmptyValue]="true"
                ></app-algorithm-input>

                <div class="spacer50"></div>

                <div class="checkbox-group d-flex align-items-center">
                    <label class="tryb-checkbox">
                        <span class="input-label">{{
                            'algorithm.useSde.label' | translate
                        }}</span>
                        <input
                            type="checkbox"
                            [formControl]="
                                algorithmForm.get(Scope.SPECIFIC).get('useSde')
                            "
                            [checked]="
                                algorithmForm.get(Scope.SPECIFIC).get('useSde')
                                    .value
                            "
                            (change)="onValueChange()"
                        />
                        <span class="checkmark">
                            <span
                                class="material-icons task_alt"
                                *ngIf="
                                    algorithmForm
                                        .get(Scope.SPECIFIC)
                                        .get('useSde').value
                                "
                                >task_alt</span
                            >
                        </span>
                    </label>
                </div>

                <div class="spacer20"></div>

                <app-algorithm-input
                    inputLabel="{{
                        'algorithm.maxEpisodeLength.label' | translate
                    }}"
                    type="text"
                    [required]="true"
                    [hasSteppers]="true"
                    [control]="
                        algorithmForm
                            .get(Scope.ENVIRONMENT)
                            .get('maxEpisodeLength')
                    "
                    infoText="{{
                        'algorithm.maxEpisodeLength.description' | translate
                    }}"
                    (valueChange)="onValueChange()"
                    [isFloatingPoint]="false"
                ></app-algorithm-input>
            </div>
        </div>
    </div>
</div>
