import { EventEmitter, Injectable } from '@angular/core';
import {
    BehaviorSubject,
    filter,
    interval,
    mergeMap,
    Observable,
    Subscription,
    tap,
} from 'rxjs';
import { MessageService } from 'src/app/message/services/message.service';
import {
    emptyTrainingTreeNode,
    TrainingTreeNode,
} from '../models/training-tree-node';
import { RightClickMenuPosition } from '../models/right-click-menu-position';
import { switchMap } from 'rxjs/operators';
import { ProjectService } from 'src/app/project/services/project.service';
import { TrainingService } from 'src/app/shared/services/training.service';
import { TrainingDTO } from 'src/app/shared/models/training-dto';
import { TrainingStatus } from 'src/app/shared/models/training-status';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

@Injectable()
export class TreeStateService {
    private _selectedTreeNode = new BehaviorSubject<TrainingTreeNode>(
        emptyTrainingTreeNode
    );
    private _isTrainingNameValid = new BehaviorSubject<boolean>(false);
    private _treeState = new BehaviorSubject<TrainingTreeNode>(
        emptyTrainingTreeNode
    );
    private _selectedTrainingId = new BehaviorSubject<string>('');

    public isMenuOpen = false;
    public projectId = '';
    public onForceChangeSelected = new EventEmitter();

    public onTrainingCloned = new EventEmitter();

    private treeUpdateSubscription$: Subscription = new Subscription();

    private treeStateUpdateInterval: number = 10000;

    get selectedTreeNode(): TrainingTreeNode {
        return this._selectedTreeNode.value;
    }

    get selectedTreeNode$(): Observable<TrainingTreeNode> {
        return this._selectedTreeNode.asObservable();
    }

    set selectedTreeNode(newValue: TrainingTreeNode) {
        this._selectedTreeNode.next(newValue);
    }

    set treeState(newTree: TrainingTreeNode) {
        this._treeState.next(newTree);
    }

    get treeState(): TrainingTreeNode {
        return this._treeState.value;
    }

    get treeState$(): Observable<TrainingTreeNode> {
        return this._treeState.asObservable();
    }

    set selectedTrainingId(value: string) {
        this._selectedTrainingId.next(value);
    }

    get selectedTrainingId(): string {
        return this._selectedTrainingId.value;
    }

    get isTrainingNameValid(): boolean {
        return this._isTrainingNameValid.value;
    }

    set isTrainingNameValid(newValue: boolean) {
        this._isTrainingNameValid.next(newValue);
    }

    private treeHasRunningTraining: boolean = false;

    constructor(
        private trainingService: TrainingService,
        private messageService: MessageService,
        private projectService: ProjectService
    ) {}

    public startTreeUpdates() {
        this.treeUpdateSubscription$ = interval(this.treeStateUpdateInterval)
            .pipe(
                filter(() => this.treeHasRunningTraining),
                switchMap(() => this.refreshTreeState())
            )
            .subscribe();
    }

    public cloneTreeNode(nodeUuid: string, cloneSuffix: string): void {
        this.trainingService.clone(nodeUuid, cloneSuffix).subscribe({
            next: (clonedTraining: TrainingDTO) => {
                this.currentTree(this.projectId).subscribe(
                    (updatedRoot: TrainingTreeNode) => {
                        this.treeState = updatedRoot;
                        this.onForceChangeSelected.emit();
                        this.selectedTreeNode = TrainingTreeNode.findInTree(
                            updatedRoot,
                            clonedTraining.uuid
                        );
                        this.onTrainingCloned.emit();
                    }
                );
            },
        });
    }

    public deleteTreeNode(nodeUuid: string): void {
        const parentOfDeletion = TrainingTreeNode.findParentInTree(
            this.treeState,
            nodeUuid
        );
        this.trainingService.delete(nodeUuid).subscribe({
            next: () => {
                this.currentTree(this.projectId).subscribe(
                    (updatedRoot: TrainingTreeNode) => {
                        this.treeState = updatedRoot;
                        this.onForceChangeSelected.emit();
                        setTimeout(() => {
                            this.selectedTreeNode = parentOfDeletion;
                        }, 1100);
                    }
                );
            },
            error: (error) => {
                this.messageService.displayErrorMessage(
                    'Training kann nicht gelöscht werden.'
                );
                throw error;
            },
        });
    }

    downloadTrainingSkill(trainingId: string): void {
        this.trainingService.downloadSkill(trainingId).subscribe({
            next: (response: HttpResponse<Blob>) => {
                const contentDisposition = response.headers.get(
                    'content-disposition'
                );
                const downloadURL = window.URL.createObjectURL(response.body);
                const link = document.createElement('a');
                link.href = downloadURL;
                link.download =
                    this.getTrainingSkillFileName(contentDisposition);
                link.click();
                window.URL.revokeObjectURL(downloadURL);
            },
            error: (error: HttpErrorResponse) => {
                let messageKey: string =
                    'splitScreen.components.contextMenu.canNotDownloadSkill';
                if (error.status === 404) {
                    messageKey =
                        'splitScreen.components.contextMenu.canNotFindSkillFile';
                }
                this.messageService.displayTranslatedErrorMessage(
                    messageKey,
                    null
                );
            },
        });
    }

    private getTrainingSkillFileName(contentDisposition: string): string {
        return contentDisposition
            ? contentDisposition
                  .split(';')[1]
                  .trim()
                  .split('=')[1]
                  .replace(/\"/g, '')
            : 'file';
    }

    // TODO: fix initial loading
    public refreshTreeState(): Observable<TrainingTreeNode> {
        return this.currentTree(this.projectId).pipe(
            tap((tree: TrainingTreeNode) => {
                this.setTreeHasRunningTraining(this.hasRunningTraining(tree));
                this._treeState.next(tree);
            }),
            tap((tree: TrainingTreeNode) => {
                if (this.selectedTrainingId) {
                    this.selectedTreeNode = TrainingTreeNode.findInTree(
                        tree,
                        this.selectedTrainingId
                    );
                } else {
                    this.selectedTreeNode = tree;
                }
            })
        );
    }

    setTreeHasRunningTraining(treeHasRunningTraining: boolean) {
        this.treeHasRunningTraining = treeHasRunningTraining;
    }

    hasRunningTraining(tree: TrainingTreeNode) {
        const runningStatuses = [
            TrainingStatus.RUNNING,
            TrainingStatus.QUEUED,
            TrainingStatus.CANCELLING,
            TrainingStatus.PREPARING,
        ];
        if (runningStatuses.includes(tree.status)) {
            return true;
        }
        for (let child of tree.children) {
            if (this.hasRunningTraining(child)) {
                return true;
            }
        }
        return false;
    }

    public updateSelectedTreeNodeName(newName: string) {
        this.selectedTreeNode = TrainingTreeNode.updateNodeName(
            this.treeState,
            this.selectedTreeNode.uuid,
            newName
        );

        return this.notifyTrainingService(newName);
    }

    private notifyTrainingService(newName: string): Observable<TrainingDTO> {
        // TODO: replace with patch endpoint
        return this.trainingService.get(this.selectedTreeNode.uuid).pipe(
            mergeMap((training: TrainingDTO) => {
                training.name = newName;
                return this.trainingService.update(training);
            })
        );
    }

    public getTrainingOfSelectedNode(): Observable<TrainingDTO> {
        return this.trainingService.get(this.selectedTreeNode.uuid);
    }

    cleanup() {
        this.isMenuOpen = false;
        this.selectedTreeNode = emptyTrainingTreeNode;
        this.treeState = emptyTrainingTreeNode;
        this.treeUpdateSubscription$.unsubscribe();
    }

    currentTree(projectId: string) {
        return this.projectService.getTrainingTree(projectId);
    }
}
