import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { AuthenticationService } from '../../../../../authentication/services/authentication.service';
import { Object3DService } from '../../../../../object3-d/service/object3-d.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { SceneStateService } from '../../../../services/scene-state.service';
import { BrowserAndPhysicsLoadable } from 'src/app/object3-d/models/browser-and-physics-loadable';
import { UploadFileType } from 'src/app/object3-d/models/upload-file-type';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ScrollableService } from '../../../../services/scrollable.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-object-library',
    templateUrl: './object-library.component.html',
    styleUrls: [
        '../../../../styles/scrollable-container.scss',
        '../../../../styles/search-bar.scss',
        './object-library.component.scss',
    ],
})
export class ObjectLibraryComponent implements OnInit, AfterViewInit {
    @Input() editingIsDisabled: boolean;
    @Input() trainingId: string;

    filterTextValue: string;
    uploadType: UploadFileType = UploadFileType.OBJECT_IN_SCENE;
    loadableObjects: BrowserAndPhysicsLoadable[] = [];
    tmpLoadableObjects: BrowserAndPhysicsLoadable[] = [];

    private modal: NgbModal;
    @ViewChild('content') contentRef: TemplateRef<any>;

    @ViewChild('listContainer') listContainerRef: ElementRef;
    listHasScrollbar: boolean = false;

    private readonly THUMBNAIL_FILE_NAME = 'thumbnail.jpg';

    constructor(
        private object3DService: Object3DService,
        private authenticationService: AuthenticationService,
        private sceneStateService: SceneStateService,
        private translate: TranslateService,
        private changeDetector: ChangeDetectorRef,
        private scrollableService: ScrollableService,
        private route: ActivatedRoute,
        modal: NgbModal
    ) {
        this.modal = modal;
    }

    ngOnInit(): void {
        this.object3DService
            .getObjects3DByUser(this.authenticationService.currentUserValue.id)
            .subscribe((content: BrowserAndPhysicsLoadable[]) => {
                content.forEach((data) => {
                    this.loadableObjects.push(data);
                });
            });
        this.tmpLoadableObjects = this.loadableObjects;
    }

    ngAfterViewInit() {
        const observer = new ResizeObserver(() => {
            this.checkIfListHasScrollbar();
        });
        observer.observe(this.listContainerRef.nativeElement);
    }

    drop(event: CdkDragDrop<BrowserAndPhysicsLoadable[]>) {
        const objectAdded = this.tmpLoadableObjects[event.previousIndex];
        this.sceneStateService.addObject(objectAdded, this.trainingId);
    }

    filterText(): void {
        let enteredText: string = this.filterTextValue
            .toLocaleLowerCase()
            .trim();
        this.tmpLoadableObjects = this.loadableObjects.filter(
            (item) => item.name.toLocaleLowerCase().indexOf(enteredText) >= 0
        );
    }

    clearText(): void {
        this.filterTextValue = '';
        this.tmpLoadableObjects = this.loadableObjects;
    }

    onSearch(): void {
        if (this.filterTextValue.length === 0) {
            this.tmpLoadableObjects = this.loadableObjects;
        }
    }

    addObject(): void {
        this.modal.open(this.contentRef);
    }

    addNewObject(item: BrowserAndPhysicsLoadable) {
        this.loadableObjects.push(item);
        this.tmpLoadableObjects = this.loadableObjects;
        this.filterTextValue = '';
        this.checkIfListHasScrollbar();
    }

    checkIfListHasScrollbar() {
        window.requestAnimationFrame(() => {
            this.listHasScrollbar = this.scrollableService.checkIfHasScrollbar(
                this.listContainerRef
            );
            this.changeDetector.detectChanges();
        });
    }

    getObjectThumbnailSrc(object: BrowserAndPhysicsLoadable): string {
        return object.externalAddress?.replace(
            object.entryFile,
            this.THUMBNAIL_FILE_NAME
        );
    }
}
