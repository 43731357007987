<div class="labeling-central-tile">
    <div
        class="labeling-field"
        [ngClass]="{
            active:
                showImageContainer &&
                selectedImage?.processedUrl &&
                !selectedImage?.error,
            shrink:
                !showImageContainer ||
                !selectedImage?.processedUrl ||
                selectedImage?.error
        }"
    >
        <div class="current-image">
            <div #panzoomParent class="current-image-inner">
                <pan-zoom [config]="panZoomConfig" class="pan-zoom">
                    <img
                        #currentImage
                        (load)="onImageLoad()"
                        [src]="selectedImage?.processedUrl"
                        [alt]="selectedImage?.id"
                        *ngIf="
                            selectedImage?.processedUrl && !selectedImage?.error
                        "
                    />
                    <svg
                        [ngStyle]="{
                            cursor: cursorView
                        }"
                        (mousedown)="
                            cursorView = isDraggingActive
                                ? 'grabbing'
                                : 'default'
                        "
                        (mouseup)="
                            cursorView = isDraggingActive ? 'grab' : 'default'
                        "
                        *ngIf="svgStatus"
                        id="labeling"
                        #labeling
                        [attr.width]="svgStatus.w"
                        [attr.height]="svgStatus.h"
                        [attr.viewBox]="svgStatus.viewBox"
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <g
                            *ngFor="let annotation of annotations"
                            class="annotation-box"
                            [ngClass]="{
                                active: annotation.active,
                                selectable: !isDraggingActive
                            }"
                            (click)="selectAnnotation(annotation)"
                        >
                            <rect
                                [ngStyle]="{
                                    stroke: annotation.active
                                        ? 'white'
                                        : getColor(
                                              getImgCategoryName(
                                                  annotation.categoryId
                                              )
                                          )
                                }"
                                [attr.x]="annotation.bbox.x"
                                [attr.y]="annotation.bbox.y"
                                [attr.width]="annotation.bbox.width"
                                [attr.height]="annotation.bbox.height"
                            ></rect>
                            <rect
                                [ngStyle]="{
                                    fill: annotation.active
                                        ? 'white'
                                        : getColor(
                                              getImgCategoryName(
                                                  annotation.categoryId
                                              )
                                          ),
                                    stroke: annotation.active
                                        ? 'white'
                                        : getColor(
                                              getImgCategoryName(
                                                  annotation.categoryId
                                              )
                                          )
                                }"
                                [attr.x]="annotation.bbox.x"
                                [attr.y]="annotation.bbox.y - 20"
                                [attr.width]="annotation.bbox.width"
                                height="20"
                                class="title-box"
                            ></rect>
                            <text
                                [attr.x]="annotation.bbox.x + 3"
                                [attr.y]="annotation.bbox.y - 6"
                                class="title"
                            >
                                {{ getImgCategoryName(annotation.categoryId) }}
                            </text>
                        </g>
                    </svg>
                </pan-zoom>
            </div>
        </div>
        <div class="feature-buttons-wrapper">
            <button
                id="undo-button"
                [disabled]="!annotationStateService.hasUndo()"
                [ngClass]="{
                    disabled: !annotationStateService.hasUndo()
                }"
                (click)="annotationStateService.undo()"
                (mouseenter)="featureButtonActiveIdx = 0"
                (mouseleave)="featureButtonActiveIdx = null"
                [title]="
                    'imgDb.imgLabeling.featureButtonsWrapper.undo' | translate
                "
            >
                <img
                    [src]="
                        'assets/svg/img-db/labeling-dialog/undo' +
                        (annotationStateService.hasUndo() &&
                        featureButtonActiveIdx === 0
                            ? '-hover'
                            : '') +
                        '.svg'
                    "
                    alt="undo-icon"
                />
            </button>
            <button
                id="redo-button"
                [disabled]="!annotationStateService.hasRedo()"
                [ngClass]="{
                    disabled: !annotationStateService.hasRedo()
                }"
                (click)="annotationStateService.redo()"
                (mouseenter)="featureButtonActiveIdx = 1"
                (mouseleave)="featureButtonActiveIdx = null"
                [title]="
                    'imgDb.imgLabeling.featureButtonsWrapper.redo' | translate
                "
            >
                <img
                    [src]="
                        'assets/svg/img-db/labeling-dialog/redo' +
                        (annotationStateService.hasRedo() &&
                        featureButtonActiveIdx === 1
                            ? '-hover'
                            : '') +
                        '.svg'
                    "
                    alt="redo-icon"
                />
            </button>
            <button
                id="pan-button"
                [disabled]="currentZoomLevel <= 0"
                (click)="onPanButtonClick()"
                [ngClass]="{
                    disabled: currentZoomLevel <= 0,
                    active: isPanButtonActive
                }"
                (mouseenter)="featureButtonActiveIdx = 2"
                (mouseleave)="featureButtonActiveIdx = null"
                [title]="
                    'imgDb.imgLabeling.featureButtonsWrapper.pan' | translate
                "
            >
                <img
                    [src]="
                        'assets/svg/img-db/labeling-dialog/pan' +
                        ((currentZoomLevel > 0 &&
                            featureButtonActiveIdx === 2) ||
                        isPanButtonActive
                            ? '-hover'
                            : '') +
                        '.svg'
                    "
                    alt="pan-icon"
                />
            </button>
        </div>
    </div>
    <div class="d-flex justify-content-center" *ngIf="!showImageContainer">
        <div class="tryb-design-spinner"></div>
    </div>
    <div
        *ngIf="
            showImageContainer &&
            !selectedImage?.processedUrl &&
            !selectedImage?.error
        "
        class="w-100"
    >
        <img
            alt="spinner"
            src="assets/img/img-db/spinner.gif"
            class="img-fluid spinner"
        />
        <div class="text-center message">
            {{ 'imgDb.imgLabeling.stillProcess' | translate }}
        </div>
    </div>
    <div *ngIf="showImageContainer && selectedImage?.error">
        <div class="text-center message">
            {{ 'imgDb.imgLabeling.imgError' | translate }}
        </div>
    </div>
</div>
