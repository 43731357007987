import { Component, Input } from '@angular/core';
import { BaseAlgorithmClass } from '../base-algorithm-class';
import { AlgorithmType } from '../../models/algorithm-form';

@Component({
    selector: 'app-airl-parameters',
    templateUrl: './adversarial-trainer-parameters.component.html',
    styleUrls: [
        './adversarial-trainer-parameters.component.scss',
        '../../shared/algorithm-parameters.scss',
        '../sac-parameters/sac-parameters.component.scss',
    ],
})
export class AdversarialTrainerParametersComponent extends BaseAlgorithmClass {
    @Input() advancedSettingEnabled: boolean = false;
    protected readonly AlgorithmType = AlgorithmType;

    constructor() {
        super();
    }
}
