import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginRedirectionService } from './authentication/services/login-redirection.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class DefaultErrorHandler implements ErrorHandler {
    constructor(
        private redirectionService: LoginRedirectionService,
        private router: Router
    ) {}
    handleError(error: any): void {
        if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
                this.redirectionService.login(this.router.url);
                return;
            } else {
                console.error(
                    `Backend returned code ${error.status}, body was: `
                );
                console.error(error.error);
            }
        } else if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        }
        console.error('Something bad happened; please try again later.');
    }
}
