import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AlgorithmDTO } from '../models/algorithm-form';

@Injectable({
    providedIn: 'root',
})
export class AlgorithmService {
    readonly restUrl: string;

    constructor(private http: HttpClient) {
        this.restUrl = environment.backendUrl + '/rest/training';
    }

    getAlgorithmFormData(trainingId: string): Observable<AlgorithmDTO> {
        const url = this.createUrl(trainingId);
        return this.http.get<AlgorithmDTO>(url);
    }

    saveAlgorithmFormData(trainingId: string, algorithmDTO: AlgorithmDTO) {
        const url = this.createUrl(trainingId);

        return this.http.put(url, algorithmDTO);
    }

    private createUrl(trainingId: string): string {
        return `${this.restUrl}/${trainingId}/algorithm`;
    }
}
