import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import * as Skill from '../models/layer-namespace';
import * as d3 from 'd3';
import { SkillArchitectureStateService } from '../skill-architecture-state.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { combineLatestWith, filter } from 'rxjs/operators';
import { nonNil } from 'src/app/shared/utility';
import {
    mapToSkillArchitectureDropEvent,
    SkillArchitectureDropEvent,
    SkillArchitectureDropEventData,
} from './drop-event/drop-event';
import { DrawingService } from './drawing.service';
import { Drawable } from './drawing/drawable';
import { LayerModel } from '../models/layer-namespace';
import {
    TransformData,
    TransformDataUtil,
    TransformViewInfo,
} from './drawing/transform';
import { InputLayerSceneElement } from './drawing/input-layer-scene-element';
import { SceneStateService } from 'src/app/scenes/services/scene-state.service';
import { TrainingDTO } from 'src/app/shared/models/training-dto';

@Component({
    selector: 'app-architecture-visualization',
    templateUrl: './architecture-visualization.component.html',
    styleUrls: ['./architecture-visualization.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ArchitectureVisualizationComponent
    implements OnInit, OnDestroy, AfterViewInit, TransformViewInfo
{
    @Output() rendered: EventEmitter<Skill.SkillArchitectureEntity> =
        new EventEmitter();
    @Output() transform: EventEmitter<TransformData> = new EventEmitter();

    @Input() trainingId: string;
    @Input() editingIsDisabled: boolean;
    @Input() drawAreaId: string;
    @Input() initialTransform: TransformData;

    public queryTrainingData: TrainingDTO;

    sceneElements$: BehaviorSubject<InputLayerSceneElement[]> =
        new BehaviorSubject([]);
    architectureEntity: Skill.SkillArchitectureEntity;
    architectureStateSubscription: Subscription;
    updateSelectionSub: Subscription;
    network: Drawable;
    drawArea: d3.Selection<HTMLElement, unknown, HTMLElement, unknown>;
    private fetched: boolean = false;
    private viewInit: boolean = false;
    zoom: d3.ZoomBehavior<Element, unknown>;

    constructor(
        private skillArchitectureStateService: SkillArchitectureStateService,
        private drawingService: DrawingService,
        private sceneStateService: SceneStateService
    ) {}

    ngOnInit(): void {
        this.sceneStateService.getUnfixedElements(this.trainingId);

        this.architectureStateSubscription =
            this.skillArchitectureStateService.skillArchitecture$
                .pipe(filter(nonNil))
                .pipe(
                    combineLatestWith(
                        this.sceneStateService.inputLayerSceneElements$
                    )
                )
                .subscribe(([entity, sceneElements]) => {
                    this.updateVisualization(entity, sceneElements);
                    this.registerLayerClickEvent();
                    this.fetched = true;
                });

        this.updateSelectionSub =
            this.skillArchitectureStateService.selectedLayer$.subscribe(
                (layer) => {
                    this.onLayerSelect(layer);
                }
            );
    }

    ngAfterViewInit(): void {
        this.drawArea = d3.select('#' + this.drawAreaId);
        this.network = this.drawingService.init(this.drawArea);

        // define listener for whole drawArea, but we transform only networkDrawable
        const zoomListener = (e) => {
            const eventData = TransformDataUtil.createTransformData(e);
            this.transform.emit(eventData);
        };
        const endListener = () => {
            // since we allow to block zoom, check at the end
            let tState = d3.zoomTransform(this.drawArea.node());
            const transformData = this.currentTransformData;
            if (
                tState.x !== transformData.x ||
                tState.y !== transformData.y ||
                tState.k !== transformData.scale
            ) {
                tState = new d3.ZoomTransform(
                    transformData.scale,
                    transformData.x,
                    transformData.y
                );

                this.zoom.transform(this.drawArea, tState);
            }
        };
        this.zoom = d3
            .zoom()
            .scaleExtent([0, 1])
            .on('zoom', zoomListener)
            .on('end', endListener);
        this.drawArea.call(this.zoom);

        this.viewInit = true;
        this.initGraphView();
    }

    ngOnDestroy(): void {
        this.updateSelectionSub?.unsubscribe();
        this.architectureStateSubscription?.unsubscribe();
        this.viewInit = false;
        this.fetched = false;
    }

    private onLayerSelect(layer: LayerModel) {
        document
            .querySelectorAll('.layer-box.active')
            .forEach((item) => item.classList.remove('active'));

        const selectedLayer = document.getElementById(this.getLayerId(layer));
        if (selectedLayer) {
            selectedLayer.parentElement.classList.add('active');
            this.showSelectedLayer(layer);
        }
    }

    private updateVisualization(
        architectureEntity: Skill.SkillArchitectureEntity,
        sceneElements: InputLayerSceneElement[]
    ) {
        this.removeExistingVisualComponents();
        this.drawingService.visualizeSkillArchitecture(
            architectureEntity,
            sceneElements
        );
        this.rendered.emit(architectureEntity);
        this.initGraphView();
    }

    private removeExistingVisualComponents() {
        this.drawingService.clear();
    }

    private registerLayerClickEvent() {
        d3.selectAll('rect').on('click', (event: PointerEvent) =>
            this.onNodeClick(event)
        );
    }

    private onNodeClick(event: any): void {
        const clickedEventTargetId: string = event.target.id;
        const clickedLayerName = clickedEventTargetId.split('-')[1];
        this.skillArchitectureStateService.selectLayer(clickedLayerName);
    }

    public onLayerDrop(event: CdkDragDrop<string>) {
        this.handleDropEvent(mapToSkillArchitectureDropEvent(event));
    }

    private handleDropEvent(event: SkillArchitectureDropEvent) {
        const index = this.calculateDropTargetIndex(event);
        if (nonNil(index)) {
            this.updateStateWithDroppedLayer(event.data, index);
        }
    }

    private calculateDropTargetIndex(event): number {
        let index = undefined;
        document.querySelectorAll('.drop-target').forEach((el, i) => {
            const boundingRect = el.getBoundingClientRect();
            const xInRange =
                event.dropPoint.x < boundingRect.left + boundingRect.width &&
                event.dropPoint.x > boundingRect.left;
            const yInRange =
                event.dropPoint.y < boundingRect.top + boundingRect.height &&
                event.dropPoint.y > boundingRect.top;

            if (xInRange && yInRange) {
                index = i;
            }
        });

        return index;
    }

    private updateStateWithDroppedLayer(
        data: SkillArchitectureDropEventData,
        dropTargetIndex: number
    ) {
        this.skillArchitectureStateService.insertSubmittedLayer(
            data,
            dropTargetIndex
        );
    }

    public getViewableWidth(): number {
        return this.drawArea.node().getBoundingClientRect().width;
    }

    public getViewableHeight(): number {
        return this.drawArea.node().getBoundingClientRect().height;
    }

    public getGraphWidth(): number {
        return this.network.width;
    }

    public getGraphHeight(): number {
        return this.network.height;
    }

    public getCurrentScale(): number {
        return this.network.zoomScale;
    }

    public transformNetwork(data: TransformData): void {
        this.network.transform(data);
    }

    private getSelectedLayerElement(layer: LayerModel | string): HTMLElement {
        let layerId = '';
        if (typeof layer === 'string') {
            layerId = layer + '';
        } else {
            layerId = this.getLayerId(<LayerModel>layer);
        }

        return document.getElementById(layerId);
    }

    private getLayerId(layer: LayerModel): string {
        return 'rect-' + layer?.name;
    }

    public get currentTransformData(): TransformData {
        if (this.network) {
            return {
                x: this.network.draggedPosition.x,
                y: this.network.draggedPosition.y,
                scale: this.network.zoomScale,
            } as TransformData;
        } else {
            return null;
        }
    }

    private fireZoomEvent(transform: TransformData): void {
        let tState = new d3.ZoomTransform(
            transform.scale,
            transform.x,
            transform.y
        );

        this.zoom.transform(this.drawArea, tState);
    }

    private initGraphView() {
        if (this.fetched && this.viewInit) {
            let transform = this.initialTransform;
            if (!transform) {
                transform = this.calculateCentering();
            }

            this.fireZoomEvent(transform);
            //we don't know if it is filtered, so call it explicitly
            this.transformNetwork(transform);
        }
    }

    private calculateCentering(): TransformData {
        let scaleWidth = this.getViewableWidth() / this.getGraphWidth();
        let zoom = 1;

        if (scaleWidth > 1) {
            scaleWidth = 1; //avoid magnifying graph
        }

        // remain scale when new layer dropped
        if (scaleWidth < 1 && this.getCurrentScale() < 1) {
            zoom = this.getCurrentScale();
        }

        let x =
            this.getViewableWidth() / 2 -
            (scaleWidth * this.getGraphWidth()) / 2;
        const y = (this.getViewableHeight() - zoom * this.getGraphHeight()) / 2;

        // remain position when new layer dropped
        if (scaleWidth < 1 && this.network.draggedPosition.x !== 0) {
            x = this.network.draggedPosition.x;
        }

        return {
            x: x,
            y: y,
            scale: zoom,
        } as TransformData;
    }

    private showSelectedLayer(layer: LayerModel): void {
        const viewDomRect = this.drawArea.node().getBoundingClientRect();
        const scaledWidth = this.network.scaledWidth;
        const scaledHeight = this.network.scaledHeight;
        if (
            viewDomRect.width < scaledWidth ||
            viewDomRect.height < scaledHeight
        ) {
            const selectedHtml = this.getSelectedLayerElement(layer);
            //position of selected, inside main graph
            const selectedDomRect =
                selectedHtml.parentElement.getBoundingClientRect();

            if (
                selectedDomRect.right > viewDomRect.right ||
                selectedDomRect.bottom > viewDomRect.bottom
            ) {
                const tState = this.currentTransformData;
                const transformX = selectedDomRect.right - viewDomRect.right;
                //only when negative we should move up
                const transformY = selectedDomRect.bottom - viewDomRect.bottom;
                let transform = {
                    x: tState.x - transformX,
                    y: transformY < 0 ? tState.y : tState.y - transformY,
                    scale: tState.scale,
                } as TransformData;
                this.fireZoomEvent(transform);
            }
        }
    }
}
