import { Injectable } from '@angular/core';
import { HierarchyPointNode } from 'd3';
import {
    DThreeNodeEnterElement,
    DThreeSvgElement,
} from 'src/app/shared/models/d-three-types';
import { TrainingTreeNode } from '../models/training-tree-node';
import { TreeStateService } from './tree-state.service';
import { JobService } from './job.service';
import { TrainingStatus } from 'src/app/shared/models/training-status';
import { TranslateService } from '@ngx-translate/core';
import {
    MatLegacyDialog as MatDialog,
    MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';

@Injectable()
export class TreeActionsService {
    refuseTransfer = false;
    replayClickData: HierarchyPointNode<TrainingTreeNode>;
    projectId = '';

    constructor(
        private treeStateService: TreeStateService,
        private jobService: JobService,
        private translateService: TranslateService,
        private dialog: MatDialog
    ) {
        this.treeStateService.onForceChangeSelected.subscribe(() => {
            this.replayClickData = undefined;
        });
    }

    addNodeClickAction(enterElement: DThreeNodeEnterElement) {
        enterElement
            .selectAll('foreignObject')
            .on(
                'mouseup',
                (event$, node: HierarchyPointNode<TrainingTreeNode>) =>
                    this.handleClick(event$, node)
            );
    }

    handleClick(
        event$: MouseEvent,
        node: HierarchyPointNode<TrainingTreeNode>
    ) {
        const elementId = event$.target['id'];
        const treeNode = node.data;

        if (this.treeStateService.selectedTreeNode.uuid !== treeNode.uuid) {
            this.treeStateService.isMenuOpen =
                elementId === 'training-node-menu';
            this.treeStateService.selectedTreeNode = treeNode;
            this.replayClickData = undefined;
        } else
            switch (elementId) {
                case 'training-node-menu':
                    this.toggleMenuAndUpdateTree(treeNode);
                    break;
                case 'training-node-start':
                    this.start(treeNode);
                    this.toggleMenuAndUpdateTree(treeNode);
                    break;
                case 'training-node-stop':
                    this.stop(treeNode);
                    this.toggleMenuAndUpdateTree(treeNode);
                    break;
                case 'training-node-delete':
                    this.openDeletionConfirmDialog(treeNode);
                    this.toggleMenuAndUpdateTree(treeNode);
                    break;
                case 'training-node-clone':
                    this.clone(treeNode);
                    this.toggleMenuAndUpdateTree(treeNode);
                    break;
                case 'training-node-download':
                    this.treeStateService.downloadTrainingSkill(treeNode.uuid);
                    this.toggleMenuAndUpdateTree(treeNode);
                    break;
            }
    }

    private toggleMenuAndUpdateTree(treeNode: TrainingTreeNode) {
        this.treeStateService.isMenuOpen = !this.treeStateService.isMenuOpen;
        this.treeStateService.selectedTreeNode = treeNode;
    }

    start(treeNode: TrainingTreeNode): void {
        this.jobService.start(treeNode).subscribe(() => {
            treeNode.status = TrainingStatus.QUEUED;
            this.treeStateService.setTreeHasRunningTraining(true);
        });
    }

    stop(treeNode: TrainingTreeNode): void {
        this.jobService.stop(treeNode).subscribe(() => {
            treeNode.status = TrainingStatus.CANCELLING;
        });
    }

    clone(treeNode: TrainingTreeNode): void {
        this.treeStateService.cloneTreeNode(
            treeNode.uuid,
            this.translateService.instant(
                'splitScreen.components.contextMenu.cloneSuffix'
            )
        );
    }

    openDeletionConfirmDialog(treeNode: TrainingTreeNode): void {
        const results = this.translateService.instant(
            [
                'splitScreen.components.contextMenu.delete1',
                'splitScreen.components.contextMenu.keep',
                'splitScreen.components.contextMenu.confirmation',
                'splitScreen.components.contextMenu.delete2',
            ],
            {
                name: treeNode.name,
            }
        );
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            confirmButtonText:
                results['splitScreen.components.contextMenu.delete1'],
            cancelButtonText:
                results['splitScreen.components.contextMenu.keep'],
            title: results['splitScreen.components.contextMenu.confirmation'],
            message: results['splitScreen.components.contextMenu.delete2'],
        };

        this.dialog
            .open(ConfirmDialogComponent, dialogConfig)
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    this.deleteNode(treeNode.uuid);
                }
            });
    }

    deleteNode(trainingId: string): void {
        this.treeStateService.deleteTreeNode(trainingId);
    }
}
