<div id="scene-content" class="row" *ngIf="waitFor | async">
    <div class="col-2" id="sticky-sidebar">
        <div class="text-center left-sidebar">
            <div class="left-sidebar__item">
                <app-scene-composition
                    [trainingId]="training.uuid"
                    [editingIsDisabled]="editingIsDisabled"
                ></app-scene-composition>
            </div>
            <div class="left-sidebar__item">
                <app-scene-diversity
                    [trainingId]="training.uuid"
                    [editingIsDisabled]="editingIsDisabled"
                ></app-scene-diversity>
            </div>
        </div>
    </div>
    <div class="col-8 canv">
        <div class="canvas-container" #container></div>
    </div>
    <div class="col-2" id="context-area">
        <div class="context-area-content">
            <app-context-area
                [trainingId]="training.uuid"
                [editingIsDisabled]="editingIsDisabled"
            ></app-context-area>
        </div>
    </div>
</div>
