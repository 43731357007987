<div class="d-flex flex-direction-column">
    <div
        class="btn-page-container backward"
        [ngClass]="{ disabled: inputControl.value === 1 }"
    >
        <button
            [id]="'btn-to-start-page-' + tag"
            class="page-button to-start-button"
            (click)="onToStartClick()"
            (mouseover)="onHover('start', true)"
            (mouseleave)="onHover('start', false)"
        >
            <img [src]="startSrc" alt="to-start-arrow" />
        </button>
        <button
            [id]="'btn-previous-page-' + tag"
            class="page-button previous-button"
            (click)="onPreviousClick()"
            (mouseover)="onHover('backward', true)"
            (mouseleave)="onHover('backward', false)"
        >
            <img [src]="backwardSrc" alt="previous-page-arrow" />
        </button>
    </div>
    <div class="page-text-wrapper">
        <p class="page-texts">
            {{ 'shared.components.paging.' + tag | translate }}
        </p>
    </div>
    <div class="current-page-input-wrapper">
        <input
            type="text"
            [id]="'current-page-input-' + tag"
            class="current-page-input"
            [formControl]="inputControl"
            (input)="formatValue()"
            (keyup.enter)="onEnterPress()"
            (blur)="onSubmit()"
            #numberInput
        />
    </div>
    <div class="page-text-wrapper">
        <p class="page-texts">
            {{ 'shared.components.paging.of' | translate }}
            <span>{{ maxValue }}</span>
        </p>
    </div>
    <div
        class="btn-page-container forward"
        [ngClass]="{ disabled: inputControl.value === maxValue }"
    >
        <button
            [id]="'btn-next-page-' + tag"
            class="page-button next-button"
            (click)="onNextClick()"
            (mouseover)="onHover('forward', true)"
            (mouseleave)="onHover('forward', false)"
        >
            <img [src]="forwardSrc" alt="next-page-arrow" />
        </button>
        <button
            [id]="'btn-to-end-page-' + tag"
            class="page-button to-end-button"
            (click)="onToEndClick()"
            (mouseover)="onHover('end', true)"
            (mouseleave)="onHover('end', false)"
        >
            <img [src]="endSrc" alt="to-end-arrow" />
        </button>
    </div>
</div>
