import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TrainingInfoWithCode } from 'src/app/shared/models/training-info-with-code';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class CustomRandomizationService {
    private readonly baseUrl;

    constructor(private http: HttpClient) {
        this.baseUrl = `${environment.backendUrl}/rest/training/`;
    }

    getCustomRandomizationFunction(
        trainingId: string
    ): Observable<TrainingInfoWithCode> {
        const url = this.baseUrl + `${trainingId}/customRandomizationFunction`;
        return this.http.get<TrainingInfoWithCode>(url);
    }

    patchCustomRandomizationFunction(trainingId: string, customCode: string) {
        const url = this.baseUrl + `${trainingId}/customRandomizationFunction`;
        customCode = customCode.replace(new RegExp('\t', 'g'), '    ');
        return this.http.patch<TrainingInfoWithCode>(url, { code: customCode });
    }
}
