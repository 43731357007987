import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TrainingDTO } from '../models/training-dto';
import { TrainingInfoWithCode } from '../models/training-info-with-code';
import { ObservationSpace } from 'src/app/skill-architecture-editor/interfaces/observation';

@Injectable({
    providedIn: 'root',
})
export class TrainingService {
    readonly trainingUrl: string;

    constructor(private http: HttpClient) {
        this.trainingUrl = environment.backendUrl + '/rest/training';
    }

    get(uuid: string): Observable<TrainingDTO> {
        const url = this.trainingUrl + `/${uuid}`;
        return this.http.get<TrainingDTO>(url);
    }

    delete(uuid: string): Observable<void> {
        const url = this.trainingUrl + `/${uuid}`;
        return this.http.delete<void>(url);
    }

    update(training: TrainingDTO): Observable<TrainingDTO> {
        const url = this.trainingUrl + `/${training.uuid}`;
        return this.http.patch<TrainingDTO>(url, training);
    }

    clone(uuid: string, cloneSuffix: string): Observable<TrainingDTO> {
        const url = this.trainingUrl + `/${uuid}/clone`;
        let queryParams = new HttpParams().set('suffix', cloneSuffix);
        return this.http.post<TrainingDTO>(url, '', { params: queryParams });
    }

    downloadSkill(uuid: string): Observable<HttpResponse<Blob>> {
        const url = this.trainingUrl + `/${uuid}/skill`;

        return this.http.get<Blob>(url, {
            responseType: 'blob' as 'json',
            observe: 'response',
        });
    }

    loadTrainingInfoForCostFunction(
        trainingId: string
    ): Observable<TrainingInfoWithCode> {
        const url = `${this.trainingUrl}/${trainingId}/customCostFunction`;
        return this.http.get<TrainingInfoWithCode>(url);
    }

    saveCustomCode(
        trainingId: string,
        code: string
    ): Observable<TrainingInfoWithCode> {
        const url = `${this.trainingUrl}/${trainingId}/customCostFunction`;
        code = code.replace(new RegExp('\t', 'g'), '    ');
        return this.http.patch<TrainingInfoWithCode>(url, { code: code });
    }

    getObservations(trainingId: string): Observable<ObservationSpace> {
        const url = `${this.trainingUrl}/${trainingId}/observation`;
        return this.http.get<ObservationSpace>(url);
    }

    updateObservations(
        trainingId: string,
        observations: ObservationSpace
    ): Observable<ObservationSpace> {
        const url = `${this.trainingUrl}/${trainingId}/observation`;
        return this.http.put<ObservationSpace>(url, observations);
    }
}
