<div
    #treeContainer
    style="width: 100%; height: 100%; overflow: hidden; position: relative"
    (mousewheel)="mousewheel($event)"
    (mousedown)="mousedown()"
    (mouseup)="mouseup()"
    (contextmenu)="mouseup()"
    (mouseleave)="mouseup()"
    (mousemove)="mousemove($event)"
>
    <div style="position: relative; height: 100%">
        <svg
            id="tree-svg"
            class="tree-svg"
            #treeSvg
            [ngStyle]="{
                position: 'relative',
                left: this.leftOffset + 'px',
                top: this.topOffset + 'px',
                transform: 'scale(' + getTreeScale() + ')',
                width: '100%',
                height: '100%',
                overflow: 'visible'
            }"
        ></svg>
    </div>
</div>
