import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContainerType } from '../../../shared/services/container/container.types';

@Component({
    selector: 'app-project-split-screen-header',
    templateUrl: './project-split-screen-header.component.html',
    styleUrls: ['./project-split-screen-header.component.scss'],
})
export class ProjectSplitScreenHeaderComponent {
    protected readonly ContainerType = ContainerType;

    @Input() containerType: ContainerType;
    @Input() containerName: string;
    @Input() memberRole: string;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {}

    navigateToContainerMembers() {
        this.router.navigate(['members'], { relativeTo: this.activatedRoute });
    }

    navigateToContainerSettings() {
        this.router.navigate(['settings'], { relativeTo: this.activatedRoute });
    }
}
