import { Component, Input, OnInit } from '@angular/core';
import * as fromAlgorithmForm from '../../../algorithm/models/algorithm-form';
import {
    AlgorithmDTO,
    AlgorithmParameter,
    AlgorithmType,
} from '../../models/algorithm-form';
import { FormControl, FormGroup } from '@angular/forms';
import { AlgorithmService } from '../../services/algorithm.service';
import { AlgorithmFormService } from '../../services/algorithm-form.service';
import { TrainingDTO } from 'src/app/shared/models/training-dto';
import { TrainingStatus } from 'src/app/shared/models/training-status';
import { TrainingType } from '../../../shared/enums/training-type.enum';
import { nonNil } from '../../../shared/utility';

@Component({
    selector: 'app-algorithm',
    templateUrl: './algorithm.component.html',
    styleUrls: ['./algorithm.component.scss'],
})
export class AlgorithmComponent implements OnInit {
    // html import
    protected readonly AlgorithmType = AlgorithmType;
    protected readonly TrainingType = TrainingType;

    @Input() training: TrainingDTO;

    trainingId: string;
    dataLoaded: boolean = false;
    editingIsDisabled: boolean = false;
    advancedSetting: boolean = false;
    form: FormGroup;

    algorithmTypes: fromAlgorithmForm.AlgorithmType[];

    type: FormControl<fromAlgorithmForm.AlgorithmType>;

    constructor(
        private algorithmService: AlgorithmService,
        private formService: AlgorithmFormService
    ) {}

    ngOnInit(): void {
        this.trainingId = this.training.uuid;
        this.editingIsDisabled = this.training.status !== TrainingStatus.NEW;
        this.setAlgorithmTypes(this.training.trainingType);

        this.algorithmService
            .getAlgorithmFormData(this.trainingId)
            .subscribe((algorithm: AlgorithmDTO) => {
                this.initializeTypeForm(
                    algorithm.type,
                    this.training.status,
                    this.training.trainingType
                );
                this.initializeAlgorithmForm(algorithm);

                this.advancedSetting = algorithm.advancedSetting;
                this.dataLoaded = true;
            });
    }

    initializeTypeForm(
        type: AlgorithmType,
        trainingStatus: TrainingStatus,
        trainingType: TrainingType
    ) {
        this.type = new FormControl<AlgorithmType>(type);

        if (
            trainingStatus !== TrainingStatus.NEW ||
            trainingType === TrainingType.VISUAL
        ) {
            this.type.disable();
        }
    }

    initializeAlgorithmForm(data: AlgorithmDTO) {
        this.form = this.formService.getForm(data);
    }

    setAlgorithmTypes(type: TrainingType) {
        switch (type) {
            case TrainingType.MOTORIC:
                this.algorithmTypes = [
                    AlgorithmType.PPO,
                    AlgorithmType.SAC,
                    AlgorithmType.BC,
                    AlgorithmType.GAIL,
                    AlgorithmType.AIRL,
                ];
                break;
            case TrainingType.VISUAL:
                this.algorithmTypes = [AlgorithmType.ADAM];
                break;
            default:
                this.algorithmTypes = [];
        }
    }

    slideToggleChange($event) {
        if (!this.editingIsDisabled) {
            this.advancedSetting = $event.checked;
            this.onValueChange();
        }
    }

    onValueChange($event?) {
        if (!this.editingIsDisabled) {
            const algorithmDTO: AlgorithmDTO = this.prepareAlgorithmDto();

            this.adjustValuesForSaving(algorithmDTO.parameters);

            this.algorithmService
                .saveAlgorithmFormData(this.trainingId, algorithmDTO)
                .subscribe((algorithmDto: AlgorithmDTO) => {
                    if ($event === 'changedLearner') {
                        this.initializeAlgorithmForm(algorithmDto);
                        this.dataLoaded = true;
                    }
                });
        }
    }

    onTypeValueChange() {
        if (!this.editingIsDisabled) {
            this.dataLoaded = false;
            this.advancedSetting = false;

            // SaveAlgorithm will create a new Algorithm if send with empty parameters (duh, obviously!)
            const algorithmDTO: AlgorithmDTO = this.prepareAlgorithmDto(null);
            this.algorithmService
                .saveAlgorithmFormData(this.trainingId, algorithmDTO)
                .subscribe((data: AlgorithmDTO) => {
                    this.initializeAlgorithmForm(data);
                    this.dataLoaded = true;
                });
        }
    }

    private prepareAlgorithmDto(
        rawFormValues: Object = this.form.getRawValue()
    ): AlgorithmDTO {
        let parameters = [];
        if (nonNil(rawFormValues)) {
            Object.keys(rawFormValues).forEach((scope) => {
                Object.entries(rawFormValues[scope]).forEach(
                    ([name, value]) => {
                        parameters.push({
                            name,
                            value,
                            scope,
                        } as AlgorithmParameter);
                    }
                );
            });
        }
        return {
            type: this.type.value,
            parameters: parameters,
            advancedSetting: this.advancedSetting,
        };
    }

    private adjustValuesForSaving(parameters: AlgorithmParameter[]) {
        parameters.forEach((parameter: AlgorithmParameter) => {
            const name = parameter.name;
            if (name === 'epsilon') {
                parameter.value = Math.pow(10, parameter.value as number);
            }

            let value = parameter.value;

            if (typeof value === 'string') {
                if (value.includes('.') || value.includes(',')) {
                    parameter.value = parseFloat(
                        parameter.value.replace(',', '.')
                    );
                } else {
                    if (parameter.value === '') {
                        parameter.value = null;
                    } else {
                        const numeric = parseInt(parameter.value);
                        if (!isNaN(numeric)) {
                            parameter.value = numeric;
                        }
                    }
                }
            }
        });
    }
}
