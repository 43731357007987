export enum AlgorithmType {
    PPO = 'PPO',
    ADAM = 'ADAM',
    SAC = 'SAC',
    BC = 'BC',
    GAIL = 'GAIL',
    AIRL = 'AIRL',
}

export enum Policy {
    MLP_POLICY = 'MLP_POLICY',
    MLP_LSTM_POLICY = 'MLP_LSTM_POLICY',
    MLP_LN_LSTM_POLICY = 'MLP_LN_LSTM_POLICY',
    CNN_POLICY = 'CNN_POLICY',
    CNN_LSTM_POLICY = 'CNN_LSTM_POLICY',
    CNN_LN_LSTM_POLICY = 'CNN_LN_LSTM_POLICY',
}

export enum ImitationPolicy {
    FEED_FORWARD_32POLICY = 'FEED_FORWARD_32POLICY',
}

export enum FrequencyUnit {
    STEP = 'STEP',
    EPISODE = 'EPISODE',
}

export enum ActionNoiseType {
    NORMAL = 'NORMAL',
    ORNSTEIN_UHLENBECK = 'ORNSTEIN_UHLENBECK',
    NONE = 'NONE',
}

export enum ReplayBufferClass {
    REPLAY_BUFFER = 'REPLAY_BUFFER',
    NONE = 'NONE',
}

export enum Scope {
    TRAINING_SETUP = 'trainingSetup',
    SPECIFIC = 'algorithmSpecificParameters',
    GENERAL = 'generalTrainingParameters',
    OPTIMIZER = 'optimizerParameters',
    ENVIRONMENT = 'environmentParameters',
    LEARNER_SPECIFIC = 'learnerAlgorithmSpecificParameters',
    LEARNER_TRAINING_SETUP = 'learnerTrainingSetup',
    LEARNER_GENERAL = 'learnerGeneralTrainingParameters',
    LEARNER_OPTIMIZER = 'learnerOptimizerParameters',
}

export interface AlgorithmParameter {
    name: string;
    value: any;
    scope: Scope;
}

export interface AlgorithmDTO {
    parameters: AlgorithmParameter[];
    type: AlgorithmType;
    advancedSetting: boolean;
}
