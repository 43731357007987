import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import * as THREE from 'three';
import { Euler, Object3D, Vector3 } from 'three';
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { SceneVisualService } from '../../../../services/scene-visual.service';
import { CartesianCoordinateValidator } from '../../../../../shared/validators/cartesian-coordinate-validator';
import { NumberFormatValidator } from 'src/app/shared/validators/number-format-validator';
import { SceneStateService } from '../../../../services/scene-state.service';
import { LoadedSceneElement } from 'src/app/scenes/models/loaded-scene-element';
import { filter, map, mergeMap, Subject, take, tap } from 'rxjs';
import { degreesToRadians, nonNil } from 'src/app/shared/utility';
import {
    convertEuler,
    Orientation,
    StateOfSceneElement,
} from 'src/app/scenes/models/state-of-scene-element';
import { TranslateService } from '@ngx-translate/core';
import { ScaleValidator } from 'src/app/shared/validators/scale-validator';
import { NumberTranslateService } from 'src/app/shared/services/number-translate.service';
import { OrientationValidator } from 'src/app/shared/validators/orientation-validator';
import { ScrollableService } from '../../../../services/scrollable.service';
import { environment } from 'src/environments/environment';
import { SceneDiversitySelection } from 'src/app/scenes/models/scene-diversity-selection';

@Component({
    selector: 'app-selected-object3-d',
    templateUrl: './selected-object3-d.component.html',
    styleUrls: [
        '../../../../styles/scrollable-container.scss',
        './selected-object3-d.component.scss',
    ],
})
export class SelectedObject3DComponent implements OnInit {
    loadingSubscription = new Subject<LoadedSceneElement>();
    loadedSceneElement: LoadedSceneElement;
    additionalSceneElements: LoadedSceneElement[] = [];
    selection: SceneDiversitySelection;
    selectedObjectForm: UntypedFormGroup = this.createForm();
    oldInputValue: string = '';
    control: UntypedFormControl;
    @Input() editingIsDisabled: boolean;
    @Output() expandArgumentsForm = new EventEmitter<boolean>();

    displayForm: boolean = false;

    currentPosRot = {
        x: 0.0,
        y: 0.0,
        z: 0.0,
        xrot: 0.0,
        yrot: 0.0,
        zrot: 0.0,
    };

    @ViewChild('selectedObjectContainer')
    selectedObjectContainerRef: ElementRef;
    hasScrollbar: boolean = false;

    disableScale: boolean = false;

    constructor(
        private sceneVisualService: SceneVisualService,
        private formBuilder: UntypedFormBuilder,
        private sceneStateService: SceneStateService,
        private translate: TranslateService,
        private numberTranslate: NumberTranslateService,
        private scrollableService: ScrollableService
    ) {}

    ngOnInit(): void {
        this.selectedObjectForm.valueChanges.subscribe(() => {
            let canChange = !(
                this.selectedObjectForm.touched &&
                this.selectedObjectForm.invalid
            );
            this.sceneVisualService.isArrangementFormValid.next(canChange);
        });

        this.sceneStateService.getDiversitySelection$.subscribe(
            (selection: SceneDiversitySelection) => {
                this.selection = selection;
                this.updateScaleState();
            }
        );

        this.sceneStateService.additionalSceneElements$.subscribe(
            (additionalSceneElements) => {
                this.additionalSceneElements = additionalSceneElements;
                this.updateScaleState();
            }
        );

        this.sceneVisualService.selected$
            .pipe(
                tap((object: LoadedSceneElement) => {
                    if (!object && this.selectedObjectForm) {
                        this.disableForm();
                        setTimeout(() => {
                            this.displayForm = true;
                        }, 200);
                    } else if (object && this.selectedObjectForm) {
                        this.selectedObjectForm.enable();
                    }
                }),
                tap((lse) => this.expandAfterSelect(lse)),
                filter(nonNil),
                tap((selected) => {
                    this.loadedSceneElement = selected;
                }),
                tap(() => this.updateScaleState()),
                map((object: LoadedSceneElement) => object.object3D$),
                filter(nonNil),
                map((object: Object3D) => object.id),
                mergeMap(
                    this.sceneStateService.asyncFindElementByThreeId.bind(
                        this.sceneStateService
                    )
                ),
                filter(nonNil),
                tap(this.selectSceneElement.bind(this)),
                tap(this.sceneElementMovedViaControls.bind(this))
            )
            .subscribe();

        this.sceneStateService.sceneElementStateUpdated.subscribe(
            this.sceneElementMovedViaControls.bind(this)
        );

        setTimeout(() => {
            this.sceneVisualService.selected$
                .pipe(filter(nonNil))
                .subscribe((lse) => {
                    this.selectSceneElement(lse);
                    this.loadingSubscription.next(lse);
                });
        });

        this.translate.onLangChange.subscribe(() => {
            const controls = this.selectedObjectForm.controls;
            Object.keys(controls).forEach((controlKey: string) => {
                const control = controls[controlKey];
                control.setValue(
                    this.numberTranslate.translateNumber(control.value)
                );
            });
        });
    }

    private updateScaleState(): void {
        if (this.selectedObjectForm.status === 'DISABLED') {
            return;
        }

        const selectedIsAdditional = !!this.additionalSceneElements.find(
            (lse) =>
                lse.sceneElement.id === this.loadedSceneElement?.sceneElement.id
        );
        this.disableScale = !this.selection.base && !selectedIsAdditional;
        if (this.disableScale) {
            this.selectedObjectForm.controls.scale.disable();
        } else {
            this.selectedObjectForm.controls.scale.enable();
        }
    }

    private expandAfterSelect(object: LoadedSceneElement) {
        setTimeout(() => {
            this.expandArgumentsForm.emit(!!object);
        }, 300);
    }

    private createForm(): UntypedFormGroup {
        return this.formBuilder.group({
            x: [
                '0',
                [
                    Validators.required,
                    NumberFormatValidator.validateFormat,
                    CartesianCoordinateValidator.validateRange,
                ],
            ],
            y: [
                '0',
                [
                    Validators.required,
                    NumberFormatValidator.validateFormat,
                    CartesianCoordinateValidator.validateRange,
                ],
            ],
            z: [
                '0',
                [
                    Validators.required,
                    NumberFormatValidator.validateFormat,
                    CartesianCoordinateValidator.validateRange,
                ],
            ],
            xrot: [
                '0',
                [
                    Validators.required,
                    NumberFormatValidator.validateFormat,
                    OrientationValidator.validateRange,
                ],
            ],
            yrot: [
                '0',
                [
                    Validators.required,
                    NumberFormatValidator.validateFormat,
                    OrientationValidator.validateRange,
                ],
            ],
            zrot: [
                '0',
                [
                    Validators.required,
                    NumberFormatValidator.validateFormat,
                    OrientationValidator.validateRange,
                ],
            ],
            scale: ['100', [ScaleValidator.validateRange]],
        });
    }

    @HostListener('window:resize')
    onResize() {
        this.hasScrollbar = this.scrollableService.checkIfHasScrollbar(
            this.selectedObjectContainerRef
        );
    }

    private disableForm() {
        this.selectedObjectForm.patchValue({
            x: '--',
            y: '--',
            z: '--',
            xrot: '--',
            yrot: '--',
            zrot: '--',
            scale: '--',
        });
        this.selectedObjectForm.disable();
        this.selectedObjectForm.markAsTouched();
    }

    public sceneElementMovedViaControls(state: StateOfSceneElement) {
        // Update input fields
        this.loadedSceneElement?.object3D$
            .pipe(filter(nonNil), take(1))
            .subscribe((object: Object3D) => {
                this.updateFormsPosition(object.position);
                this.updateFormsRotation(
                    state.orientation ?? convertEuler(object.rotation)
                );
                this.updateFormsScale(object.scale.x * 100);
            });
    }

    public updateValueWithStepper(
        value: string,
        name: string,
        eventName: string
    ) {
        if (this.selectedObjectForm.invalid) {
            return;
        }

        const control = this.selectedObjectForm.controls[name];
        const oldValue = control.value;
        if (control.valid) {
            control.setValue(value);
            control.updateValueAndValidity();
            if (control.invalid) {
                control.setValue(oldValue);
                control.updateValueAndValidity();
            } else {
                this.onEventWrapped(eventName, name);
            }
        }
    }

    private updateFormsPosition(position: THREE.Vector3) {
        ['x', 'y', 'z'].forEach((key) => {
            const cmVal = this.convertNumToCm(position[key]);
            const updateVal = this.prepareForWriting(cmVal, 2);
            this.writeToForm(key, updateVal);
        });
    }

    private prepareForWriting(
        numberToPrepare: number,
        decimalPlace: number,
        toTranslate: boolean = true
    ): string {
        const rounded: number = this.roundToTwoDecimalPlaces(numberToPrepare);
        let fixed: string = this.enforceFixedPointNotation(
            rounded,
            decimalPlace
        );
        return toTranslate
            ? this.numberTranslate.translateNumber(fixed)
            : fixed;
    }

    private extractAsNumberFrom(axis: string, isInt: boolean = false): number {
        let input: string = String(
            this.selectedObjectForm.controls[axis].value
        );
        input = this.withDotAsDecimalSep(input);
        const asNumber: number = isInt ? parseInt(input) : parseFloat(input);
        return asNumber;
    }

    private withDotAsDecimalSep(value: string): string {
        const comma = ',';
        const dot = '.';
        return value.replace(comma, dot);
    }

    private withCommaAsDecimalSep(value: string): string {
        const dot = '.';
        const comma = ',';
        return value.replace(dot, comma);
    }

    public writeToForm(axis: string, numberString: string): void {
        this.selectedObjectForm.patchValue({
            [axis]: this.numberTranslate.translateNumber(numberString),
        });
    }

    private updateFormsRotation(rotation: Orientation) {
        const updatedDegreesX = this.prepareForWriting(rotation.x, 1);
        const updatedDegreesY = this.prepareForWriting(rotation.y, 1);
        const updatedDegreesZ = this.prepareForWriting(rotation.z, 1);
        this.writeToForm('xrot', updatedDegreesX);
        this.writeToForm('yrot', updatedDegreesY);
        this.writeToForm('zrot', updatedDegreesZ);
    }

    private updateFormsScale(scale: number) {
        const updatedScale = this.prepareForWriting(scale, 0, false);
        this.writeToForm('scale', updatedScale.toString());
    }

    private selectSceneElement(loadedSceneElement: LoadedSceneElement) {
        this.loadedSceneElement = loadedSceneElement;
        loadedSceneElement?.object3D$
            .pipe(
                filter(nonNil),
                take(1),
                tap((_) => {
                    this.setupFormControlsPosition(this.loadedSceneElement);
                    this.setupFormControlsRotation(this.loadedSceneElement);
                    this.selectedObjectForm.patchValue({
                        scale: Math.round(
                            this.loadedSceneElement.sceneElement.scale
                        ).toString(),
                    });
                    if (this.disableScale) {
                        this.selectedObjectForm.get('scale').disable();
                    } else {
                        this.selectedObjectForm.get('scale').enable();
                    }
                    if (this.editingIsDisabled) {
                        this.selectedObjectForm.disable();
                    }
                    this.selectedObjectForm.markAsTouched();
                    this.displayForm = true;
                })
            )
            .subscribe();
    }

    private setupFormControlsPosition(loadedSceneElement: LoadedSceneElement) {
        const position = loadedSceneElement.state.position;
        const updateObj = new Object();
        ['x', 'y', 'z'].forEach((key) => {
            const val = this.prepareForWriting(
                this.convertNumToCm(position[key]),
                2
            );
            updateObj[key] = val;
        });
        this.selectedObjectForm.patchValue(updateObj);
    }

    private setupFormControlsRotation(loadedSceneElement: LoadedSceneElement) {
        const preparedForWritingX: string = this.prepareForWriting(
            loadedSceneElement.state.orientation.x,
            1
        );
        const preparedForWritingY: string = this.prepareForWriting(
            loadedSceneElement.state.orientation.y,
            1
        );
        const preparedForWritingZ: string = this.prepareForWriting(
            loadedSceneElement.state.orientation.z,
            1
        );

        this.selectedObjectForm.patchValue({
            xrot: preparedForWritingX,
            yrot: preparedForWritingY,
            zrot: preparedForWritingZ,
        });
    }

    onEventWrapped(method: string, axis: string, formName?: string) {
        this.loadedSceneElement.object3D$
            .pipe(filter(nonNil), take(1))
            .subscribe((object) => {
                if (formName) {
                    this[method](object, axis, formName);
                } else {
                    this[method](object, axis);
                }
            });
    }

    blurInput(event: { target: HTMLInputElement }) {
        event.target.blur();
    }

    private onCancelInput(axis: string, object: THREE.Object3D) {
        this.updateSceneElementPosition(
            axis,
            this.extractAsNumberFrom(axis),
            object
        );
    }

    private onCancelInputRot(object: THREE.Object3D) {
        this.updateRotation(object);
    }

    private onCancelInputScale(object: THREE.Object3D) {
        this.updateSceneElementScale(
            this.extractAsNumberFrom('scale', true) / 100,
            object
        );
    }

    onCancelWrapped(
        method: string,
        axis: string,
        event: { target: HTMLInputElement }
    ) {
        this.loadedSceneElement.object3D$
            .pipe(filter(nonNil), take(1))
            .subscribe((object) => {
                const formControl = this.selectedObjectForm.get(axis);
                formControl.setValue(this.oldInputValue);
                event.target.value = this.oldInputValue;
                if (method === 'onCancelInput') {
                    this.onCancelInput(axis, object);
                } else {
                    this[method](object);
                }
                event.target.blur();
            });
    }

    // noinspection JSUnusedLocalSymbols
    private onFocus(object: THREE.Object3D, axis: string) {
        const input = this.selectedObjectForm.controls[axis];
        if (!this.oldInputValue) {
            this.oldInputValue = input.value;
        }
        const exactPosition: number = this.convertNumToCm(
            object.position[axis]
        );
        if (input.valid && !isNaN(exactPosition)) {
            const positionWithDecimalPlace: string = this.prepareForWriting(
                exactPosition,
                2
            );
            this.writeToForm(axis, positionWithDecimalPlace);
        }
    }

    onFocusScale() {
        const input = this.selectedObjectForm.controls['scale'];
        if (!this.oldInputValue) {
            this.oldInputValue = input.value;
        }
    }

    // noinspection JSUnusedLocalSymbols
    private onInput(object: THREE.Object3D, axis: string) {
        // Write precise value to robot's position vector
        this.numberTranslate.limitDecimalPlaces(
            this.selectedObjectForm.controls[axis],
            2
        );
        const input = this.selectedObjectForm.controls[axis];
        input.markAsTouched();
        const userInput: number = this.extractAsNumberFrom(axis);

        if (input.valid && !isNaN(userInput)) {
            this.updateSceneElementPosition(axis, userInput, object);
        }
        if (!isNaN(userInput)) {
            input.setValue(this.numberTranslate.translateNumber(input.value));
        }
    }

    // noinspection JSUnusedLocalSymbols
    private onInputRot(object: THREE.Object3D, formName: string) {
        this.numberTranslate.limitDecimalPlaces(
            this.selectedObjectForm.controls[formName],
            1
        );
        const input = this.selectedObjectForm.controls[formName];
        input.markAsTouched();
        const userInput: number = this.extractAsNumberFrom(formName);

        if (input.valid && !isNaN(userInput)) {
            if (!this.oldInputValue) {
                this.oldInputValue = input.value;
            }
            this.updateRotation(object);
        }
        if (!isNaN(userInput)) {
            input.setValue(this.numberTranslate.translateNumber(input.value));
        }
    }

    // noinspection JSUnusedLocalSymbols
    private onInputScale(object: THREE.Object3D) {
        const input = this.selectedObjectForm.controls['scale'];
        input.markAsTouched();
        const userInput: number = this.extractAsNumberFrom('scale', true);
        if (input.valid && !isNaN(userInput)) {
            if (!this.oldInputValue) {
                this.oldInputValue = input.value;
            }
            this.updateSceneElementScale(userInput, object);
        }
        if (!isNaN(userInput)) {
            input.setValue(parseInt(userInput.toString()).toString());
        }
    }

    roundToTwoDecimalPlaces(axisValue: number): number {
        return Math.round((axisValue + Number.EPSILON) * 100) / 100;
    }

    enforceFixedPointNotation(num: number, decimalPlaces: number): string {
        return num.toFixed(decimalPlaces);
    }

    private updateSceneElementPosition(
        axis: string,
        newValue: number,
        object: THREE.Object3D
    ) {
        this.writePositionIntoThree(axis, newValue, object);
        this.writePositionIntoLoadedSceneElement(object);
    }

    private writePositionIntoThree(
        axis: string,
        newValue: number,
        object: THREE.Object3D
    ) {
        const robotPosition: Vector3 = object.position;
        let calcVal = this.numberTranslate.convertValueToMeter(newValue, 'cm');
        switch (axis) {
            case 'x':
                robotPosition.setX(calcVal);
                break;
            case 'y':
                robotPosition.setY(calcVal);
                break;
            case 'z':
                robotPosition.setZ(calcVal);
                break;
            default:
                throw new Error(`Illegal argument: ${axis}`);
        }
    }

    private writePositionIntoLoadedSceneElement(object: THREE.Object3D) {
        ['x', 'y', 'z'].forEach((key) => {
            const ensuredVal = this.numberTranslate.limitNumberToDecimalPlaces(
                object.position[key],
                4
            );
            this.loadedSceneElement.state.position[key] = ensuredVal;
        });
    }

    private updateSceneElementScale(newValue: number, object: THREE.Object3D) {
        this.writeScaleIntoThree(newValue, object);
        this.writeScaleIntoLoadedSceneElement(newValue);
    }

    private writeScaleIntoThree(newValue: number, object: THREE.Object3D) {
        let calcVal = newValue / 100;
        object.scale.set(calcVal, calcVal, calcVal);
    }

    private writeScaleIntoLoadedSceneElement(newValue: number) {
        this.loadedSceneElement.sceneElement.scale = newValue;
    }

    private updateRotation(object: THREE.Object3D) {
        this.applyRotationIntoThree(object);
        this.writeRotationIntoLoadedSceneElement();
    }

    private applyRotationIntoThree(object: THREE.Object3D) {
        const xrot: number = this.extractAsNumberFrom('xrot');
        const yrot: number = this.extractAsNumberFrom('yrot');
        const zrot: number = this.extractAsNumberFrom('zrot');
        if (!isNaN(xrot) && !isNaN(yrot) && !isNaN(zrot)) {
            object.quaternion.setFromEuler(
                new Euler(
                    degreesToRadians(xrot),
                    degreesToRadians(yrot),
                    degreesToRadians(zrot)
                )
            );
        }
    }

    private writeRotationIntoLoadedSceneElement() {
        this.loadedSceneElement.state.orientation.x =
            this.extractAsNumberFrom('xrot');
        this.loadedSceneElement.state.orientation.y =
            this.extractAsNumberFrom('yrot');
        this.loadedSceneElement.state.orientation.z =
            this.extractAsNumberFrom('zrot');
    }

    private onBlur(object: THREE.Object3D, axis: string) {
        // Display rounded value
        const input = this.selectedObjectForm.controls[axis];
        const exactValue: number = this.convertNumToCm(object.position[axis]);

        if (input.valid && !isNaN(exactValue)) {
            const roundedValue: number =
                this.roundToTwoDecimalPlaces(exactValue);
            const withFixedPointNotation: string =
                this.enforceFixedPointNotation(roundedValue, 2);
            this.writeToForm(axis, withFixedPointNotation);
            this.sceneStateService.updateStateOfLoadedSceneElement(
                this.loadedSceneElement.state
            );
            this.oldInputValue = '';
        }
    }

    onBlurRot(formName: string, _axis: string) {
        // Display rounded value
        const input = this.selectedObjectForm.controls[formName];
        let preciseDegrees: number = this.extractAsNumberFrom(formName);
        if (input.valid && !isNaN(preciseDegrees)) {
            preciseDegrees = this.handleSpecialValueRotation(preciseDegrees);
            const roundedDegrees: number =
                this.roundToTwoDecimalPlaces(preciseDegrees);
            const withFixedPointNotation = this.enforceFixedPointNotation(
                roundedDegrees,
                1
            );
            this.writeToForm(formName, withFixedPointNotation);
            this.writeRotationIntoLoadedSceneElement();
            this.sceneStateService.updateStateOfLoadedSceneElement(
                this.loadedSceneElement.state
            );
            this.oldInputValue = '';
        }
    }

    onBlurScale() {
        const input = this.selectedObjectForm.controls['scale'];
        const preciseScale: number = this.extractAsNumberFrom('scale', true);
        if (input.valid && !isNaN(preciseScale)) {
            this.writeToForm('scale', preciseScale.toString());
            this.sceneStateService.updateSceneElementScale(
                this.loadedSceneElement.sceneElement
            );
            this.oldInputValue = '';
        }
    }

    handleSpecialValueRotation(degree: number) {
        if (degree < 0 && degree >= -360) {
            degree = 360 + degree;
        }

        if (degree === 360) {
            degree = 0;
        }

        return degree;
    }

    keepFocusOnInvalidInput(event, axis: string) {
        const inputElem = event.target;
        const input = this.selectedObjectForm.controls[axis];

        if (!!input.errors) {
            inputElem.focus();
        }
    }

    getErrorMessage(
        control: UntypedFormControl,
        min: number = environment.costFunction.position.min,
        max: number = environment.costFunction.position.max
    ) {
        if (control.hasError('invalidFormat')) {
            return this.translate.instant(
                'costFunction.costFunctionEditor.errors.numberRequired'
            );
        } else if (control.hasError('required')) {
            return this.translate.instant(
                'costFunction.costFunctionEditor.errors.numberRequired'
            );
        } else if (control.hasError('positiveFloatFormatError')) {
            return this.translate.instant(
                'algorithm.errors.positiveFloatFormatError'
            );
        } else if (control.hasError('invalidScaleRange')) {
            return this.translate.instant(
                'scenes.components.editScene.contextArea.selectedObject3-d.errors.invalidScaleRange'
            );
        }

        return control.hasError('invalidRange')
            ? this.translate.instant(
                  'costFunction.costFunctionEditor.errors.numberRange',
                  { min, max }
              )
            : '';
    }

    convertNumToCm(num: number): number {
        //expected that default number is meter!
        return this.numberTranslate.convertMeterToValue(num, 'cm');
    }
}
