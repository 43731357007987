import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ContainerType } from '../../../shared/services/container/container.types';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SplitScreenTab } from '../../model/split-screen-tabs';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TrainingStatus } from '../../../shared/models/training-status';
import { TrainingType } from '../../../shared/enums/training-type.enum';
import { isNil } from '../../../shared/utility';
import { TrainingService } from '../../../shared/services/training.service';
import { TrainingDTO } from '../../../shared/models/training-dto';
import { fadeIn, fadeOut } from '../../../shared/animations';

@Component({
    selector: 'app-project-split-screen-tabs',
    templateUrl: './project-split-screen-tabs.component.html',
    styleUrls: ['./project-split-screen-tabs.component.scss'],
    animations: [fadeIn, fadeOut],
})
export class ProjectSplitScreenTabsComponent implements OnInit, OnDestroy {
    protected readonly SplitScreenTab = SplitScreenTab;
    protected readonly TrainingStatus = TrainingStatus;

    @Input() containerId: string;
    @Input() containerType: ContainerType;

    training: TrainingDTO;
    trainingId: string;
    trainingName: string;
    trainingStatus: TrainingStatus;
    trainingType: TrainingType;

    tabs: SplitScreenTab[];
    selectedTab: SplitScreenTab;

    $destroy: Subject<void> = new Subject();
    trainingLoaded: boolean = false;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private trainingService: TrainingService
    ) {}

    ngOnInit(): void {
        this.tabs = this.buildTabsBasedOnContainerType();

        this.activatedRoute.queryParamMap
            .pipe(takeUntil(this.$destroy))
            .subscribe((params: ParamMap) => {
                const trainingId: string = params.get('trainingUuid');
                if (isNil(trainingId) || trainingId === '') {
                    return;
                }

                if (this.trainingId !== trainingId) {
                    this.trainingLoaded = false;
                    this.trainingId = trainingId;
                    this.fetchTraining();
                }

                const urlSelectedTab: string = params.get('selectedTab');
                if (isNil(urlSelectedTab)) {
                    this.onTabClick(this.selectedTab);
                } else {
                    const selectedTab: SplitScreenTab =
                        urlSelectedTab as SplitScreenTab;

                    this.selectedTab = this.tabs.includes(selectedTab)
                        ? selectedTab
                        : undefined;
                }
            });
    }

    private fetchTraining() {
        this.trainingService
            .get(this.trainingId)
            .pipe(take(1))
            .subscribe((training: TrainingDTO) => {
                this.training = training;
                this.trainingName = training.name;
                this.trainingStatus = training.status;
                this.trainingType = training.trainingType;
                this.trainingLoaded = true;
            });
    }

    onTabClick(selectedTab: SplitScreenTab) {
        if (this.tabs.includes(selectedTab)) {
            this.router.navigate([], {
                skipLocationChange: false,
                relativeTo: this.activatedRoute,
                queryParams: {
                    selectedTab: selectedTab,
                },
                queryParamsHandling: 'merge',
            });
        }
    }

    getTranslationKey(selectedTab: SplitScreenTab) {
        if (this.tabs.includes(selectedTab)) {
            return `projectSplitScreen.components.tabs.${selectedTab}`;
        }
        return null;
    }

    ngOnDestroy(): void {
        this.$destroy.complete();
    }

    private buildTabsBasedOnContainerType(): SplitScreenTab[] {
        let defaultTabs: SplitScreenTab[] = [
            SplitScreenTab.ALGORITHM,
            SplitScreenTab.NETWORK_ARCHITECTURE,
            SplitScreenTab.EVALUATION,
        ];
        switch (this.containerType) {
            case ContainerType.PROJECT_VISUAL:
                defaultTabs = [SplitScreenTab.IMAGE_DB].concat(defaultTabs);
                this.selectedTab = SplitScreenTab.IMAGE_DB;
                return defaultTabs;
            case ContainerType.PROJECT_MOTORIC:
                defaultTabs = [
                    SplitScreenTab.SCENE,
                    SplitScreenTab.COST_FUNCTION,
                ].concat(defaultTabs);
                this.selectedTab = SplitScreenTab.SCENE;
                return defaultTabs;
            default:
                return [];
        }
    }
}
