import { Component, Input, OnInit } from '@angular/core';
import { TrainingType } from 'src/app/shared/enums/training-type.enum';
import { TrainingDTO } from 'src/app/shared/models/training-dto';
import { TrainingStatus } from 'src/app/shared/models/training-status';

@Component({
    selector: 'app-neural-network',
    templateUrl: './neural-network.component.html',
    styleUrls: ['./neural-network.component.scss'],
})
export class NeuralNetworkComponent implements OnInit {
    @Input() training: TrainingDTO;

    selectedIndex: number = 0;
    editingIsDisabled: boolean = false;

    ngOnInit(): void {
        this.editingIsDisabled = this.training.status !== TrainingStatus.NEW;
        if (this.training.trainingType === TrainingType.VISUAL) {
            this.selectedIndex = 1;
        }
    }

    changeSelection(selectedIndex: number) {
        this.selectedIndex = selectedIndex;
    }
}
