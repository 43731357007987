import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Pageable, httpByPageable } from '../../models/pageable';
import { UserInfo } from '../../models/user-info';
import {
    Container,
    ContainerCreateRequest,
    ContainerCreateResponse,
    ContainerMembership,
    ContainerPage,
    ContainerType,
    JoinRequestCreateResponse,
} from './container.types';

@Injectable({ providedIn: 'root' })
export class ContainerService {
    private baseUrl: string;

    constructor(private http: HttpClient) {
        this.baseUrl = environment.backendUrl + '/rest/containers';
    }

    create(
        newContainer: ContainerCreateRequest
    ): Observable<ContainerCreateResponse> {
        return this.http.post<ContainerCreateResponse>(
            this.baseUrl,
            newContainer
        );
    }

    update(
        containerId: string,
        containerRequest: ContainerCreateRequest
    ): Observable<Container> {
        const url = `${this.baseUrl}/${containerId}`;
        return this.http.patch<Container>(url, containerRequest);
    }

    savePicture(containerId: string, picture: File): Observable<void> {
        const url = `${this.baseUrl}/${containerId}/picture`;
        //TODO: clarify what do we return, locationUrl or more?
        return this.http.post<void>(url, picture);
    }

    getById(containerId: string): Observable<Container> {
        const url = `${this.baseUrl}/${containerId}`;
        return this.http.get<Container>(url);
    }

    delete(containerId: string): Observable<unknown> {
        const url = `${this.baseUrl}/${containerId}`;
        return this.http.delete(url);
    }

    getAllUserContainers(
        types: ContainerType[],
        pageable: Pageable
    ): Observable<ContainerPage> {
        return this.fetchContainerFiltered(types, pageable, '/own');
    }

    getAllCommunityContainers(
        types: ContainerType[],
        pageable: Pageable
    ): Observable<ContainerPage> {
        return this.fetchContainerFiltered(types, pageable);
    }

    private fetchContainerFiltered(
        types: ContainerType[],
        pageable: Pageable,
        urlSuffix?: string
    ): Observable<ContainerPage> {
        let httpParams = httpByPageable(new HttpParams(), pageable);
        if (!!types && types.length > 0) {
            httpParams = httpParams.set('containerTypes', types.join(','));
        }

        const url = urlSuffix ? this.baseUrl + urlSuffix : this.baseUrl;

        return this.http.get<ContainerPage>(url, {
            params: httpParams,
        });
    }

    //------------Membership
    getOwner(containerId: string): Observable<UserInfo> {
        const url = `${this.baseUrl}/${containerId}/memberships/owner`;
        return this.http.get<UserInfo>(url);
    }

    getMyMembership(containerId: string): Observable<ContainerMembership> {
        const url = `${this.baseUrl}/${containerId}/memberships/single`;
        return this.http.get<ContainerMembership>(url);
    }

    //------------JoinRequest
    createJoinRequest(containerId: string) {
        const url = `${this.baseUrl}/${containerId}/requests`;
        return this.http.post<JoinRequestCreateResponse>(url, null);
    }
}
