<div class="img-db">
    <app-header-box
        *ngIf="!isDbFromTraining"
        [name]="getBackButtonName()"
    ></app-header-box>
    <div
        class="img-db-content"
        *ngIf="imageDb"
        [ngClass]="{ 'not-in-split-screen': !isDbFromTraining }"
    >
        <div class="central">
            <div class="header">
                <div class="tabs-wrapper">
                    <ul class="tab-list">
                        <li
                            class="tab-item"
                            *ngFor="let item of navItems; index as idx"
                            [id]="item + '-navigation-tab'"
                            [class.active]="isViewActive(idx)"
                            (click)="onNavClick(idx)"
                            (mouseleave)="hoveredNavItemIdx = null"
                            (mouseenter)="hoveredNavItemIdx = idx"
                        >
                            <img
                                [src]="
                                    'assets/svg/img-db/header/manage-buttons/' +
                                    (isViewActive(idx) ||
                                    hoveredNavItemIdx === idx
                                        ? item + '-hover'
                                        : item) +
                                    '.svg'
                                "
                                alt="{{ item }}-icon"
                            />
                            <span
                                id="{{ item }}-button"
                                class="tab-text"
                                [attr.aria-current]="isViewActive(idx)"
                                >{{
                                    'imgDb.header.tabs.' + item | translate
                                }}</span
                            >
                        </li>
                    </ul>
                    <div class="separation-line"></div>
                </div>
                <div
                    class="functional-buttons-wrapper"
                    [ngClass]="{
                        split: splitScreenState === SplitScreenState.DEFAULT
                    }"
                >
                    <div
                        class="upload-sort-buttons-container"
                        *ngIf="activeViewIdx === 0"
                    >
                        <label
                            *ngIf="activeViewIdx === 0"
                            for="open_file_dialog"
                            class="upload_label"
                            (mouseenter)="hoveredFunctionalButtonIdx = 0"
                            (mouseleave)="hoveredFunctionalButtonIdx = null"
                        >
                            <div class="upload-button">
                                <img
                                    [src]="
                                        'assets/svg/img-db/header/functional-buttons/upload' +
                                        (hoveredFunctionalButtonIdx === 0
                                            ? '-hover'
                                            : '') +
                                        '.svg'
                                    "
                                    alt="upload-icon"
                                />
                                <span>
                                    {{
                                        'imgDb.header.functionalButtons.upload'
                                            | translate
                                    }}
                                </span>
                            </div>
                        </label>
                    </div>
                    <div
                        *ngIf="activeViewIdx === 1"
                        class="filter-section"
                        [title]="getFiltersString()"
                    >
                        <b class="filter-caption">{{
                            'imgDb.imgLabeling.filter' | translate
                        }}</b>
                        <span class="filter-list">{{
                            getFiltersString()
                        }}</span>
                    </div>
                </div>
            </div>
            <div class="data">
                <div class="central-content-wrapper">
                    <ngx-file-drop
                        *ngIf="activeViewIdx === 0"
                        (onFileDrop)="onItemDropped($event)"
                        [accept]="getValidImageFormats()"
                    >
                        <ng-template
                            ngx-file-drop-content-tmp
                            let-openFileSelector="openFileSelector"
                        >
                            <app-img-db-gallery
                                *ngIf="
                                    activeViewIdx === 0 &&
                                    imagesStorage.isStorageReady
                                "
                                (selectButtonStatusChanged)="
                                    changeSelectButtonMode($event)
                                "
                            ></app-img-db-gallery>
                            <button
                                id="open_file_dialog"
                                (click)="openFileSelector()"
                                class="d-none"
                            ></button>
                        </ng-template>
                    </ngx-file-drop>
                    <app-img-labeling-viewer
                        *ngIf="activeViewIdx === 1"
                        [selectedCategory]="selectedEditCategory"
                        [categories]="categories"
                        (zoomLevelChanged)="onZoomLevelChanged($event)"
                        [splitScreenState]="splitScreenState"
                    ></app-img-labeling-viewer>
                </div>
            </div>
            <div class="footer-wrapper">
                <app-footer
                    *ngIf="lengthOfImagesList"
                    [ngClass]="{
                        'zoom-out-disabled':
                            currentZoomLevel === currentMinZoom,
                        'zoom-in-disabled': currentZoomLevel === currentMaxZoom
                    }"
                    [type]="activeViewIdx === 0 ? 'manage' : 'label'"
                    (zoomEmitter)="handleZoom($event)"
                ></app-footer>
            </div>
        </div>
        <div class="right">
            <div class="data">
                <div
                    class="side-content-wrapper"
                    [ngClass]="{
                        'in-manage-dialog': activeViewIdx === 0,
                        'in-annotate-dialog': activeViewIdx === 1
                    }"
                >
                    <div
                        class="manage-dialog-right-container"
                        *ngIf="activeViewIdx === 0"
                    >
                        <div class="category-manager-component-wrapper">
                            <app-img-db-category-manager
                                [isDbOfTraining]="isDbFromTraining"
                                [imageDatabaseId]="imageDb.id"
                                [categories]="categories"
                                [selectedCategoryNames]="selectedCategoryNames"
                                [numberOfUncategorizedImages]="
                                    uncategorizedImagesCount
                                "
                                [isEmptyCategorySelected]="
                                    isEmptyCategorySelected
                                "
                                [mode]="
                                    activeViewIdx === 0
                                        ? 'overview'
                                        : 'annotate'
                                "
                                [selectedSorting]="categoriesSortingKey.option"
                                [fetchOnInit]="fetchCategoriesOnViewIndexChange"
                                (actionTaken)="
                                    handleCategoryManagerAction($event)
                                "
                            >
                            </app-img-db-category-manager>
                        </div>
                    </div>
                    <div
                        class="annotate-dialog-right-container"
                        *ngIf="activeViewIdx === 1"
                    >
                        <div class="classificator-tree-component-wrapper">
                            <app-classificator-tree
                                *ngIf="activeViewIdx === 1"
                                [categories]="categories"
                                (categorySelected)="
                                    handleCategorySelect($event)
                                "
                                (categoryDeleted)="handleCategoryDelete($event)"
                            ></app-classificator-tree>
                        </div>
                        <div class="categories-right-component-wrapper">
                            <app-img-db-category-manager
                                [isDbOfTraining]="isDbFromTraining"
                                [imageDatabaseId]="imageDb.id"
                                [categories]="categories"
                                [selectedCategoryNames]="selectedCategoryNames"
                                [numberOfUncategorizedImages]="
                                    uncategorizedImagesCount
                                "
                                [isEmptyCategorySelected]="
                                    isEmptyCategorySelected
                                "
                                [mode]="
                                    activeViewIdx === 0
                                        ? 'overview'
                                        : 'annotate'
                                "
                                [selectedSorting]="categoriesSortingKey.option"
                                [fetchOnInit]="fetchCategoriesOnViewIndexChange"
                                (actionTaken)="
                                    handleCategoryManagerAction($event)
                                "
                            >
                            </app-img-db-category-manager>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
