import {
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { SceneVisualService } from '../../../services/scene-visual.service';
import { URDFJoint, URDFMimicJoint } from 'urdf-loader';
import {
    convertDegreeToRad,
    convertRadToDegree,
    NumberTranslateService,
    roundTo,
} from '../../../../shared/services/number-translate.service';
import { Subscription } from 'rxjs';
import { JointType } from '../../../../shared/enums/joint-type.enum';

@Component({
    selector: 'app-context-area',
    templateUrl: './context-area.component.html',
    styleUrls: ['./context-area.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ContextAreaComponent implements OnInit, OnDestroy {
    @Input() trainingId: string;
    @Input() editingIsDisabled: boolean;
    selectedObjectExpanded = false;
    isFormInvalid: boolean = false;

    selectedJoint: URDFJoint;
    jointValue: number;
    selectedJointSub: Subscription;
    timer: any;
    delayCallBack: number = 800;

    constructor(
        public sceneVisualService: SceneVisualService,
        private changeRef: ChangeDetectorRef,
        private numberTranslateService: NumberTranslateService
    ) {}

    ngOnInit(): void {
        this.sceneVisualService.isArrangementFormValid.subscribe((value) => {
            this.isFormInvalid = !value;
            this.changeRef.detectChanges();
        });

        this.selectedJointSub = this.sceneVisualService.selectedJoint.subscribe(
            (joint) => {
                this.selectedJoint = joint;
                if (!joint) {
                    this.jointValue = null;
                } else if (joint.jointType === JointType.REVOLUTE) {
                    this.jointValue = convertRadToDegree(
                        joint.jointValue[0].valueOf()
                    );
                } else if (joint.jointType === JointType.PRISMATIC) {
                    this.jointValue =
                        this.numberTranslateService.convertMeterToValue(
                            joint.jointValue[0].valueOf(),
                            'cm'
                        );
                } else {
                    this.jointValue = joint.jointValue[0].valueOf();
                }
            }
        );
    }

    objectExpandAction(action: boolean) {
        this.selectedObjectExpanded = action;
    }

    drop(event) {
        console.log('Drop Event in Context-Area: ', event);
    }

    toggleValidity(isValid: boolean) {
        this.sceneVisualService.isArrangementFormValid.next(isValid);
    }

    get convertedJointLimits() {
        if (this.selectedJoint) {
            let lowerVal: number;
            let upperVal: number;
            let limit = this.selectedJoint.limit;
            switch (this.selectedJoint.jointType) {
                case JointType.REVOLUTE: {
                    lowerVal = convertRadToDegree(limit.lower.valueOf());
                    upperVal = convertRadToDegree(limit.upper?.valueOf());
                    break;
                }
                case JointType.PRISMATIC: {
                    lowerVal = this.numberTranslateService.convertMeterToValue(
                        limit.lower.valueOf(),
                        'cm'
                    );
                    upperVal = this.numberTranslateService.convertMeterToValue(
                        limit.upper?.valueOf(),
                        'cm'
                    );
                    break;
                }
                case JointType.CONTINUOUS: {
                    lowerVal = 0;
                    upperVal = 0;
                    break;
                }
                default: {
                    throw Error(
                        `Joint Type ${this.selectedJoint.jointType} of selectedJoint is not supported!`
                    );
                }
            }
            return { lower: roundTo(lowerVal, 1), upper: roundTo(upperVal, 1) };
        }
    }

    get isJointSelected() {
        return !!this.selectedJoint;
    }

    ngOnDestroy(): void {
        if (this.selectedJointSub) {
            this.selectedJointSub.unsubscribe();
        }
    }

    onValueChanged(newValue: number) {
        let jointValue = newValue;
        if (this.selectedJoint.jointType === JointType.REVOLUTE) {
            jointValue = convertDegreeToRad(jointValue);
        } else if (this.selectedJoint.jointType === JointType.PRISMATIC) {
            jointValue = this.numberTranslateService.convertValueToMeter(
                jointValue,
                'cm'
            );
        }

        this.selectedJoint.setJointValue(jointValue);
        this.selectedJoint.mimicJoints.forEach((mimicJoint: URDFMimicJoint) => {
            mimicJoint.setJointValue(
                jointValue * mimicJoint.multiplier.valueOf() +
                    mimicJoint.offset.valueOf()
            );
        });
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.sceneVisualService.saveCurrentState();
        }, this.delayCallBack);
    }
}
