import { Injectable } from '@angular/core';
import {
    BehaviorSubject,
    forkJoin,
    Observable,
    ReplaySubject,
    Subject,
    take,
} from 'rxjs';
import { UserAuth } from '../profile/models/user-profile';
import { UserService } from './user.service';
import { IdmOption } from '../profile/models/idm-option';
import { LoginRedirectionService } from './login-redirection.service';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    private isLoggedInSubject$: BehaviorSubject<boolean> = new BehaviorSubject(
        false
    );
    public logoutPreparationsFinished$: Subject<boolean> = new Subject();

    private currentUser$: BehaviorSubject<UserAuth>;
    public isLoggedIn$: Observable<boolean> =
        this.isLoggedInSubject$.asObservable();
    public logoutTriggered$ = new Subject();
    public initializationFinished$ = new ReplaySubject<boolean>();

    constructor(
        private userService: UserService,
        private redirectionService: LoginRedirectionService
    ) {
        forkJoin([userService.getMe(), userService.getIdmOption()]).subscribe(
            ([userAuth, idmOption]: [UserAuth, IdmOption]) => {
                this.currentUser$ = new BehaviorSubject(userAuth);
                redirectionService.setIdmOptions(idmOption);
                this.isLoggedInSubject$.next(!!userAuth.id);

                if (this.isLoggedIn()) {
                    userService.getProfilePicture(userAuth.id);
                }
                this.initializationFinished$.next(true);
            }
        );
    }

    signOut(): void {
        if (this.logoutTriggered$.observed) {
            this.logoutPreparationsFinished$.pipe(take(1)).subscribe(() => {
                this.sendLogoutRequest();
            });
        }
        this.logoutTriggered$.next(true);
        this.currentUser$.next({} as UserAuth);
        this.isLoggedInSubject$.next(false);
        if (!this.logoutTriggered$.observed) {
            this.sendLogoutRequest();
        }
        this.userService.deleteProfilePicture();
    }

    private sendLogoutRequest() {
        this.userService.logoutFromIdm().subscribe({
            next: (resp) => {
                const logoutUri = resp.headers.get('Location');
                if (!!logoutUri) {
                    this.redirect(logoutUri);
                } else {
                    this.redirect('/');
                }
            },
            error: () => {
                this.redirect('/');
            },
        });
    }
    private redirect(url: string) {
        window.location.href = url;
    }

    public observableUserAuth(): Observable<UserAuth> {
        return this.currentUser$.asObservable();
    }

    public saveUser(user: UserAuth): void {
        this.currentUser$.next(user);
        this.isLoggedInSubject$.next(!!user.id);

        if (!!user.id) {
            this.userService.getProfilePicture(user.id);
        }
    }

    public get currentUserValue(): UserAuth {
        return this.currentUser$?.value;
    }

    public isLoggedIn(): boolean {
        return this.isLoggedInSubject$.value;
    }

    public isAdmin(): boolean {
        return !!this.currentUserValue.roles.includes('ROLE_CONFIG_ADMIN');
    }
}
