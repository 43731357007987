import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { UserCacheService } from 'src/app/xtra/user-cache/user-cache-service';
import { ExampleMotion } from '../../../models/example-motion';
import { SceneDiversity } from '../../../models/scene-diversity';
import { SceneDiversitySelection } from '../../../models/scene-diversity-selection';
import { SceneStateService } from '../../../services/scene-state.service';
import { SceneVisualService } from '../../../services/scene-visual.service';

@Component({
    selector: 'app-scene-diversity',
    templateUrl: './scene-diversity.component.html',
    styleUrls: [
        './scene-diversity.component.scss',
        '../../../styles/scrollable-container.scss',
    ],
})
export class SceneDiversityComponent implements OnInit, OnDestroy {
    @Input() trainingId: string;
    @Input() editingIsDisabled: boolean = false;

    diversity: SceneDiversity;
    sceneDiversitySelection: SceneDiversitySelection;

    statusCache = new StatusCache();
    statusCachePath: string;

    routeSub = this.route.params.subscribe((params: Params) => {
        const path = `scene-example-motion-${params.id}`;
        this.setStatusCachePath(path);
    });

    constructor(
        private route: ActivatedRoute,
        private sceneStateService: SceneStateService,
        private sceneVisualService: SceneVisualService,
        private userCacheService: UserCacheService
    ) {}

    ngOnInit(): void {
        this.sceneStateService.getSceneDiversity().subscribe((diversity) => {
            this.diversity = diversity;
        });

        this.sceneStateService.getDiversitySelection$.subscribe((selection) => {
            this.sceneDiversitySelection = selection;
        });
    }

    ngOnDestroy(): void {
        this.routeSub.unsubscribe();

        this.saveSelectedExampleMotion();
    }

    selectSceneDiversity(base: boolean, exampleMotion?: ExampleMotion) {
        if (this.sceneVisualService.isArrangementFormValid.value) {
            this.sceneStateService.changeDiversitySelection({
                base: base ? this.diversity.base : undefined,
                exampleMotion: exampleMotion,
            });
            this.statusCache.selectedExampleMotionId = exampleMotion
                ? exampleMotion.id
                : null;
        }
    }

    createNewExampleMotion(selector: SceneDiversitySelection) {
        this.sceneStateService.createNewExampleMotion(
            selector,
            this.trainingId
        );
    }

    deleteExampleMotion(exampleMotion: ExampleMotion) {
        if (
            exampleMotion.id === this.sceneDiversitySelection.exampleMotion?.id
        ) {
            this.selectSceneDiversity(true);
        }
        this.sceneStateService.deleteExampleMotion(
            exampleMotion.id,
            this.trainingId
        );
    }

    saveSelectedExampleMotion() {
        const saveDataSub = this.userCacheService
            .saveData(this.statusCachePath, this.statusCache)
            .subscribe(() => saveDataSub.unsubscribe());
    }

    private setStatusCachePath(path: string) {
        this.statusCachePath = path;
        const loadedStatusSub = this.userCacheService
            .loadData(this.statusCachePath)
            .subscribe((data) => {
                if (
                    data &&
                    data.selectedExampleMotionId &&
                    this.sceneStateService.scene$.value.exampleMotions.find(
                        (initialState) =>
                            initialState.id === data.selectedExampleMotionId
                    )
                ) {
                    this.statusCache = <StatusCache>data;
                    this.sceneStateService.selectExampleMotion(
                        data.selectedExampleMotionId
                    );
                } else {
                    this.sceneStateService.changeDiversitySelection({
                        base: this.sceneStateService.scene$.value.base,
                        exampleMotion: undefined,
                    });
                }
                this.sceneStateService.scene$.next(
                    this.sceneStateService.scene$.value
                );
                loadedStatusSub.unsubscribe();
            });
    }
}

/**Internal Cache Class*/
class StatusCache {
    selectedExampleMotionId: number;
}
