<div *ngIf="displayForm">
    <mat-card class="example-card">
        <div
            #selectedObjectContainer
            class="
                scrollable-container scrollable-container-align-right
                selected-object-container
            "
            [ngClass]="{ 'has-scrollbar': hasScrollbar }"
        >
            <mat-card-header></mat-card-header>
            <h6>
                {{
                    'scenes.components.editScene.contextArea.selectedObject3-d.position'
                        | translate
                }}
            </h6>
            <form [formGroup]="selectedObjectForm">
                <div class="row">
                    <mat-form-field appearance="none">
                        <div class="row">
                            <mat-label class="input-label"
                                >{{
                                    'scenes.components.editScene.contextArea.selectedObject3-d.positionx'
                                        | translate
                                }}
                            </mat-label>

                            <div class="input-wrapper">
                                <input
                                    id="x"
                                    type="text"
                                    matInput
                                    formControlName="x"
                                    placeholder="0,00"
                                    (input)="onEventWrapped('onInput', 'x')"
                                    (blur)="onEventWrapped('onBlur', 'x')"
                                    (focus)="onEventWrapped('onFocus', 'x')"
                                    (focusout)="
                                        keepFocusOnInvalidInput($event, 'x')
                                    "
                                    (keyup.enter)="blurInput($event)"
                                    (keyup.escape)="
                                        onCancelWrapped(
                                            'onCancelInput',
                                            'x',
                                            $event
                                        )
                                    "
                                    [ngClass]="{
                                        invalid:
                                            selectedObjectForm.get('x').invalid
                                    }"
                                />
                                <app-input-steppers
                                    [value]="selectedObjectForm.get('x').value"
                                    (changeEmitter)="
                                        updateValueWithStepper(
                                            $event,
                                            'x',
                                            'onInput'
                                        )
                                    "
                                ></app-input-steppers>
                                <p
                                    class="error"
                                    *ngIf="selectedObjectForm.get('x').invalid"
                                >
                                    {{
                                        getErrorMessage(
                                            selectedObjectForm.get('x')
                                        )
                                    }}
                                </p>
                            </div>
                        </div>
                    </mat-form-field>

                    <mat-form-field appearance="none">
                        <div class="row">
                            <mat-label class="input-label"
                                >{{
                                    'scenes.components.editScene.contextArea.selectedObject3-d.positiony'
                                        | translate
                                }}
                            </mat-label>
                            <div class="input-wrapper">
                                <input
                                    matInput
                                    type="text"
                                    formControlName="y"
                                    placeholder="0,00"
                                    (input)="onEventWrapped('onInput', 'y')"
                                    (blur)="onEventWrapped('onBlur', 'y')"
                                    (focus)="onEventWrapped('onFocus', 'y')"
                                    (focusout)="
                                        keepFocusOnInvalidInput($event, 'y')
                                    "
                                    (keyup.enter)="blurInput($event)"
                                    (keyup.escape)="
                                        onCancelWrapped(
                                            'onCancelInput',
                                            'y',
                                            $event
                                        )
                                    "
                                    [ngClass]="{
                                        invalid:
                                            selectedObjectForm.get('y').invalid
                                    }"
                                />
                                <app-input-steppers
                                    [value]="selectedObjectForm.get('y').value"
                                    (changeEmitter)="
                                        updateValueWithStepper(
                                            $event,
                                            'y',
                                            'onInput'
                                        )
                                    "
                                ></app-input-steppers>
                                <p
                                    class="error"
                                    *ngIf="selectedObjectForm.get('y').invalid"
                                >
                                    {{
                                        getErrorMessage(
                                            selectedObjectForm.get('y')
                                        )
                                    }}
                                </p>
                            </div>
                        </div>
                    </mat-form-field>
                    <mat-form-field appearance="none">
                        <div class="row">
                            <mat-label class="input-label"
                                >{{
                                    'scenes.components.editScene.contextArea.selectedObject3-d.positionz'
                                        | translate
                                }}
                            </mat-label>

                            <div class="input-wrapper">
                                <input
                                    matInput
                                    type="text"
                                    formControlName="z"
                                    placeholder="0,00"
                                    (input)="onEventWrapped('onInput', 'z')"
                                    (blur)="onEventWrapped('onBlur', 'z')"
                                    (focus)="onEventWrapped('onFocus', 'z')"
                                    (focusout)="
                                        keepFocusOnInvalidInput($event, 'z')
                                    "
                                    (keyup.enter)="blurInput($event)"
                                    (keyup.escape)="
                                        onCancelWrapped(
                                            'onCancelInput',
                                            'z',
                                            $event
                                        )
                                    "
                                    [ngClass]="{
                                        invalid:
                                            selectedObjectForm.get('z').invalid
                                    }"
                                />
                                <app-input-steppers
                                    [value]="selectedObjectForm.get('z').value"
                                    (changeEmitter)="
                                        updateValueWithStepper(
                                            $event,
                                            'z',
                                            'onInput'
                                        )
                                    "
                                ></app-input-steppers>
                                <p
                                    class="error"
                                    *ngIf="selectedObjectForm.get('z').invalid"
                                >
                                    {{
                                        getErrorMessage(
                                            selectedObjectForm.get('z')
                                        )
                                    }}
                                </p>
                            </div>
                        </div>
                    </mat-form-field>
                </div>
                <h6>
                    {{
                        'scenes.components.editScene.contextArea.selectedObject3-d.orientation'
                            | translate
                    }}
                </h6>
                <div class="row">
                    <mat-form-field appearance="none">
                        <div class="row">
                            <mat-label class="input-label"
                                >{{
                                    'scenes.components.editScene.contextArea.selectedObject3-d.RXAxis'
                                        | translate
                                }}
                            </mat-label>
                            <div class="input-wrapper">
                                <input
                                    id="xrot"
                                    type="text"
                                    matInput
                                    formControlName="xrot"
                                    placeholder="0"
                                    (input)="
                                        onEventWrapped(
                                            'onInputRot',
                                            'xrot',
                                            'xrot'
                                        )
                                    "
                                    (blur)="onBlurRot('xrot', 'x')"
                                    (focusout)="
                                        keepFocusOnInvalidInput($event, 'xrot')
                                    "
                                    (keyup.enter)="blurInput($event)"
                                    (keyup.escape)="
                                        onCancelWrapped(
                                            'onCancelInputRot',
                                            'xrot',
                                            $event
                                        )
                                    "
                                    [ngClass]="{
                                        invalid:
                                            selectedObjectForm.get('xrot')
                                                .invalid
                                    }"
                                />
                                <app-input-steppers
                                    [value]="
                                        selectedObjectForm.get('xrot').value
                                    "
                                    [step]="0.1"
                                    (changeEmitter)="
                                        updateValueWithStepper(
                                            $event,
                                            'xrot',
                                            'onInputRot'
                                        )
                                    "
                                ></app-input-steppers>
                                <p
                                    class="error"
                                    *ngIf="
                                        selectedObjectForm.get('xrot').invalid
                                    "
                                >
                                    {{
                                        getErrorMessage(
                                            selectedObjectForm.get('xrot'),
                                            -360,
                                            360
                                        )
                                    }}
                                </p>
                            </div>
                        </div>
                    </mat-form-field>
                    <mat-form-field appearance="none">
                        <div class="row">
                            <mat-label class="input-label"
                                >{{
                                    'scenes.components.editScene.contextArea.selectedObject3-d.YAxis'
                                        | translate
                                }}
                            </mat-label>
                            <div class="input-wrapper">
                                <input
                                    id="yrot"
                                    type="text"
                                    matInput
                                    formControlName="yrot"
                                    placeholder="0"
                                    (input)="
                                        onEventWrapped(
                                            'onInputRot',
                                            'yrot',
                                            'yrot'
                                        )
                                    "
                                    (blur)="onBlurRot('yrot', 'y')"
                                    (focusout)="
                                        keepFocusOnInvalidInput($event, 'yrot')
                                    "
                                    (keyup.enter)="blurInput($event)"
                                    (keyup.escape)="
                                        onCancelWrapped(
                                            'onCancelInputRot',
                                            'yrot',
                                            $event
                                        )
                                    "
                                    [ngClass]="{
                                        invalid:
                                            selectedObjectForm.get('yrot')
                                                .invalid
                                    }"
                                />
                                <app-input-steppers
                                    [value]="
                                        selectedObjectForm.get('yrot').value
                                    "
                                    [step]="0.1"
                                    (changeEmitter)="
                                        updateValueWithStepper(
                                            $event,
                                            'yrot',
                                            'onInputRot'
                                        )
                                    "
                                ></app-input-steppers>
                                <p
                                    class="error"
                                    *ngIf="
                                        selectedObjectForm.get('yrot').invalid
                                    "
                                >
                                    {{
                                        getErrorMessage(
                                            selectedObjectForm.get('yrot'),
                                            -360,
                                            360
                                        )
                                    }}
                                </p>
                            </div>
                        </div>
                    </mat-form-field>
                    <mat-form-field appearance="none">
                        <div class="row">
                            <mat-label class="input-label"
                                >{{
                                    'scenes.components.editScene.contextArea.selectedObject3-d.ZAxis'
                                        | translate
                                }}
                            </mat-label>
                            <div class="input-wrapper">
                                <input
                                    id="zrot"
                                    type="text"
                                    matInput
                                    formControlName="zrot"
                                    placeholder="0"
                                    (input)="
                                        onEventWrapped(
                                            'onInputRot',
                                            'zrot',
                                            'zrot'
                                        )
                                    "
                                    (blur)="onBlurRot('zrot', 'z')"
                                    (focusout)="
                                        keepFocusOnInvalidInput($event, 'zrot')
                                    "
                                    (keyup.enter)="blurInput($event)"
                                    (keyup.escape)="
                                        onCancelWrapped(
                                            'onCancelInputRot',
                                            'zrot',
                                            $event
                                        )
                                    "
                                    [ngClass]="{
                                        invalid:
                                            selectedObjectForm.get('zrot')
                                                .invalid
                                    }"
                                />
                                <app-input-steppers
                                    [value]="
                                        selectedObjectForm.get('zrot').value
                                    "
                                    [step]="0.1"
                                    (changeEmitter)="
                                        updateValueWithStepper(
                                            $event,
                                            'zrot',
                                            'onInputRot'
                                        )
                                    "
                                ></app-input-steppers>
                                <p
                                    class="error"
                                    *ngIf="
                                        selectedObjectForm.get('zrot').invalid
                                    "
                                >
                                    {{
                                        getErrorMessage(
                                            selectedObjectForm.get('zrot'),
                                            -360,
                                            360
                                        )
                                    }}
                                </p>
                            </div>
                        </div>
                    </mat-form-field>
                </div>
                <h6>
                    {{
                        'scenes.components.editScene.contextArea.selectedObject3-d.scale'
                            | translate
                    }}
                </h6>
                <div class="row">
                    <mat-form-field appearance="none">
                        <div class="row">
                            <div class="input-wrapper">
                                <input
                                    id="scale"
                                    type="text"
                                    matInput
                                    formControlName="scale"
                                    placeholder="0"
                                    (input)="
                                        onEventWrapped('onInputScale', 'scale')
                                    "
                                    (blur)="onBlurScale()"
                                    (focus)="onFocusScale()"
                                    (focusout)="
                                        keepFocusOnInvalidInput($event, 'scale')
                                    "
                                    (keyup.enter)="blurInput($event)"
                                    (keyup.escape)="
                                        onCancelWrapped(
                                            'onCancelInputScale',
                                            'scale',
                                            $event
                                        )
                                    "
                                    [ngClass]="{
                                        invalid:
                                            selectedObjectForm.get('scale')
                                                .invalid
                                    }"
                                />
                                <div *ngIf="disableScale" class="info-text">
                                    {{
                                        'scenes.components.editScene.contextArea.selectedObject3-d.scaleMouseover'
                                            | translate
                                    }}
                                </div>
                                <app-input-steppers
                                    [value]="
                                        selectedObjectForm.get('scale').value
                                    "
                                    [step]="1"
                                    (changeEmitter)="
                                        updateValueWithStepper(
                                            $event,
                                            'scale',
                                            'onInputScale'
                                        )
                                    "
                                ></app-input-steppers>
                                <p
                                    class="error"
                                    *ngIf="
                                        selectedObjectForm.get('scale').invalid
                                    "
                                >
                                    {{
                                        getErrorMessage(
                                            selectedObjectForm.get('scale')
                                        )
                                    }}
                                </p>
                            </div>
                        </div>
                    </mat-form-field>
                </div>
            </form>
        </div>
    </mat-card>
</div>
