<div class="container-fluid algorithm-container tryb-scroll">
    <div class="row" *ngIf="dataLoaded">
        <div class="algorithm-selector">
            <p class="mr-3 selector-title">
                {{ 'algorithm.appliedAlgorithm' | translate }}
            </p>
            <div class="d-flex align-items-center">
                <div class="tryb-dropdown">
                    <select
                        id="algorithm-dropdown"
                        [formControl]="type"
                        (change)="onTypeValueChange()"
                    >
                        <option
                            *ngFor="let algorithmType of algorithmTypes"
                            [value]="algorithmType"
                            [id]="algorithmType"
                        >
                            {{ algorithmType }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-12 algorithm-content">
            <div class="slide-toggle expert-toggle">
                <p class="me-3 slide-toggle-title">
                    {{ 'algorithm.advancedSettings' | translate }}
                </p>
                <mat-slide-toggle
                    [checked]="advancedSetting"
                    (change)="slideToggleChange($event)"
                    [disabled]="editingIsDisabled"
                ></mat-slide-toggle>
            </div>
            <div class="parameter-content">
                <ng-container *ngIf="type.value === AlgorithmType.PPO">
                    <app-ppo-parameters
                        [algorithmForm]="form"
                        [advancedSettingEnabled]="advancedSetting"
                        [editingIsDisabled]="editingIsDisabled"
                        (valueChange)="onValueChange()"
                    ></app-ppo-parameters>
                </ng-container>
                <ng-container *ngIf="type.value === AlgorithmType.ADAM">
                    <app-adam-parameters
                        [algorithmForm]="form"
                        [advancedSettingEnabled]="advancedSetting"
                        [editingIsDisabled]="editingIsDisabled"
                        (valueChange)="onValueChange()"
                    >
                    </app-adam-parameters>
                </ng-container>
                <ng-container *ngIf="type.value === AlgorithmType.SAC">
                    <app-sac-parameters
                        [algorithmForm]="form"
                        [advancedSettingEnabled]="advancedSetting"
                        [editingIsDisabled]="editingIsDisabled"
                        (valueChange)="onValueChange()"
                    >
                    </app-sac-parameters>
                </ng-container>
                <ng-container *ngIf="type.value === AlgorithmType.BC">
                    <app-bc-parameters
                        [algorithmForm]="form"
                        [advancedSettingEnabled]="advancedSetting"
                        [editingIsDisabled]="editingIsDisabled"
                        (valueChange)="onValueChange()"
                    ></app-bc-parameters>
                </ng-container>
                <ng-container
                    *ngIf="
                        type.value === AlgorithmType.GAIL ||
                        type.value === AlgorithmType.AIRL
                    "
                >
                    <app-airl-parameters
                        [algorithmForm]="form"
                        [advancedSettingEnabled]="advancedSetting"
                        [editingIsDisabled]="editingIsDisabled"
                        (valueChange)="onValueChange($event)"
                    ></app-airl-parameters>
                </ng-container>
                <ng-container
                    *ngIf="
                        training.trainingType === TrainingType.MOTORIC &&
                        [
                            AlgorithmType.BC,
                            AlgorithmType.GAIL,
                            AlgorithmType.AIRL
                        ].includes(type.value)
                    "
                >
                    <div class="spacer20"></div>
                    <app-expert-dataset
                        [trainingId]="trainingId"
                    ></app-expert-dataset>
                </ng-container>
            </div>
        </div>
    </div>
</div>
