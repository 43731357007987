<div class="context">
    <mat-accordion multi="false">
        <mat-expansion-panel
            [(expanded)]="selectedObjectExpanded"
            [ngClass]="{ 'hide-pointer-events': isFormInvalid }"
        >
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <a>{{
                        'scenes.components.editScene.contextArea.contextAreaComponent.objectProperties'
                            | translate
                    }}</a>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <app-selected-object3-d
                *ngIf="!isJointSelected"
                [editingIsDisabled]="editingIsDisabled"
                (expandArgumentsForm)="objectExpandAction($event)"
                #selectedObject3DComponent
            ></app-selected-object3-d>
            <div class="orientation-form" *ngIf="isJointSelected">
                <h6>
                    {{
                        (selectedJoint.jointType === 'revolute'
                            ? 'scenes.components.editScene.contextArea.selectedObject3-d.orientation'
                            : 'scenes.components.editScene.contextArea.selectedObject3-d.position'
                        ) | translate
                    }}
                </h6>
                <app-floating-number-input
                    [defaultValue]="jointValue"
                    shouldBeFocused="true"
                    selectValueOnFocus="true"
                    [lowerLimit]="convertedJointLimits.lower"
                    [upperLimit]="convertedJointLimits.upper"
                    showLimits="true"
                    [step]="0.1"
                    controlType="orientation"
                    (isControlValid)="toggleValidity($event)"
                    [decimalPlace]="1"
                    [errorTranslationKey]="
                        'shared.components.floatingNumberInput.orientation'
                    "
                    (valueChanged)="onValueChanged($event)"
                ></app-floating-number-input>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel
            [ngClass]="{ 'hide-pointer-events': isFormInvalid }"
        >
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <a>{{
                        'scenes.components.editScene.contextArea.contextAreaComponent.objectLibrary'
                            | translate
                    }}</a>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <app-object-library
                [trainingId]="trainingId"
                [editingIsDisabled]="editingIsDisabled"
            ></app-object-library>
        </mat-expansion-panel>
    </mat-accordion>
</div>
