import { NgModule } from '@angular/core';
import { CostFunctionComponent } from './components/cost-function/cost-function.component';
import { CostFunctionEditorComponent } from './components/cost-function-editor/cost-function-editor.component';
import { OrientationTabContentComponent } from './components/orientation-tab-content/orientation-tab-content.component';
import { CostFunctionInputGroupComponent } from './components/cost-function-input-group/cost-function-input-group.component';
import { DistanceTabContentComponent } from './components/distance-tab-content/distance-tab-content.component';
import { PositionTabContentComponent } from './components/position-tab-content/position-tab-content.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../material.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CustomCodeEditorComponent } from './components/custom-code-editor/custom-code-editor.component';
import { SceneModule } from '../scenes/scene.module';

@NgModule({
    declarations: [
        CostFunctionComponent,
        CostFunctionEditorComponent,
        CustomCodeEditorComponent,
        OrientationTabContentComponent,
        CostFunctionInputGroupComponent,
        DistanceTabContentComponent,
        PositionTabContentComponent,
    ],
    exports: [
        CostFunctionComponent,
        CostFunctionEditorComponent,
        CustomCodeEditorComponent,
        OrientationTabContentComponent,
        CostFunctionInputGroupComponent,
        DistanceTabContentComponent,
        PositionTabContentComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        MaterialModule,
        DragDropModule,
        SceneModule,
    ],
})
export class CostFunctionModule {}
