import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditSceneComponent } from './components/edit-scene/edit-scene.component';
import { InputSteppersComponent } from './components/edit-scene/context-area/input-steppers/input-steppers.component';
import { ObjectLibraryComponent } from './components/edit-scene/context-area/object-library/object-library.component';
import { SceneSettingsComponent } from './components/edit-scene/context-area/scene-settings/scene-settings.component';
import { SelectedObject3DComponent } from './components/edit-scene/context-area/selected-object3-d/selected-object3-d.component';
import { ContextAreaComponent } from './components/edit-scene/context-area/context-area.component';
import { SceneDiversityComponent } from './components/edit-scene/scene-diversity/scene-diversity.component';
import { JointTreeNodeComponent } from './components/joint-tree-node/joint-tree-node.component';
import { NodeTreeViewComponent } from './components/node-tree-view/node-tree-view.component';
import { SceneCompositionComponent } from './components/scene-composition/scene-composition.component';
import { SearchHighlightPipe } from './pipes/search-highlight.pipe';
import { SharedModule } from '../shared/shared.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
    declarations: [
        EditSceneComponent,
        ContextAreaComponent,
        ObjectLibraryComponent,
        SceneSettingsComponent,
        SelectedObject3DComponent,
        InputSteppersComponent,
        SceneDiversityComponent,
        JointTreeNodeComponent,
        NodeTreeViewComponent,
        SceneCompositionComponent,
        SearchHighlightPipe,
    ],
    imports: [
        BrowserModule,
        CommonModule,
        SharedModule,
        DragDropModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
    ],
    exports: [
        EditSceneComponent,
        ContextAreaComponent,
        ObjectLibraryComponent,
        SceneSettingsComponent,
        SelectedObject3DComponent,
        InputSteppersComponent,
        SceneDiversityComponent,
        JointTreeNodeComponent,
        NodeTreeViewComponent,
        SceneCompositionComponent,
        SearchHighlightPipe,
    ],
})
export class SceneModule {}
