<div class="header-wrapper">
    <div class="project-info-wrapper">
        <div class="image-wrapper">
            <img
                id="motoric-project-icon"
                src="assets/svg/project-split-screen/header/motoric-icon.svg"
                alt="motoric"
                *ngIf="containerType === ContainerType.PROJECT_MOTORIC"
            />
            <img
                id="visual-project-icon"
                src="assets/svg/project-split-screen/header/visual-icon.svg"
                alt="visual"
                *ngIf="containerType === ContainerType.PROJECT_VISUAL"
            />
        </div>
        <div class="project-name-wrapper">
            <span>{{ containerName }}</span>
        </div>
    </div>
    <div class="project-settings-wrapper" *ngIf="memberRole === 'OWNER'">
        <a
            [title]="
                'projectSplitScreen.components.header.membersHover' | translate
            "
            class="project-members"
            (click)="navigateToContainerMembers()"
        >
            <img
                id="members-icon"
                src="assets/svg/project-split-screen/header/members.svg"
                alt="member"
            />
        </a>
        <a
            [title]="
                'projectSplitScreen.components.header.settingsHover' | translate
            "
            class="project-settings"
            (click)="navigateToContainerSettings()"
        >
            <img
                id="settings-icon"
                src="assets/svg/project-split-screen/header/settings.svg"
                alt="settings"
            />
        </a>
    </div>
</div>
