<div class="tab-component-wrapper">
    <div class="training-name-wrapper">
        <span class="training-name">{{ trainingName }}</span>
    </div>
    <div class="tabs-wrapper">
        <div
            class="tab-element"
            [ngClass]="{ active: tab === selectedTab }"
            *ngFor="let tab of tabs"
            (click)="onTabClick(tab)"
            [id]="tab + '-tab'"
        >
            <span class="tab-element-circle"></span>
            <span class="tab-element-value"
                >{{ getTranslationKey(tab) | translate }}
            </span>
        </div>
    </div>
    <div class="tab-content" *ngIf="trainingLoaded" @fadeIn>
        <app-edit-scene
            *ngIf="selectedTab === SplitScreenTab.SCENE"
            [training]="training"
            @fadeIn
        ></app-edit-scene>
        <app-cost-function
            *ngIf="selectedTab === SplitScreenTab.COST_FUNCTION"
            [trainingId]="trainingId"
            @fadeIn
        ></app-cost-function>
        <app-algorithm
            *ngIf="selectedTab === SplitScreenTab.ALGORITHM"
            [training]="training"
            @fadeIn
        ></app-algorithm>
        <app-neural-network
            *ngIf="selectedTab === SplitScreenTab.NETWORK_ARCHITECTURE"
            [training]="training"
            @fadeIn
        ></app-neural-network>
        <app-evaluation
            *ngIf="selectedTab === SplitScreenTab.EVALUATION"
            [training]="training"
            @fadeIn
        ></app-evaluation>
        <app-img-db
            *ngIf="selectedTab === SplitScreenTab.IMAGE_DB"
            [training]="training"
            @fadeIn
        ></app-img-db>
        <div class="empty-selection" *ngIf="!selectedTab" @fadeIn>
            <div class="arrow"></div>
            <span class="text">
                {{
                    'projectSplitScreen.components.tabs.emptySelection'
                        | translate
                }}
            </span>
        </div>
    </div>
    <div class="spinner-wrapper" *ngIf="!trainingLoaded">
        <div class="tryb-design-spinner"></div>
    </div>
</div>
