<div class="basic-param-wrapper">
    <div class="basic-param-title-wrapper">
        <p class="basic-param-title mb-0">
            {{ 'algorithm.basicFormTitle' | translate }}
        </p>
    </div>
    <div class="row m-0 basic-param-content">
        <div class="col-4 col-xl-3 px-0 bg-color">
            <div class="col-content">
                <app-algorithm-input
                    inputLabel="{{ 'algorithm.batchSizeBC.label' | translate }}"
                    type="text"
                    [required]="true"
                    [hasSteppers]="true"
                    [control]="
                        algorithmForm.get(Scope.GENERAL).get('batchSize')
                    "
                    (valueChange)="onValueChange()"
                    [isFloatingPoint]="false"
                ></app-algorithm-input>
                <div class="spacer40"></div>
                <app-algorithm-input
                    inputLabel="{{
                        'algorithm.learningRate.label' | translate
                    }}"
                    type="text"
                    [required]="true"
                    [hasSteppers]="true"
                    [control]="
                        algorithmForm.get(Scope.OPTIMIZER).get('learningRate')
                    "
                    (valueChange)="onValueChange()"
                    [isFloatingPoint]="true"
                ></app-algorithm-input>
            </div>
        </div>

        <div class="col-4 col-xl-3 px-0 bg-color">
            <div class="col-content">
                <app-algorithm-input
                    inputLabel="{{ 'algorithm.nEpochs.label' | translate }}"
                    type="text"
                    [required]="true"
                    [control]="
                        algorithmForm.get(Scope.TRAINING_SETUP).get('nEpochs')
                    "
                    infoText="{{ 'algorithm.nEpochs.description' | translate }}"
                    [hasSteppers]="true"
                    [isFloatingPoint]="false"
                    (valueChange)="onValueChange()"
                ></app-algorithm-input>
                <div class="spacer40"></div>
                <app-algorithm-input
                    *ngIf="advancedSettingEnabled"
                    inputLabel="{{ 'algorithm.batchSize.label' | translate }}"
                    type="text"
                    [required]="false"
                    [hasSteppers]="true"
                    [control]="
                        algorithmForm.get(Scope.SPECIFIC).get('minibatchSize')
                    "
                    (valueChange)="onValueChange()"
                    [isFloatingPoint]="false"
                ></app-algorithm-input>
            </div>
        </div>

        <div
            class="col-4 col-xl-3 px-0 bg-color"
            *ngIf="advancedSettingEnabled"
        >
            <div class="col-content">
                <app-algorithm-input
                    inputLabel="{{ 'algorithm.entWeight.label' | translate }}"
                    type="text"
                    [required]="true"
                    [hasSteppers]="true"
                    [control]="
                        algorithmForm.get(Scope.SPECIFIC).get('entWeight')
                    "
                    (valueChange)="onValueChange()"
                    [isFloatingPoint]="true"
                ></app-algorithm-input>
                <div class="spacer40"></div>
                <app-algorithm-input
                    inputLabel="{{ 'algorithm.l2Weight.label' | translate }}"
                    type="text"
                    [required]="true"
                    [hasSteppers]="true"
                    [decimalPlaces]="7"
                    [control]="
                        algorithmForm.get(Scope.SPECIFIC).get('l2Weight')
                    "
                    (valueChange)="onValueChange()"
                    [isFloatingPoint]="true"
                ></app-algorithm-input>
            </div>
        </div>
        <div
            class="col-4 col-xl-3 px-0 bg-color"
            *ngIf="advancedSettingEnabled"
        >
            <div class="col-content">
                <app-algorithm-input
                    inputLabel="{{ 'algorithm.seed.label' | translate }}"
                    type="text"
                    [required]="false"
                    [hasSteppers]="true"
                    [control]="algorithmForm.get(Scope.GENERAL).get('seed')"
                    (valueChange)="onValueChange()"
                    [isFloatingPoint]="false"
                ></app-algorithm-input>
                <div class="spacer40"></div>
                <div class="tryb-input-container">
                    <label for="policy-select" class="input-label">
                        {{ 'algorithm.policy.label' | translate }}
                    </label>

                    <div
                        class="tryb-dropdown"
                        [ngClass]="{
                            disabled: algorithmForm
                                .get(Scope.SPECIFIC)
                                .get('policy').disabled
                        }"
                    >
                        <select
                            id="policy-select"
                            class="mr-1"
                            [formControl]="
                                algorithmForm.get(Scope.SPECIFIC).get('policy')
                            "
                            (change)="onValueChange()"
                            (keyup.enter)="blurInput($event)"
                            (keyup.escape)="blurInput($event)"
                        >
                            <option
                                *ngFor="let type of imitationPolicy"
                                [value]="type"
                            >
                                {{ type }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="spacer40"></div>
            </div>
        </div>
    </div>
</div>
