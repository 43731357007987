import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { ImagesStorageService } from '../services/images-storage.service';
import { Subscription } from 'rxjs';
import { PagingResult } from 'src/app/shared/models/pageable';
import { ImgWithSelection } from '../model/img-with-selection';

export type pagingType = 'manage' | 'label';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy, OnChanges {
    currentPage: number = 1;
    subscriptions: Subscription[] = [];
    totalPages: number;
    @Input() type: pagingType;

    @Output() zoomEmitter: EventEmitter<string> = new EventEmitter<string>();

    constructor(private storage: ImagesStorageService) {}

    ngOnInit(): void {
        if (this.type === 'manage') {
            this.subscriptions.push(
                this.storage.imagesPage$.subscribe(
                    (page: PagingResult<ImgWithSelection>) => {
                        if (page) {
                            this.totalPages = page.totalPages;
                            this.currentPage = this.storage.currentPage;
                        }
                    }
                )
            );
        } else if (this.type === 'label') {
            this.subscriptions.push(
                this.storage.imagesPage$.subscribe(
                    (page: PagingResult<ImgWithSelection>) => {
                        if (page) {
                            this.totalPages = page.totalElements;
                        }
                    }
                )
            );
            this.subscriptions.push(
                this.storage.selectedImage$.subscribe(
                    (image: ImgWithSelection) => {
                        if (image) {
                            this.currentPage =
                                (this.storage.currentPage - 1) *
                                    this.storage.pageSize +
                                this.storage.imagesPage$.value.values.findIndex(
                                    (img: ImgWithSelection) =>
                                        img.id === image.id
                                ) +
                                1;
                        }
                    }
                )
            );
            this.onNextValue(
                (this.storage.currentPage - 1) * this.storage.pageSize + 1
            );
        }
    }

    ngOnChanges(): void {
        this.ngOnDestroy();
        this.ngOnInit();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }

    onNextValue(numberProvidedFromPagingBar: number) {
        if (this.currentPage !== numberProvidedFromPagingBar) {
            if (this.type === 'manage') {
                this.currentPage = numberProvidedFromPagingBar;
                this.storage.changePage(this.currentPage);
                this.storage.getImages().subscribe();
            } else if (this.type === 'label') {
                this.currentPage = numberProvidedFromPagingBar;

                const indexOfImageToBeSelected =
                    this.findIndexOfImageToBeSelected(this.currentPage) - 1;

                const newPage = this.calculateNextPage(
                    numberProvidedFromPagingBar
                );
                if (newPage !== this.storage.currentPage) {
                    this.storage.changePage(newPage);
                    this.storage.getImages().subscribe(() => {
                        this.storage.updateImageSelection(
                            indexOfImageToBeSelected
                        );
                    });
                } else {
                    this.storage.updateImageSelection(indexOfImageToBeSelected);
                }
            }
        }
    }

    private calculateNextPage(val: number): number {
        return Math.ceil(val / this.storage.pageSize);
    }

    private findIndexOfImageToBeSelected(imageToGet: number): number {
        return imageToGet % this.storage.pageSize || this.storage.pageSize;
    }

    onZoomIn() {
        this.zoomEmitter.emit('in');
    }

    onZoomOut() {
        this.zoomEmitter.emit('out');
    }
}
