import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { UserProfile } from '../../authentication/profile/models/user-profile';
import { Subscription } from 'rxjs';
import { UserService } from '../../authentication/services/user.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
    isOnMainSite = false;
    isLoggedIn = false;
    showModeratorBoard = false;
    email: string;
    name: string;
    user: UserProfile;
    profilePicture: string = null;
    private profilePictureSubscription: Subscription;

    constructor(
        private authenticationService: AuthenticationService,
        private userService: UserService
    ) {
        this.authenticationService.isLoggedIn$.subscribe({
            next: (loggedIn) => {
                this.isLoggedIn = loggedIn;
            },
        });

        this.profilePictureSubscription =
            this.userService.profilePicture$.subscribe(
                (pic) => (this.profilePicture = pic)
            );
    }

    ngOnInit(): void {
        this.isOnMainSite = true;

        if (this.isLoggedIn) {
            this.isOnMainSite = false;
        }
    }

    ngOnDestroy(): void {
        this.profilePictureSubscription?.unsubscribe();
    }

    logout(): void {
        this.authenticationService.signOut();
        this.isLoggedIn = false;
        window.location.reload();
    }
}
