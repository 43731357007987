import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { MessageService } from 'src/app/message/services/message.service';
import { ProjectService } from 'src/app/project/services/project.service';
import { fadeIn, fadeOut } from 'src/app/shared/animations';
import {
    ContainerCreateRequest,
    ContainerCreateResponse,
    ContainerType,
} from 'src/app/shared/services/container/container.types';
import {
    WizardSlide,
    WizardSlideContentElement,
} from '../../models/wizard-slide';
import { ProjectWizardSlideCreationService } from '../../services/project-wizard-slide-creation.service';
import { WizardFormData as WizardFormData } from 'src/app/shared/components/wizard-form/wizard-form.data';
import { CarouselComponentHandler } from 'src/app/shared/components/carousel/carousel.component';

@Component({
    selector: 'app-wizard-slide',
    templateUrl: './wizard-slide.component.html',
    styleUrls: ['./wizard-slide.component.scss'],
    providers: [ProjectWizardSlideCreationService],
    animations: [fadeIn, fadeOut],
})
export class WizardSlideComponent implements CarouselComponentHandler {
    @Input() wizardList: Array<WizardSlide>;
    @Input() activeWizardSlide: number = 1;

    @Output()
    activeWizardPosition: EventEmitter<number> = new EventEmitter<number>();
    @Output()
    addProject: EventEmitter<string> = new EventEmitter<string>();

    hasSubmitFailed = new BehaviorSubject<boolean>(false);
    createDto: ContainerCreateRequest = new ContainerCreateRequest();

    constructor(
        private projectService: ProjectService,
        private messageService: MessageService,
        private router: Router,
        private translate: TranslateService,
        private projectWizardService: ProjectWizardSlideCreationService
    ) {}

    onSubmit(finalData: WizardFormData): void {
        const defaultTrainingName = this.translate.instant(
            'splitScreen.defaultTrainingName'
        );

        this.createDto.name = finalData.name;
        this.createDto.openly = finalData.openly;
        this.createDto.description = finalData.description;
        this.createDto.defaultTrainingName = defaultTrainingName;

        this.projectService.createProject(this.createDto).subscribe({
            next: (data: ContainerCreateResponse) => {
                if (data !== undefined) {
                    this.confirmCreatedProject(data.name);
                    this.redirectToProjectOverview(data.id);
                }
            },
            error: (err) => {
                console.error(err);
                this.hasSubmitFailed.next(true);
                throw err;
            },
        });
    }

    private redirectToProjectOverview(id: string) {
        this.router.navigate(['/project', id]);
    }

    private confirmCreatedProject(projectName: string) {
        this.messageService.displaySuccessMessage(
            this.translate.instant(
                'project.components.projectEvents.createSuccess',
                { name: projectName }
            )
        );

        this.addProject.emit('closeModal');
    }

    chooseOption(elem: WizardSlideContentElement) {
        //Should do nothing if ExpertTraining was clicked
        if (elem.redirectSlideId === 0) {
            return;
        }

        if (elem.redirectSlideId === 2) {
            this.createDto.containerType = <ContainerType>elem.cardValue;
        }
        if (elem.redirectSlideId === 3) {
            this.createDto.parameter = <string>elem.cardValue;
        }

        this.changeWizard(elem.redirectSlideId);
        this.activeWizardPosition.emit(elem.redirectSlideId);
    }

    changeWizard(id: number): void {
        this.activeWizardSlide = id;

        this.wizardList = this.projectWizardService.changeSlideData(
            this.wizardList,
            this.createDto
        );
    }

    onGoBack() {
        this.changeWizard(--this.activeWizardSlide);
    }

    getSelectionHandler(): CarouselComponentHandler {
        return this;
    }

    isActive(elem: WizardSlideContentElement): boolean {
        if (!elem) {
            return false;
        }
        if (elem.redirectSlideId === 2) {
            return elem.cardValue === this.createDto.containerType;
        }
        if (elem.redirectSlideId === 3) {
            return elem.cardValue === this.createDto.parameter;
        }
        return false;
    }
}
