export class ImgCategory {
    private _id: string;
    private _name: string;
    private _imageCount: number;
    private _annotationCount: number;

    constructor(
        id: string,
        name: string,
        imageCount: number = 0,
        annotationCount: number = 0
    ) {
        this._id = id;
        this._name = name;
        this._imageCount = imageCount;
        this._annotationCount = annotationCount;
    }

    public get id(): string {
        return this._id;
    }

    public set id(id: string) {
        this._id = id;
    }

    public get name(): string {
        return this._name;
    }

    public get imageCount(): number {
        return this._imageCount;
    }

    public get annotationCount(): number {
        return this._annotationCount;
    }

    public static includes(categories: ImgCategory[], name: string): boolean {
        let foundCategory = categories.find(
            (category) => category.name === name
        );
        return !!foundCategory;
    }
}
