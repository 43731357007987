import { Component, Input, OnInit } from '@angular/core';
import { SceneService } from '../../scenes/services/scene.service';
import { SceneElement } from '../../scenes/models/scene-element';
import { MujocoPoseMapping } from '../models/mujoco-pose-mapping';

@Component({
    selector: 'app-randomization-sidebar',
    templateUrl: './randomization-sidebar.component.html',
    styleUrls: ['./randomization-sidebar.component.scss'],
})
export class RandomizationSidebarComponent implements OnInit {
    @Input() trainingId: string;
    sceneElements: SceneElement[];
    poseMappings: MujocoPoseMapping[] = [];

    constructor(private sceneService: SceneService) {}

    ngOnInit(): void {
        this.sceneService.getScene(this.trainingId).subscribe((scene) => {
            this.sceneElements = scene.sceneElements;

            let currentStartingPoint = 0;
            this.sceneElements.forEach((sceneElement) => {
                let currentPoseMapping: MujocoPoseMapping =
                    new MujocoPoseMapping();
                currentPoseMapping.name =
                    sceneElement.browserAndPhysicsLoadable.name;
                if (!sceneElement.fixed) {
                    currentPoseMapping.qpos_range_position = `data.qpos[${currentStartingPoint}:${
                        currentStartingPoint + 3
                    }]`;
                    currentStartingPoint += 3;
                    currentPoseMapping.qpos_range_orientation = `data.qpos[${currentStartingPoint}:${
                        currentStartingPoint + 4
                    }]`;
                    currentStartingPoint += 4;
                } else if (!sceneElement.browserAndPhysicsLoadable.isRobot) {
                    return;
                }
                if (sceneElement.browserAndPhysicsLoadable.isRobot) {
                    let offset: number = 0;
                    switch (currentPoseMapping.name) {
                        case 'ant': {
                            offset = 8;
                            this.setJointPose(
                                currentPoseMapping,
                                currentStartingPoint,
                                offset
                            );
                            break;
                        }
                        case 'pib': {
                            offset = 36;
                            this.setJointPose(
                                currentPoseMapping,
                                currentStartingPoint,
                                offset
                            );
                            break;
                        }
                        case 'nanosaur': {
                            offset = 4;
                            this.setJointPose(
                                currentPoseMapping,
                                currentStartingPoint,
                                offset
                            );
                            break;
                        }
                        case 'franka_emika_panda': {
                            offset = 9;
                            this.setJointPose(
                                currentPoseMapping,
                                currentStartingPoint,
                                offset
                            );
                            break;
                        }
                    }
                    currentStartingPoint += offset;
                }
                this.poseMappings.push(currentPoseMapping);
            });
        });
    }

    private setJointPose(
        currentPoseMapping: MujocoPoseMapping,
        currentStartingPoint: number,
        offset: number
    ) {
        currentPoseMapping.qpos_range_joint = `data.qpos[${currentStartingPoint}:${
            currentStartingPoint + offset
        }]`;
    }
}
