import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProjectJoinRequest } from '../../../../project/models/project-join-request';
import { ProjectService } from '../../../../project/services/project.service';

@Component({
    selector: 'app-member-request',
    templateUrl: './member-request.component.html',
    styleUrls: ['./member-request.component.scss'],
})
export class MemberRequestComponent {
    @Input() joinRequest: ProjectJoinRequest;
    @Input() memberIdx: number;
    @Output() refreshMemberListTrigger: EventEmitter<string> =
        new EventEmitter<string>();
    constructor(private projectService: ProjectService) {}

    answerMemberRequest(accept: boolean): void {
        this.projectService
            .answerJoinRequest({
                projectId: this.joinRequest.containerId,
                userAccountDisplayName: this.joinRequest.userAccountDisplayName,
                accepted: accept,
            })
            .subscribe({
                next: () => {
                    this.refreshMemberListTrigger.emit(
                        this.joinRequest.userAccountDisplayName
                    );
                },
                error: (error) => {
                    console.error(
                        'Error while Answering Join Request. Please Try Again Later',
                        error
                    );
                    throw error;
                },
            });
    }
}
