import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UserAuth, UserProfile } from '../profile/models/user-profile';
import { PublicApiToken } from 'src/app/admin/models/public-api-token';
import { IdmOption } from '../profile/models/idm-option';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private readonly databaseUrl: string;

    profilePicture$: BehaviorSubject<string> = new BehaviorSubject<string>(
        null
    );

    constructor(private http: HttpClient) {
        this.databaseUrl = environment.backendUrl;
    }

    getUsers(): Observable<UserProfile[]> {
        const url = `${this.databaseUrl}/rest/v1/users`;
        return this.http.get<UserProfile[]>(url);
    }

    getUser(id: string): Observable<UserProfile> {
        const url = `${this.databaseUrl}/rest/v1/users/${id}`;
        return this.http.get<UserProfile>(url);
    }

    updateUser(user: UserProfile, id: string): Observable<UserProfile> {
        const url = `${this.databaseUrl}/rest/v1/users/${id}`;
        return this.http.patch<UserProfile>(url, user);
    }

    activateUser(active: boolean, id: string): Observable<UserProfile> {
        const url = `${this.databaseUrl}/rest/v1/users/${id}/activate/${active}`;
        return this.http.patch<UserProfile>(url, active);
    }

    updateUserCanTrain(checked: boolean, id: string) {
        const url = `${this.databaseUrl}/rest/v1/users/${id}/training/${checked}`;
        return this.http.patch<UserProfile>(url, checked);
    }

    updateUserRole(isAdmin: boolean, id: string): Observable<UserProfile> {
        const url = `${this.databaseUrl}/rest/v1/users/${id}/administrate/${isAdmin}`;
        return this.http.patch<UserProfile>(url, isAdmin);
    }

    getProfilePicture(id: string): void {
        this.getProfilePictureAsBlob(id).subscribe((data) => {
            const sub = this.readFileOrBlob(data.body).subscribe((result) => {
                this.profilePicture$.next(result);
                sub?.unsubscribe();
            });
        });
    }

    private getProfilePictureAsBlob(id: string): Observable<any> {
        const url = `${this.databaseUrl}/rest/v1/users/${id}/picture`;
        return this.http.get<Blob>(url, {
            params: {},
            responseType: 'blob' as 'json',
            observe: 'response',
        });
    }

    updateProfilePicture(file: File, id: string): Observable<any> {
        const url = `${this.databaseUrl}/rest/v1/users/${id}/picture`;
        const formData = new FormData();
        formData.append('file', file);
        return this.http.post<any>(url, formData);
    }

    updateProfilePictureFromLocal(data: string, id: string): Observable<any> {
        const url = `${this.databaseUrl}/rest/v1/users/${id}/picture`;
        return this.http.post<any>(url, data);
    }

    readFileOrBlob(fileToRead: File | Blob): Observable<string> {
        if (fileToRead && fileToRead.size > 0) {
            const reader = new FileReader();
            const subject = new Subject<string>();
            reader.readAsDataURL(fileToRead);
            reader.onload = (ev: ProgressEvent): void => {
                subject.next(reader.result as string);
            };
            return subject.asObservable();
        }
        return of(null);
    }

    getTokens(userId: string): Observable<PublicApiToken[]> {
        const url = `${this.databaseUrl}/rest/v1/users/${userId}/tokens`;
        return this.http.get<PublicApiToken[]>(url);
    }

    addToken(userId: string): Observable<PublicApiToken> {
        const url = `${this.databaseUrl}/rest/v1/users/${userId}/tokens`;
        return this.http.post<PublicApiToken>(url, {});
    }

    setTokenName(
        userId: string,
        tokenId: string,
        name: string
    ): Observable<PublicApiToken> {
        const url = `${this.databaseUrl}/rest/v1/users/${userId}/tokens/${tokenId}`;
        return this.http.patch<PublicApiToken>(url, { name });
    }

    activateToken(
        userId: string,
        tokenId: string,
        active: boolean
    ): Observable<PublicApiToken> {
        const url = `${this.databaseUrl}/rest/v1/users/${userId}/tokens/${tokenId}`;
        return this.http.patch<PublicApiToken>(url, { active });
    }

    deleteToken(userId: string, tokenId: string): Observable<void> {
        const url = `${this.databaseUrl}/rest/v1/users/${userId}/tokens/${tokenId}`;
        return this.http.delete<void>(url);
    }

    deleteProfilePicture() {
        this.profilePicture$.next(null);
    }

    getMe(): Observable<UserAuth> {
        return this.http.get<UserAuth>(`${this.databaseUrl}/rest/me`);
    }

    logoutFromIdm(): Observable<HttpResponse<Object>> {
        return this.http.post('/bff/logout', null, {
            headers: {
                'X-POST-LOGOUT-SUCCESS-URI': `https:${environment.frontendUrl}`,
            },
            observe: 'response',
        });
    }

    getIdmOption(): Observable<IdmOption> {
        return this.http.get<IdmOption>('/bff/idm-options');
    }
}
