<div *ngFor="let list of wizardList">
    <div
        class="wizard-point"
        *ngIf="list.type === 'card' && list.id === activeWizardSlide"
        @fadeIn
    >
        <div class="row slide-container">
            <div class="col-12">
                <p class="font-weight-bold text-center slide-title">
                    {{ list.slideTitle | translate }}
                </p>
            </div>
            <app-carousel
                [slides]="list"
                (optionChosen)="chooseOption($event)"
                [handler]="getSelectionHandler()"
            ></app-carousel>
        </div>
    </div>
    <div
        class="wizard-point"
        *ngIf="list.type === 'form' && list.id === activeWizardSlide"
        @fadeIn
    >
        <div class="create-project-form">
            <div class="text-container">
                <p class="text-center text-1">
                    {{ 'project.components.wizardSlide.text1' | translate }}
                </p>
                <p class="text-center text-2">
                    {{ 'project.components.wizardSlide.text2' | translate }}
                    <span class="text-tryp-pink">{{
                        'project.components.wizardSlide.text3' | translate
                    }}</span>
                    {{ 'project.components.wizardSlide.text4' | translate }}
                </p>
                <p class="text-3">
                    {{
                        'project.components.wizardSlide.projectName' | translate
                    }}
                </p>
            </div>
            <app-wizard-form
                [hasSubmitFailed]="hasSubmitFailed"
                (newData)="onSubmit($event)"
                (backClicked)="onGoBack()"
            ></app-wizard-form>
        </div>
    </div>
</div>
