<div
    *ngIf="initializationSuccess"
    class="split-screen-wrapper"
    [ngClass]="{
        'left-side-closed': splitScreenState === SplitScreenState.LEFT_CLOSED,
        'right-side-closed': splitScreenState === SplitScreenState.RIGHT_CLOSED
    }"
>
    <div class="left-container">
        <div class="left-side-content">
            <div class="project-header">
                <app-project-split-screen-header
                    [containerName]="containerName"
                    [containerType]="containerType"
                    [memberRole]="memberRole"
                ></app-project-split-screen-header>
            </div>
            <div class="project-tree">
                <app-tree></app-tree>
            </div>
        </div>
    </div>
    <div class="split-line" [ngClass]="{ hovered: isHovered }">
        <div class="arrows-wrapper">
            <a
                (click)="onArrowClick(ArrowDirection.LEFT)"
                (mouseover)="onHover(ArrowDirection.LEFT, true)"
                (mouseleave)="onHover(ArrowDirection.LEFT, false)"
            >
                <img
                    [class.invisible]="
                        splitScreenState === SplitScreenState.LEFT_CLOSED
                    "
                    class="left-arrow"
                    [src]="leftArrowSrc"
                    alt="left-arrow"
                />
            </a>
            <a
                (click)="onArrowClick(ArrowDirection.RIGHT)"
                (mouseover)="onHover(ArrowDirection.RIGHT, true)"
                (mouseleave)="onHover(ArrowDirection.RIGHT, false)"
            >
                <img
                    [class.invisible]="
                        splitScreenState === SplitScreenState.RIGHT_CLOSED
                    "
                    [src]="rightArrowSrc"
                    class="right-arrow"
                    alt="right-arrow"
                />
            </a>
        </div>
    </div>
    <div class="right-container">
        <div class="right-side-content">
            <app-project-split-screen-tabs
                [containerId]="containerId"
                [containerType]="containerType"
            ></app-project-split-screen-tabs>
        </div>
    </div>
</div>
