import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { ObjectGroup } from '../../models/object-group';
import { Scene } from '../../models/scene';
import { SceneVisualService } from '../../services/scene-visual.service';
import { SceneStateService } from '../../services/scene-state.service';
import { LoadedSceneElement } from '../../models/loaded-scene-element';
import { filter, Subscription } from 'rxjs';
import { nonNil, Reference } from '../../../shared/utility';
import { SceneCompositionType } from 'src/app/shared/enums/scene-composition-type.enum';

@Component({
    selector: 'app-scene-composition',
    templateUrl: './scene-composition.component.html',
    styleUrls: [
        './scene-composition.component.scss',
        '../../styles/scrollable-container.scss',
        '../../styles/search-bar.scss',
    ],
})
export class SceneCompositionComponent implements OnInit, OnDestroy {
    @Input() trainingId: string;

    @Input() type: SceneCompositionType = SceneCompositionType.SCENE;
    @Input() disabledCheckboxInfoText: string =
        'scenes.components.sceneComposition.disabledInfoText';
    @Input() disableFixedElementDrag: boolean = false;
    @Input() disableFixedElementDragInfoText: string;
    @Input() editingIsDisabled: boolean;

    sceneCompositionTypes = SceneCompositionType;

    scene: Scene;

    featureListObjects: ObjectGroup;
    featureListRobot: any;

    additionalSceneElements: LoadedSceneElement[];

    selectedSceneElement: LoadedSceneElement;

    loadedRobot: LoadedSceneElement;
    ikRuns = false;
    showListObjects = true;
    showListRobots = true;
    showListJoints = true;

    subscriptions: Subscription[] = [];

    searchTerm = '';
    searchResults = new Reference<number>(0);
    searchIndex = 0;
    listHasScrollbar = false;
    isFixationDisabled = false;
    isInfoTextPopupVisible = false;
    isDeleteInfoTextPopupVisible = false;
    isFixationInfoTextPopupVisible = false;
    @ViewChild('listContainer') listContainerRef: ElementRef;
    @ViewChild('infoText') infoTextRef: ElementRef;
    @ViewChild('deleteInfoText') deleteInfoTextRef: ElementRef;
    @ViewChild('fixationInfoText') fixationInfoTextRef: ElementRef;

    constructor(
        public sceneVisualService: SceneVisualService,
        public sceneStateService: SceneStateService,
        private cdRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.subscriptions = [
            this.sceneStateService.observeScene$.subscribe((scene: Scene) => {
                this.scene = scene;
            }),
            this.sceneStateService.robot$
                .pipe(filter(nonNil))
                .subscribe((robot: LoadedSceneElement) => {
                    this.loadedRobot = robot;
                }),

            this.sceneVisualService.selected$.subscribe(
                (selectedSceneElement: LoadedSceneElement) => {
                    this.selectedSceneElement = selectedSceneElement;
                    this.cdRef.detectChanges();
                    this.cdRef.markForCheck();
                }
            ),

            this.sceneStateService.getDiversitySelection$.subscribe((index) => {
                this.isFixationDisabled =
                    !!index.exampleMotion ||
                    this.type !== SceneCompositionType.SCENE;
            }),

            this.sceneStateService.additionalSceneElements$.subscribe(
                (additionalSceneElements) => {
                    this.additionalSceneElements = additionalSceneElements;
                }
            ),
            this.sceneVisualService.ikRuns.subscribe((val: boolean) => {
                this.ikRuns = val;
            }),
        ];
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    getRobot(object3d: THREE.Object3D): THREE.Object3D {
        return object3d.getObjectById(object3d.id);
    }

    toggleVisibilityObjects() {
        if (this.sceneVisualService.isArrangementFormValid.value) {
            this.showListObjects = !this.showListObjects;
        }
    }

    toggleVisibilityJoints() {
        if (this.sceneVisualService.isArrangementFormValid.value) {
            this.showListJoints = !this.showListJoints;
        }
    }

    toggleVisibilityRobots() {
        if (this.sceneVisualService.isArrangementFormValid.value) {
            this.showListRobots = !this.showListRobots;
        }
    }

    isObjectRemovable(givenElement: LoadedSceneElement): boolean {
        return (
            !!givenElement &&
            (!!this.sceneStateService.getDiversitySelection.base ||
                this.isElementNotInBaseScene(givenElement))
        );
    }

    private isElementNotInBaseScene(givenElement: LoadedSceneElement): boolean {
        return this.additionalSceneElements?.some(
            (item) => item.sceneElement.id === givenElement.sceneElement.id
        );
    }

    removeObjectFromArray(
        loadedSceneElements: LoadedSceneElement[],
        object3d: THREE.Object3D
    ) {
        return loadedSceneElements?.filter(
            (loadedSceneElement: LoadedSceneElement) =>
                loadedSceneElement.sceneElement.threejsElementId === object3d.id
        );
    }

    removeObjectFromScene(
        loadedSceneElement: LoadedSceneElement,
        event: Event
    ) {
        if (
            (!!this.sceneStateService.additionalSceneElements$.value.find(
                (lse) =>
                    lse.sceneElement.id === loadedSceneElement.sceneElement.id
            ) &&
                !this.sceneStateService.getDiversitySelection.exampleMotion) ||
            !this.isObjectRemovable(loadedSceneElement)
        ) {
            return;
        }
        if (event) {
            event.stopPropagation();
        }

        this.sceneStateService.removeObject(
            loadedSceneElement.sceneElement,
            this.trainingId
        );
    }

    selectObject(object: LoadedSceneElement) {
        if (this.sceneVisualService.isArrangementFormValid.value) {
            this.sceneVisualService.selectObject3D(object);
            this.selectedSceneElement = this.sceneVisualService.selected$.value;
        }
    }

    selectRobot(robot: LoadedSceneElement) {
        if (this.sceneVisualService.isArrangementFormValid.value) {
            this.sceneVisualService.selectObject3D(robot);
        }
    }

    onInput(event: any) {
        this.searchTerm = event.target.value;
        this.resetSearch();
    }

    private scrollToSelectedSearchResult() {
        setTimeout(() => {
            const selectedElement =
                document.getElementsByClassName('searched-selected')[0];
            if (!selectedElement) {
                return;
            }
            const boundingClientRect = selectedElement.getBoundingClientRect();
            const scrollContainerBoundingRect = document
                .getElementsByClassName('scrollable-container')[0]
                .getBoundingClientRect();

            if (
                boundingClientRect.bottom >
                    scrollContainerBoundingRect.bottom ||
                boundingClientRect.top < scrollContainerBoundingRect.top
            ) {
                selectedElement.scrollIntoView({ block: 'center' });
            }
        });
    }

    private resetSearch() {
        this.searchResults.value = 0;
        this.searchIndex = 0;
        this.scrollToSelectedSearchResult();
    }

    onKeydown(key: string) {
        if (key === 'Enter' && this.searchResults.value !== 0) {
            this.searchIndex++; // forces execution of pipe
            this.searchIndex %= this.searchResults.value;
            // reset shared mutable state used by pipe
            this.searchResults.value = 0;
            this.scrollToSelectedSearchResult();
        }
    }

    getObjectColor(object: LoadedSceneElement): string {
        return object.sceneElement.id ===
            this.selectedSceneElement?.sceneElement.id && !this.ikRuns
            ? '#e6007e'
            : '#000000';
    }

    onIsFixedChange(event: MouseEvent, object: LoadedSceneElement) {
        event.stopPropagation();
        if (this.isFixationDisabled) return;

        object.sceneElement.fixed = !object.sceneElement.fixed;
        this.sceneStateService.updateSceneElementFixed(
            object.sceneElement.id,
            object.sceneElement.fixed
        );
    }

    showInfoTextPopup($event) {
        const targetBoundingClientRect =
            $event.currentTarget.getBoundingClientRect();
        this.infoTextRef.nativeElement.style.left =
            targetBoundingClientRect.left + 'px';
        this.infoTextRef.nativeElement.style.top =
            targetBoundingClientRect.top + 'px';
        this.isInfoTextPopupVisible = true;
    }

    showDeleteInfoTextPopup($event) {
        if ($event.target.classList.contains('disabled')) {
            const targetBoundingClientRect =
                $event.currentTarget.getBoundingClientRect();
            this.deleteInfoTextRef.nativeElement.style.left =
                targetBoundingClientRect.left + 'px';
            this.deleteInfoTextRef.nativeElement.style.top =
                targetBoundingClientRect.top + 'px';
            this.isDeleteInfoTextPopupVisible = true;
        }
    }

    showFixationInfoTextPopup($event) {
        if (
            !this.disableFixedElementDragInfoText ||
            (this.disableFixedElementDrag &&
                this.disableFixedElementDragInfoText)
        ) {
            const targetBoundingClientRect =
                $event.currentTarget.getBoundingClientRect();
            this.fixationInfoTextRef.nativeElement.style.left =
                targetBoundingClientRect.right + 'px';
            this.fixationInfoTextRef.nativeElement.style.top =
                targetBoundingClientRect.top + 'px';
            this.isFixationInfoTextPopupVisible = true;
        }
    }

    getInfoTextPosition() {
        return '10px';
    }
}
