import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Params } from '@angular/router';
import { AuthenticationService } from 'src/app/authentication/services/authentication.service';
import { CardPage } from '../../../card/model/card-page';
import {
    createPageableByRouterParameters,
    Pageable,
} from 'src/app/shared/models/pageable';
import { RobotCard } from 'src/app/card/model/card-implementations/robot-card';
import { CardUser } from 'src/app/card/model/card-user';
import { BrowserAndPhysicsLoadableList } from 'src/app/robots/models/robot-list';
import { RobotService } from 'src/app/robots/services/robot.service';
import { RobotAddCard } from 'src/app/card/model/add-card-implementations/robot-add-card';

@Component({
    selector: 'app-robot-library',
    templateUrl: './robot-library.component.html',
    styleUrls: ['./robot-library.component.scss'],
})
export class RobotLibraryComponent implements OnInit {
    cardPage: CardPage = new CardPage();
    robotList: BrowserAndPhysicsLoadableList;
    currentPage = 0;
    @Input() profilePicture: string;

    constructor(
        private robotService: RobotService,
        private modal: NgbModal,
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.fetchData();
    }

    fetchData() {
        this.route.queryParams.subscribe((params: Params) => {
            this.cardPage.pageable = createPageableByRouterParameters(
                new Pageable(),
                params
            );
            if (this.cardPage.pageable.page > 0) {
                this.cardPage.pageable.page = this.cardPage.pageable.page - 1;
            }

            if (params.pageSize !== undefined) {
                this.cardPage.pageable.pageSize = params.pageSize;
            }
            this.robotService
                .getRobots(
                    this.authenticationService.currentUserValue.id,
                    this.cardPage.pageable.page,
                    this.cardPage.pageable.pageSize,
                    this.currentPage
                )
                .subscribe({
                    next: (robotsList) => {
                        this.robotList = robotsList;
                        this.cardPage.numberOfPages = robotsList.totalPages;
                        this.cardPage.pageable.page = robotsList.currentPage;
                        this.cardPage.addCard = new RobotAddCard(this.modal);
                        this.robotList.content.forEach((robot) => {
                            //TODO:FIX WHEN ROBOTS HAVE PICTURES
                            //let cardPictues = [String(robot.picture)];
                            const userId =
                                this.authenticationService.currentUserValue.id;
                            const cardUser = new CardUser(
                                userId,
                                robot.creator,
                                this.profilePicture
                            );
                            const robotCard = RobotCard.mapToRobotCard(
                                robot,
                                cardUser
                            );
                            this.cardPage.itemCards.push(robotCard);
                        });
                    },
                });
        });
    }
}
