import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { GlobalConfig } from '../models/global-config';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class GlobalConfigService {
    _isLoggedIn$ = new BehaviorSubject<boolean>(
        this.authenticationService.isLoggedIn()
    );
    private readonly databaseUrl: string;

    constructor(
        private http: HttpClient,
        private authenticationService: AuthenticationService
    ) {
        this.databaseUrl = environment.backendUrl;
    }

    getConfig(): Observable<GlobalConfig> {
        const url = `${this.databaseUrl}/rest/globalConfig`;
        return this.http.get<GlobalConfig>(url);
    }

    updateConfig(globalConfig: GlobalConfig): Observable<GlobalConfig> {
        //   console.log("Global Config in updateConfig:" + globalConfig.trainingInCloud)
        const url = `${this.databaseUrl}/rest/globalConfig`;
        return this.http.put<GlobalConfig>(url, globalConfig);
    }

    get isLoggedIn() {
        return this._isLoggedIn$.asObservable();
    }
}
