import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ApplicationLockService {
    private _applicationLock$: Subject<ApplicationLock> =
        new Subject<ApplicationLock>();

    get applicationLock() {
        return this._applicationLock$;
    }

    setApplicationLock(message?: string) {
        this._applicationLock$.next(new ApplicationLock(true, message));
    }

    releaseApplicationLock() {
        this._applicationLock$.next(new ApplicationLock(false));
    }
}

export class ApplicationLock {
    constructor(public isLocked: boolean, public message?: string) {}
}
