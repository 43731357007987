<app-message></app-message>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
<div *ngIf="isLandingPage">
    <app-menu></app-menu>
    <div id="main" class="nav-top">
        <app-landing-page></app-landing-page>
    </div>
</div>
<div *ngIf="!isLandingPage" class="container-fluid p-0 d-flex h-100">
    <div class="d-flex flex-shrink-0 flex-column">
        <app-sidebar-nav></app-sidebar-nav>
    </div>
    <div class="flex-fill overflow-hidden">
        <router-outlet></router-outlet>
    </div>
</div>
<div
    class="application-lock-window"
    *ngIf="applicationLock && applicationLock.isLocked"
>
    <div class="spinner-wrapper">
        <div class="tryb-design-spinner"></div>
    </div>
    <div class="message-wrapper">
        <span *ngIf="!applicationLock.message"
            >{{ 'appLock.defaultMessage' | translate }}
        </span>
        <span *ngIf="applicationLock.message"
            >{{ applicationLock.message | translate }}
        </span>
    </div>
</div>
