import {
    Component,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { TrainingDTO } from '../shared/models/training-dto';
import { TrainingType } from '../shared/enums/training-type.enum';
import {
    SideContainerData,
    SideContainerPosition,
    ToggleMode,
} from './models/side-container-data';
import { EvaluationStorageService } from './service/evaluation-storage.service';
import { IterationDataService } from './service/iteration-data.service';
import { Subscription, switchMap, tap } from 'rxjs';
import { TrainingStatus } from '../shared/models/training-status';
import { AlgorithmService } from '../algorithm/services/algorithm.service';
import {
    AlgorithmDTO,
    AlgorithmType,
} from '../algorithm/models/algorithm-form';
import { UserCacheService } from '../xtra/user-cache/user-cache-service';
import { SavedEvaluationState } from './evaluation-state';
import { AuthenticationService } from '../authentication/services/authentication.service';

@Component({
    selector: 'app-evaluation',
    templateUrl: './evaluation.component.html',
    styleUrls: ['./evaluation.component.scss'],
    providers: [EvaluationStorageService, IterationDataService],
})
export class EvaluationComponent implements OnInit, OnDestroy {
    //HTML imports
    protected readonly TrainingStatus = TrainingStatus;
    protected readonly TrainingType = TrainingType;
    protected readonly SideContainerPosition = SideContainerPosition;

    isDiagramMode: boolean = false;
    @Input() training: TrainingDTO;

    subscriptions: Subscription[] = [];
    maxNumberEpochs: number;

    graphDataAvailable: boolean = false;
    initialized: boolean = false;
    selectedImageIndex: number = 0;
    private algorithmType: AlgorithmType;
    private learnerType: AlgorithmType;

    constructor(
        public iterationDataService: IterationDataService,
        private router: Router,
        public evalDataStorage: EvaluationStorageService,
        private algorithmService: AlgorithmService,
        private userCacheService: UserCacheService,
        private authenticationService: AuthenticationService
    ) {}

    ngOnInit(): void {
        this.graphDataAvailable = this.training.status !== TrainingStatus.NEW;

        this.subscriptions.push(
            this.evalDataStorage.imageIndex.subscribe((selectedImageId) => {
                this.selectedImageIndex = selectedImageId;
            })
        );

        this.subscriptions.push(
            this.authenticationService.logoutTriggered$.subscribe(() => {
                if (this.evalDataStorage.changedState) {
                    this.evalDataStorage.saveState().subscribe(() => {
                        this.authenticationService.logoutPreparationsFinished$.next(
                            true
                        );
                    });
                } else {
                    this.authenticationService.logoutPreparationsFinished$.next(
                        true
                    );
                }
            })
        );

        this.algorithmService
            .getAlgorithmFormData(this.training.uuid)
            .pipe(
                tap((algorithm: AlgorithmDTO) => {
                    this.algorithmType = algorithm.type;
                    this.maxNumberEpochs =
                        this.training.trainingType === TrainingType.VISUAL
                            ? (algorithm.parameters.find(
                                  (parameter) => parameter.name === 'nEpochs'
                              ).value as number)
                            : -1;
                    this.learnerType = [
                        AlgorithmType.GAIL,
                        AlgorithmType.AIRL,
                    ].includes(algorithm.type)
                        ? (algorithm.parameters.find(
                              (parameter) => parameter.name === 'inputAlgorithm'
                          ).value as AlgorithmType)
                        : undefined;
                }),
                switchMap(() => {
                    return this.userCacheService.loadData(
                        `evaluation_${this.training.uuid}_state`
                    );
                })
            )
            .subscribe((state: SavedEvaluationState) => {
                this.isDiagramMode =
                    state !== null && state.toggleMode === ToggleMode.DIAGRAMS;
                this.evalDataStorage.initializeState(
                    this.training,
                    state,
                    this.algorithmType,
                    this.learnerType
                );
                this.initialized = true;
            });

        this.subscriptions.push(
            this.evalDataStorage.rightContainerData.subscribe(
                (sideData: SideContainerData) => {
                    if (this.isDiagramMode) {
                        this.iterationDataService.loadData(
                            this.training.uuid,
                            this.maxNumberEpochs,
                            sideData.contentItems,
                            this.evalDataStorage.state.diagrams.right
                        );
                    }
                }
            )
        );
    }

    onToggle() {
        this.isDiagramMode = !this.isDiagramMode;
        this.evalDataStorage.updateToggleMode(
            this.isDiagramMode ? ToggleMode.DIAGRAMS : ToggleMode.MEDIA
        );
    }

    getSideContainerHeader(containerPosition: SideContainerPosition): string {
        const keyForToggleMode: string = this.isDiagramMode
            ? 'diagrams'
            : 'media';

        return `evaluation.${this.training?.trainingType}.${keyForToggleMode}.${containerPosition}Header`;
    }

    mapTooltipLabel(xValue: number, mouseOverXLabel: String): string {
        return `${mouseOverXLabel} ${xValue}`;
    }

    clickOnIteration(iteration: number): void {
        this.router.navigate(['policy-search/iteration-charts'], {
            queryParams: { policySearchId: this.training.uuid, iteration },
        });
    }

    ngOnDestroy(): void {
        if (
            this.evalDataStorage.changedState &&
            this.authenticationService.isLoggedIn()
        ) {
            this.evalDataStorage.saveState().subscribe();
        }

        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler(event) {
        if (this.evalDataStorage?.changedState) {
            this.evalDataStorage.saveState().subscribe();
            event.preventDefault();
            event.returnValue = false;
        }
    }
}
