import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, Subscription, take, tap } from 'rxjs';
import { LanguageCode } from './navigation/menu/components/language-nav-menu/data/laguage-code';
import { ChangeLangEventService } from './navigation/menu/components/language-nav-menu/service/change-lang-event.service';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import { NavigationEnd, Router } from '@angular/router';
import { LoginRedirectionService } from './authentication/services/login-redirection.service';
import { AuthenticationService } from './authentication/services/authentication.service';
import {
    ApplicationLock,
    ApplicationLockService,
} from './shared/services/application-lock.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    private changeLangSubscription: Subscription;
    usedLanguageCode: LanguageCode = LanguageCode.DE;
    isLandingPage: boolean = true;
    applicationLock: ApplicationLock;

    constructor(
        private translate: TranslateService,
        private changeLangEvent: ChangeLangEventService,
        private router: Router,
        private loginRedirectionService: LoginRedirectionService,
        private authenticationService: AuthenticationService,
        private applicationLockService: ApplicationLockService
    ) {
        registerLocaleData(localeEn, LanguageCode.EN);
        registerLocaleData(localeDe, LanguageCode.DE);
        translate.addLangs([LanguageCode.DE, LanguageCode.EN]);
        translate.setDefaultLang(LanguageCode.DE);
        translate.use(this.usedLanguageCode);
    }

    ngOnInit(): void {
        this.changeLangSubscription = this.changeLangEvent
            .getChangeLang()
            .subscribe((data) => {
                this.usedLanguageCode = data;
                localStorage.setItem('change-language', this.usedLanguageCode);
                this.translate.use(this.usedLanguageCode);
            });

        this.router.events.subscribe({
            next: (event) => {
                if (event instanceof NavigationEnd) {
                    this.isLandingPage =
                        event.url === '/home' || event.url === '/';
                }
            },
        });

        this.authenticationService.initializationFinished$
            .pipe(
                take(1),
                tap(() => this.redirectIfLoggedIn())
            )
            .subscribe();

        this.applicationLockService.applicationLock.subscribe(
            (applicationLock: ApplicationLock) => {
                this.applicationLock = applicationLock;
            }
        );
    }

    private redirectIfLoggedIn() {
        if (this.authenticationService.isLoggedIn()) {
            const redirectUrl = this.loginRedirectionService.getRedirectUrl();
            if (redirectUrl) {
                this.loginRedirectionService.removeRedirectUrl();
                this.router.navigateByUrl(redirectUrl, {
                    skipLocationChange: false,
                });
            }
        } else {
            this.loginRedirectionService.removeRedirectUrl();
        }
    }

    ngOnDestroy(): void {
        this.changeLangSubscription.unsubscribe();
    }
}
