import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { SkillArchitectureService } from './skill-architecture.service';
import { SkillArchitectureStateService } from './skill-architecture-state.service';
import {
    LayerModel,
    SkillArchitectureEntity,
} from './models/skill-architecture';
import { TransformData } from './architecture-visualization/drawing/transform';
import { ArchitectureVisualizationComponent } from './architecture-visualization/architecture-visualization.component';
import { UserCacheService } from 'src/app/xtra/user-cache/user-cache-service';
import { SceneStateService } from 'src/app/scenes/services/scene-state.service';
import {
    InsideMovementZoomBehavoir,
    ZoomBehavior,
} from './skill-architecture.zoom-behavior';
import { LoadedSceneElement } from 'src/app/scenes/models/loaded-scene-element';

@Component({
    selector: 'app-skill-architecture',
    templateUrl: './skill-architecture.component.html',
    styleUrls: ['./skill-architecture.component.scss'],
    providers: [SceneStateService],
})
export class SkillArchitectureComponent implements OnInit, OnDestroy {
    @Input() trainingId: string;
    @Input() editingIsDisabled: boolean = false;
    loadedSceneElements: LoadedSceneElement[];
    sceneElements = [];

    @ViewChild(ArchitectureVisualizationComponent)
    visualizeComponent: ArchitectureVisualizationComponent;

    statusCache = new StatusCache();
    statusCachePath: string;
    layerSelectSub =
        this.skillArchitectureStateService.selectedLayer$.subscribe((layer) =>
            this.onLayerSelected(layer)
        );

    routeSub = this.route.url.subscribe((urlSegmentArr: UrlSegment[]) => {
        let path = urlSegmentArr.map((u) => u.path).join('-');
        path = encodeURIComponent(path);
        this.setStatusCachePath(path);
    });

    editorZoomBehavior: ZoomBehavior;

    constructor(
        private route: ActivatedRoute,
        private skillArchitectureService: SkillArchitectureService,
        public skillArchitectureStateService: SkillArchitectureStateService,
        private userCacheService: UserCacheService
    ) {}

    ngOnInit(): void {
        this.initialSkillArchitectureFetch();
    }

    /* Just looking if there is some cached data,
     * based on provided path, and set used cache path
     * for later onDestroy handling
     */
    private setStatusCachePath(path: string) {
        this.statusCachePath = path;

        const loadedStatusSub = this.userCacheService
            .loadData(this.statusCachePath)
            .subscribe((data) => {
                if (data) {
                    this.statusCache = <StatusCache>data;
                }
                loadedStatusSub.unsubscribe();
            });
    }

    private initialSkillArchitectureFetch() {
        const sub = this.skillArchitectureService
            .get(this.trainingId)
            .subscribe((skillArchitectureResponse) => {
                this.skillArchitectureStateService.initializeSkillArchitecture(
                    skillArchitectureResponse
                );
                sub?.unsubscribe();
            });
    }

    onDataRendered(skillArchitecture: SkillArchitectureEntity): void {
        if (skillArchitecture) {
            this.editorZoomBehavior = new InsideMovementZoomBehavoir(
                this.visualizeComponent
            );
            // if we have some previous selection keep it
            let selectedLayer = this.getSelected();
            if (!selectedLayer) {
                this.setSelected(skillArchitecture.model.layers[0].name);
                selectedLayer = this.getSelected();
            }
            this.skillArchitectureStateService.selectLayer(selectedLayer);
        }
    }

    onLayerSelected(layer: LayerModel): void {
        if (layer) {
            this.setSelected(layer.name);
        }
    }

    onTransform(transformData: TransformData): void {
        if (this.editorZoomBehavior.isAllowed(transformData)) {
            let transformAllowed =
                this.editorZoomBehavior.createAllowedTransformation(
                    transformData
                );

            this.visualizeComponent.transformNetwork(transformAllowed);
        }
    }

    ngOnDestroy(): void {
        this.saveStatus();

        this.routeSub.unsubscribe();
        this.layerSelectSub.unsubscribe();
        this.skillArchitectureStateService.selectLayer(null);
    }

    private saveStatus(): void {
        let status = {
            selected: this.getSelected(),
            transform: this.visualizeComponent?.currentTransformData,
        };
        const saveDataSub = this.userCacheService
            .saveData(this.statusCachePath, status)
            .subscribe(() => saveDataSub.unsubscribe());
    }

    public getSelected(): string {
        return this.statusCache.selected;
    }

    private setSelected(selected: string): void {
        this.statusCache.selected = selected;
    }

    public getTransform(): TransformData {
        return this.statusCache.transform;
    }

    private setTransform(transformData: TransformData): void {
        this.statusCache.transform = transformData;
    }
}

/**Internal Cache Class*/
class StatusCache {
    selected: string;
    transform: TransformData;
}
